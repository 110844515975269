import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {randomString} from "../../helpers/tools";

class BookingByRoomChart extends React.Component {

    static propTypes = {
        data: PropTypes.array,
    };

    static defaultProps = {
        data: []
    };

    state = {}

    formRef = React.createRef();

    chart;
    containerId = ''

    constructor(props) {
        super(props);
        this.containerId = `graph-booking-${randomString(16)}`
    }

    initChart() {
        const chart = am4core.create(this.containerId, am4charts.XYChart);

        // ---
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        chart.colors.step = 2;
        chart.maskBullets = false;

        //----
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.fullWidthTooltip = true;

        const bookingAxis = chart.yAxes.push(new am4charts.ValueAxis());
        bookingAxis.title.text = "Booking";
        bookingAxis.min = 0
        bookingAxis.maxPrecision = 0
        //distanceAxis.renderer.grid.template.disabled = true;

        const durationAxis = chart.yAxes.push(new am4charts.DurationAxis());
        durationAxis.title.text = "Duration";
        durationAxis.baseUnit = "minute";
        //durationAxis.renderer.grid.template.disabled = true;
        durationAxis.renderer.opposite = true;
        durationAxis.syncWithAxis = bookingAxis;
        durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

        // Create series
        const bookingSeries = chart.series.push(new am4charts.ColumnSeries());
        bookingSeries.dataFields.valueY = "total";
        bookingSeries.dataFields.dateX = "date";
        bookingSeries.yAxis = bookingAxis;
        bookingSeries.tooltipText = "{valueY} booking(s)";
        bookingSeries.name = "Number of Booking";
        bookingSeries.columns.template.fillOpacity = 0.7;
        bookingSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
        bookingSeries.columns.template.propertyFields.fillOpacity = "alpha";

        const roomCountSeries = chart.series.push(new am4charts.ColumnSeries());
        roomCountSeries.dataFields.valueY = "total_room";
        roomCountSeries.dataFields.dateX = "date";
        roomCountSeries.yAxis = bookingAxis;
        roomCountSeries.tooltipText = "{valueY} room/desk(s)";
        roomCountSeries.name = "Number of Room/Desk(s)";
        roomCountSeries.columns.template.fillOpacity = 0.7;
        roomCountSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
        roomCountSeries.columns.template.propertyFields.fillOpacity = "alpha";

        const bookingState = bookingSeries.columns.template.states.create("hover");
        bookingState.properties.fillOpacity = 0.9;

        const durationSeries = chart.series.push(new am4charts.LineSeries());
        durationSeries.dataFields.valueY = "duration";
        durationSeries.dataFields.dateX = "date";
        durationSeries.yAxis = durationAxis;
        durationSeries.name = "Duration";
        durationSeries.strokeWidth = 2;
        durationSeries.propertyFields.strokeDasharray = "dashLength";
        durationSeries.tooltipText = "{valueY.formatDuration()}";
        durationSeries.showOnInit = true;

        const durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
        const durationRectangle = durationBullet.createChild(am4core.Rectangle);
        durationBullet.horizontalCenter = "middle";
        durationBullet.verticalCenter = "middle";
        durationBullet.width = 7;
        durationBullet.height = 7;
        durationRectangle.width = 7;
        durationRectangle.height = 7;

        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineX.fill = am4core.color("#000");
        chart.cursor.lineX.fillOpacity = 0.1;
        // ---

        this.chart = chart
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.chart && prevProps.data !== this.props.data) {
            this.chart.data = this.props.data;
        }
    }

    componentDidMount() {
        this.initChart();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={this.containerId} style={{width: "100%", height: "500px"}}></div>
        );
    }
}

export default connect(null, CommonDispatcher)(BookingByRoomChart);

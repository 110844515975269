import React, { useEffect, useState } from "react";
import { Button, Form, Input, List, Radio, Space, Spin, Typography, } from "antd";
import { ROOM_TYPES } from "@src/constants";
import InfiniteScroll from 'react-infinite-scroller';
import { GiDesk } from 'react-icons/gi';
import { ShopOutlined } from '@ant-design/icons';
import formatter from "@src/helpers/formatter";
import { roomService } from "@src/services";

const RoomItem = ({loading = false, value, onChange, dataSource}) => {
    return <div style={{
        minHeight: 300,
        overflow: "auto",
        border: "1px solid #e8e8e8",
        borderRadius: 4
    }}>
        <Spin spinning={loading}>
            <InfiniteScroll
                loadMore={() => {
                }}
                initialLoad={false}
                pageStart={0}
                hasMore={false}
                useWindow={false}
            >
                <List
                    dataSource={dataSource?.filter(item => item.is_active)}
                    renderItem={item => {
                        const style = {cursor: "pointer", padding: "16px 24px"};
                        const selected = item.id === value;
                        if (selected) {
                            style.backgroundColor = 'rgba(237, 23, 118, 0.2)';
                        }

                        return <List.Item key={item.id} onClick={() => onChange(item.id)} style={style}>
                            <List.Item.Meta
                                avatar={item.type === ROOM_TYPES.DESK ?
                                    <GiDesk size='40'/> : <ShopOutlined style={{fontSize: 40}}/>
                                }
                                title={item.name}
                                description={item.capacity && <Typography.Paragraph style={{marginBottom: 0}}>
                                    Capacity: {item.capacity}
                                </Typography.Paragraph>}
                            />
                        </List.Item>
                    }}
                >
                </List>
            </InfiniteScroll>
        </Spin>
    </div>
}

export const FormRoom = ({
                             extra,
                             show,
                             name,
                             initialValues,
                             control = {allow_next: false, allow_previous: false},
                             onPrevious,
                         }) => {

    const [search, setSearch] = useState("");
    const [ready, setReady] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);

    const [roomList, setRoomList] = useState({searching: false, data: []});

    const handlers = {
        fetchRooms: () => {
            const query = {types: [formValues?.type]};
            if (search) {
                query.name = search;
            }

            setRoomList({...roomList, searching: true});
            roomService.list(null, null, query).then(rs => {
                setRoomList({data: rs.items, searching: false});
            }).catch(err => {
                setRoomList({...roomList, searching: false, error: err.message});
            });
        }
    }

    useEffect(() => {
        if (ready) {
            handlers.fetchRooms();
        }
    }, [search, formValues?.type]);

    useEffect(() => {
        if (!ready) {
            setReady(true);
            handlers.fetchRooms();
        }
    });

    const displayRoomType = formatter.toDisplayRoomType(formValues?.type);

    const formProps = {
        name,
        layout: "vertical",
        initialValues: formValues,
        onValuesChange: (value, form) => setFormValues(form),
        style: {display: show ? "block" : "none"}
    }

    return <Form {...formProps}>
        <Form.Item name="type">
            <Radio.Group options={[
                {label: formatter.toDisplayRoomType(ROOM_TYPES.ROOM), value: ROOM_TYPES.ROOM},
                {label: formatter.toDisplayRoomType(ROOM_TYPES.DESK), value: ROOM_TYPES.DESK},
            ]}/>
        </Form.Item>
        <Form.Item label="Title" name="title" rules={[{
            required: true,
            message: 'Title is require',
        }]}>
            <Input maxLength={64}/>
        </Form.Item>

        <Form.Item label={displayRoomType} required>
            <Input
                maxLength={128}
                allowClear
                onChange={(e) => setSearch(e.target.value)}
                className="input"
                placeholder={`Type to search and select the ${displayRoomType}`}
                value={search}
            />
        </Form.Item>
        <Form.Item name="room" rules={[{
            required: true,
            message: `Please select the ${displayRoomType}`,
        }]}>
            <RoomItem dataSource={roomList?.data} loading={roomList?.searching}/>
        </Form.Item>

        <Form.Item style={{marginTop: 30, textAlign: "right"}}>
            <Space direction="horizontal" size={8}>
                {!!extra && <>{extra}&nbsp;</>}
                <Button htmlType="button" disabled={!control?.allow_previous} onClick={onPrevious}>
                    Previous
                </Button>
                <Button htmlType="submit" disabled={!control?.allow_next} type="primary">
                    Next
                </Button>
            </Space>
        </Form.Item>
    </Form>
}

/*
class RoomFormScreen extends React.Component {
    state = {
        selected_room_type: null,
        hasMore: true,
        searchData: null,
        searchText: '',
        rooms: [],
    }

    componentDidMount() {
        const {selected_room_type, rooms} = this.props
        this.setState({
            selected_room_type: selected_room_type === null ? ROOM_TYPES.ROOM : selected_room_type,
            rooms: rooms
        })
    }

    handleInfiniteOnLoad = () => {
        this.setState({
            hasMore: false,
        });
        return;
    };
    search = (e) => {
        const {rooms} = this.state
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = rooms.filter((r) => {
                return r.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            this.setState({
                searchData: results,
            })
        } else {
            this.setState({
                searchData: rooms,
            })
        }

        this.setState({
            searchText: keyword
        })
    }

    render() {
        const {searchData, searchText, selected_room_type, rooms} = this.state;

        const RenderList = ({value, onChange}) => {
            const [selectedRoom, setSelectedRoom] = useState(value);

            return <div className="demo-infinite-container">
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.handleInfiniteOnLoad}
                    hasMore={this.state.hasMore}
                    useWindow={false}
                >
                    <List
                        dataSource={searchData === null ? rooms : searchData}
                        renderItem={item => (
                            <List.Item key={item.id} onClick={() => (setSelectedRoom(item.id), onChange(item.id))}
                                       style={{backgroundColor: item.id === selectedRoom ? 'rgba(237, 23, 118, 0.2)' : null}}>
                                <List.Item.Meta
                                    avatar={
                                        selected_room_type === null || selected_room_type === 'room' ?
                                            <ShopOutlined style={{fontSize: 40}}/> : <GiDesk size='40'/>
                                    }
                                    title={item.name}
                                    description={<>
                                        {item.capacity === null ? null :
                                            <Typography.Paragraph>Capacity: {item.capacity}</Typography.Paragraph>}
                                    </>}
                                />
                            </List.Item>
                        )}
                    >
                    </List>
                </InfiniteScroll>
            </div>
        }

        const displayRoomType = formatter.toDisplayRoomType(selected_room_type);
        return <>
            <Form.Item name="type" rules={[{
                required: true,
                message: 'Please select the room',
            }]}>
                <Radio.Group options={[
                    {label: 'Room', value: ROOM_TYPES.ROOM},
                    {label: 'Desk', value: ROOM_TYPES.DESK},
                ]}/>
            </Form.Item>
            <Form.Item label="Title" name="title" rules={[{
                required: true,
                message: 'Title is require',
            }]}>
                <Input/>
            </Form.Item>
            <Input
                allowClear
                onChange={this.search}
                className="input"
                placeholder="input search room"
                style={{marginBottom: 20}}
                value={searchText}
            />
            <Form.Item label={displayRoomType} name="room" rules={[{
                required: true,
                message: `Please select the ${displayRoomType}`,
            }]}>
                <RenderList/>
            </Form.Item>
        </>
    }

}
export default connect(CommonProps, CommonDispatcher)(RoomFormScreen);
*/

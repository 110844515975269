import {Select} from "antd";
import {ROOM_TYPES} from "../../constants";
import formatter from "../../helpers/formatter";
import React from "react";

export const RoomsSelectors = ({rooms, ...props}) => {

    const prop = {
        showSearch: true,
        allowClear: true,
        filterOption: (input, option) => {
            return option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        },
        ...props,
    }
    return <Select {...prop}>
        {rooms && Object.values(ROOM_TYPES).map(t => {
            const list = rooms?.filter(r => r.type === t);
            if (list?.length > 0) {
                return <Select.OptGroup key={t} label={formatter.toDisplayRoomType(t)}>
                    {list.map(r => <Select.Option key={r.id} value={r.id}>{r.name}</Select.Option>)}
                </Select.OptGroup>
            }
            return null;
        })}
    </Select>
}

import http from './http';

class SummaryService {

    overview() {
        return http.get('/api/summary/overview');
    }

}

export default new SummaryService();

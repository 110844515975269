import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Form, Input, Upload } from "antd";
import { connect } from "react-redux";
import { CommonDispatcher } from "../../store/helpers";
import fileHelper from "../../helpers/file";
import { PlusOutlined } from "@ant-design/icons";

class FacilityFormScreen extends React.PureComponent {

    static propTypes = {
        facility: PropTypes.object,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: (v) => console.warn('action not register', v),
    };

    state = {
        icon: null
    }

    formRef = React.createRef();

    constructor(props, context) {
        super(props, context);
        if (props.facility) {
            this.state.icon = props.facility.icon;
        }
    }

    handleSubmit = (form) => {
        this.props.onSubmit(form);
    }

    render() {
        const {icon} = this.state;
        const {facility} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'left',
            colon: false,
            initialValues: {
                name: facility?.name,
                icon: facility?.icon,
                is_active: facility?.is_active
            }
        };

        const uploadButton = (
            <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>Upload</div>
            </div>
        );

        return <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef}>

            <Form.Item label="Name" name="name" rules={[{
                required: true,
                message: 'Name is require',
            }]}>
                <Input maxLength={16}/>
            </Form.Item>

            <Form.Item label="Active" name="is_active" valuePropName="checked">
                <Checkbox/>
            </Form.Item>

            <Form.Item label="Icon" name="icon" valuePropName="file">
                <Upload
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(info) => {
                        const {file} = info;
                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                        if (!isJpgOrPng) {
                            this.props.notification({
                                message: 'You can only upload JPG/PNG file!',
                                type: 'warning'
                            });
                        }
                        const isLt2M = file.size / 1024 / 1024 < 1;
                        if (!isLt2M) {
                            this.props.notification({message: 'Image must smaller than 1MB!', type: 'warning'});
                        }

                        if (isLt2M && isJpgOrPng) {
                            fileHelper.fileToBase64(info.file).then(base64 => {
                                this.formRef.current.setFieldsValue({icon: base64});
                                this.setState({icon: base64});
                            });
                        }
                        return false;
                    }}
                >
                    {icon ? <img src={`${icon}`} alt="avatar" style={{width: '100%', padding: 8}}/> : uploadButton}
                </Upload>
            </Form.Item>


            <Form.Item label=" ">
                <Button htmlType="submit" type="primary">
                    {facility ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    }
}

export default connect(null, CommonDispatcher)(FacilityFormScreen);

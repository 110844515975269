import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as r from 'redux-devtools-extension';

import UiReducer from './ui-reducer';
import BackendReducer from './backend-reducer';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        return r.composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export default createStore(
    combineReducers({
        ui: UiReducer,
        backend: BackendReducer,
    }),
    bindMiddleware([thunkMiddleware]),
);

import React from "react";
import { Row, Col, Layout, Result, Button, Typography } from "antd";
import { connect } from "react-redux";
import { CommonProps, CommonDispatcher } from "../../store/helpers";
import { SETTING_KEYS } from "../../constants";
import queryString from "query-string";
import { settingService } from "../../services";
import { RoomMsalAuthProvider } from "../../services/msal";

class MicrosoftCalenderOauth extends React.Component {
    static propTypes = {};

    state = {
        error: null,
        init_done: false,
        authorize_url: null,
    };

    msal = null;

    componentDidMount() {
        settingService
            .get([
                SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY,
                SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID,
                SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID,
                SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI,
                //SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET,
            ])
            .then((settings) => {
                const client_id = settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID];
                const tenant_id = settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID];
                //const client_secret = settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET];
                const authority = settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY];
                const redirect_url = settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI];

                const scopes = ["calendars.read", "user.read"];
                const redirect_uri = redirect_url ? redirect_url : `${window.location.origin}${window.location.pathname}`;

                let sign_out_url = null;
                let use_tenant_id = tenant_id;
                if (authority) {
                    sign_out_url = `${authority}${tenant_id ? `/${tenant_id}` : ""}/oauth2/v2.0/logout`;
                } else {
                    use_tenant_id = !!tenant_id ? tenant_id : "common";
                    sign_out_url = `https://login.microsoftonline.com/${use_tenant_id}/oauth2/v2.0/logout`;
                }

                this.msal = RoomMsalAuthProvider(client_id, tenant_id, authority);
                this.msal
                    .getAuthCodeUrl({ scopes, redirectUri: redirect_uri })
                    .then((authorize_url) => {
                        this.setState({
                            init_done: true,
                            authorize_url,
                            sign_out_url,
                            query: queryString.parse(this.props.location.search),
                            settings,
                        });
                    })
                    .catch((err) => {
                        this.setState({ error: err.message, init_done: true });
                    });
            })
            .catch((err) => {
                this.props.notificationError(err);
            });
    }

    button(label, loading = false, onClick) {
        const { icon, style } = this.props;
        return (
            <Button icon={icon} onClick={onClick} type="primary" style={style} loading={loading}>
                {label}
            </Button>
        );
    }

    render() {
        const { error, init_done, authorize_url, sign_out_url, query } = this.state;

        let errObj = null;
        if (!!error) {
            errObj = {
                title: "Error",
                message: error.split("\n").map((v, i) => <p key={i}>{v}</p>),
            };
        } else if (query && query.error) {
            errObj = {
                title: query.error,
                message: query.error_description?.split("\n").map((v, i) => <p key={i}>{v}</p>),
            };
        }

        let extra;
        if (!query || Object.keys(query).length === 0) {
            extra = [
                <Button loading={!init_done} href={authorize_url} type="primary" key="login">
                    Authorize
                </Button>,
            ];
        } else {
            extra = [
                <Button href={sign_out_url} type="primary" key="login">
                    Sign out
                </Button>,
            ];
        }

        const title = query?.code
            ? " Please copy this code, switch to your application and paste it there"
            : "Please click the button for proceed to authorize";

        return (
            <Layout style={{ padding: "0 24px 24px", height: "100vh" }}>
                <Row justify="space-around" align="middle" style={{ height: "100%" }}>
                    <Col span={{ sm: 4, md: 8, lg: 8 }}>&nbsp;</Col>

                    <Col span={{ xs: 24, sm: 8, md: 8, lg: 8 }}>
                        <Layout.Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}>
                            {!!errObj ? (
                                <Result
                                    status="error"
                                    title={errObj.title}
                                    subTitle={errObj.message}
                                    extra={
                                        <Button href={this.props.location.pathname} type="primary" key="login">
                                            Retry
                                        </Button>
                                    }
                                />
                            ) : (
                                <Result
                                    status={query?.code ? "success" : "info"}
                                    title={title}
                                    subTitle={
                                        query?.code && (
                                            <Typography.Text style={{ maxWidth: 480 }} ellipsis={true} copyable={true}>
                                                {query?.code}
                                            </Typography.Text>
                                        )
                                    }
                                    extra={extra}
                                />
                            )}
                        </Layout.Content>
                    </Col>
                    <Col span={{ sm: 4, md: 8, lg: 8 }}>&nbsp;</Col>
                </Row>
            </Layout>
        );
    }
}

export default connect(CommonProps, CommonDispatcher)(MicrosoftCalenderOauth);

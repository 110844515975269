import {
    SplashScreen,
    Notification,
    MessageDialog,
    ConfirmDialog,
    CheckUserSession,
    SetUserSession,
    SessionExpired, SetGeneralSettings,
} from '../../models/actions';

export default (dispatch) => ({
    splash: (show) => dispatch(SplashScreen(show)),
    message: ({title, message, onOkay}) => dispatch(MessageDialog({title, message, onOkay})),
    confirm: (message, yes, no = null) => dispatch(ConfirmDialog({message, onYes: yes, onNo: no})),
    notification: ({title, message, type}) => dispatch(Notification({title, message, type})),
    notificationError: (err) => {
        dispatch(Notification({
            title: 'Error',
            type: 'error',
            message: err.hasOwnProperty("message") ? err.message : err
        }));
    },
    checkSession: (err) => dispatch(CheckUserSession()),
    setUserSession: (user) => dispatch(SetUserSession(user)),
    sessionExpired: (params = null) => dispatch(SessionExpired(params ? params : {})),

    setGeneralSetting: (setting) => dispatch(SetGeneralSettings(setting)),
});


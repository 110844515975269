import React from "react";

import {advertisementService} from '../../services';

import {connect} from "react-redux";
import {
    Button,
    Col,
    Form,
    Row,
    Select, Space, Table, Tag,
} from "antd";
import PageContent from "../../components/page-content";
import {
    DeleteOutlined,
    EditOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import {CommonProps, CommonDispatcher} from '../../store/helpers';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class Container extends React.PureComponent {

    static propTypes = {
        types: PropTypes.array,
    };

    static defaultProps = {};

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        timestamps: null,
        rows: [],
    };

    filterRef = React.createRef();


    columns = [
        {
            dataIndex: ['name'],
            sorter: true,
            title: 'Name'
        },
        {
            dataIndex: 'is_default',
            title: 'Default',
            align: 'center',
            width: 120,
            render: (v, r) => <Tag color={!r.is_default ? 'red' : 'green'}>
                {r.is_default ? 'Yes' : 'No'}
            </Tag>
        },
        {
            dataIndex: 'is_enable',
            title: 'Status',
            align: 'center',
            width: 120,
            render: (v, r) => <Tag color={!r.is_enable ? 'red' : 'green'}>
                {r.is_enable ? 'Enable' : 'Disabled'}
            </Tag>
        },
        {
            dataIndex: ['id'],
            title: 'Actions',
            align: 'right',
            width: 120,
            render: (id, r) => {
                return <Space size={2}>
                    <Link to={`/admin/ads/${r.id}`}>
                        <Button type="link" icon={<EditOutlined/>} title="Edit"/>
                    </Link>
                    <Button danger type="link" icon={<DeleteOutlined/>} title="Delete"
                            onClick={() => this.onDelete(id)}/>
                </Space>
            }
        },
    ];


    refresh = (params = null) => {

        let {pagination, sorter, timestamps, rooms_id} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        if (!timestamps) {
            timestamps = this.state.timestamps;
        }

        const query = {};

        if (timestamps) {
            // use starts date for timestamp
            query.starts = timestamps;
        }

        if (rooms_id) {
            query.rooms_id = rooms_id;
        }

        if (sorter && Object.keys(sorter).length > 0) {
            query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        if (this.props.types) {
            query.types = this.props.types
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        advertisementService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, timestamps, pagination, sorter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    onDelete = (id) => {
        if (id) {
            this.props.confirm(`Are you sure to delete this advertisement?`, () => {
                this.setState({loading: true});
                advertisementService.delete(id).then(rs => {
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                });
            });
        }
    }

    renderFilter = () => {
        const {rooms} = this.state;

        const initialValues = {
            rooms_id: []
        }

        const width = window.innerWidth;

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row>
                {width >= 600 ?
                    <Col span={12}>
                        <Row gutter={[8, 8]}>
                            <Col span={18}>
                                <Form.Item label="Rooms" name="rooms_id">
                                    <Select showSearch allowClear mode="multiple" filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } onChange={() => this.refresh()}>
                                        {rooms && rooms.map(room => <Select.Option key={room.id} value={room.id}>
                                            {room.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" ">
                                    <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                :
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Rooms" name="rooms_id">
                                    <Select showSearch allowClear mode="multiple" filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } onChange={() => this.refresh()}>
                                        {rooms && rooms.map(room => <Select.Option key={room.id} value={room.id}>
                                            {room.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                }
            </Row>
        </Form>
    }

    render() {
        const {rows, pagination, loading} = this.state;

        return <PageContent title="Advertisements" loading={loading} extra={<>
            <Link to={'/admin/ads/create'}>
                <Button key="add" type="primary">
                    Add
                </Button>
            </Link>
        </>}>
            <Space direction="vertical" size={16} style={{width: '100%'}}>
                {this.renderFilter()}
                <Table
                    bordered
                    rowKey={'id'}
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                    columns={this.columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                />
            </Space>
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

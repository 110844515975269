import {
    TeamOutlined,
    SettingOutlined,
    FileTextOutlined,
    DesktopOutlined,
    ControlOutlined,
    ScheduleOutlined,
    BulbOutlined,
    ShopOutlined,
    FileSearchOutlined,
    LineChartOutlined,
    FileExcelOutlined,
    PictureOutlined,
    LaptopOutlined,
    ApiOutlined
} from '@ant-design/icons';
import React from 'react';
import {ACL_ACTIONS} from "./acl";
import {USER_TYPES} from "./index";
import {
    GiDesk
} from 'react-icons/gi';

export const OVERALL_MENUS = [
    {
        id: 'bookings',
        title: 'Bookings',
        path: '/admin/bookings',
        icon: <ScheduleOutlined/>,
        pathPatterns: ['admin/bookings\\w+'],
        permission: [ACL_ACTIONS.BOOKINGS.VIEW],
    },
    {
        id: 'rooms',
        title: 'Rooms',
        path: '/admin/rooms',
        icon: <ShopOutlined/>,
        pathPatterns: ['admin/rooms/\\w+'],
        permission: [ACL_ACTIONS.ROOMS.VIEW],
    },
    {
        id: 'desks',
        title: 'Desks',
        path: '/admin/desks',
        icon: <GiDesk/>,
        pathPatterns: ['admin/desks/\\w+'],
        permission: [ACL_ACTIONS.ROOMS.VIEW],
    },
    {
        id: 'devices',
        title: 'Devices',
        path: '/admin/devices',
        icon: <DesktopOutlined/>,
        pathPatterns: ['admin/devices/\\w+'],
        permission: [ACL_ACTIONS.DEVICES.VIEW],
    },
    {
        id: 'facilities',
        title: 'Facilities',
        path: '/admin/facilities',
        icon: <BulbOutlined/>,
        pathPatterns: ['admin/facilities/\\w+'],
        permission: [ACL_ACTIONS.FACILITIES.VIEW],
    },
    {
        id: 'ads',
        title: 'Advertisements',
        path: '/admin/ads',
        icon: <PictureOutlined/>,
        pathPatterns: ['admin/ads/\\w+'],
        permission: [ACL_ACTIONS.ADS.VIEW],
    },
    {
        id: 'reports',
        title: 'Reports',
        path: '/admin/reports',
        icon: <FileTextOutlined/>,
        pathPatterns: ['admin/reports/\\w+'],
        children: [
            {
                id: 'graph',
                title: 'Graph',
                path: '/admin/reports/graph',
                icon: <LineChartOutlined/>,
                pathPatterns: ['admin/reports/graph/\\w+'],
            },
            {
                id: 'view',
                title: 'View',
                path: '/admin/reports/view',
                icon: <FileExcelOutlined/>,
                pathPatterns: ['admin/report/view/\\w+'],
            }
        ],
        permission: [ACL_ACTIONS.REPORTS.VIEW],
    },
    {
        id: 'users',
        title: 'Users',
        path: '/admin/users',
        icon: <TeamOutlined/>,
        pathPatterns: ['admin/users/\\w+'],
        permission: [ACL_ACTIONS.USERS.VIEW],
    },
    {
        id: 'system',
        title: 'System',
        icon: <ControlOutlined/>,
        pathPatterns: ['admin/system/\\w+'],
        permission: [ACL_ACTIONS.SYSTEM.VIEW],
        children: [
            {
                id: 'settings',
                title: 'Settings',
                path: '/admin/system/settings',
                icon: <SettingOutlined/>,
                pathPatterns: ['admin/system/settings/\\w+'],
                permission: [ACL_ACTIONS.SETTINGS.VIEW],
            },
            {
                id: 'integrations',
                title: 'Integrations',
                path: '/admin/system/integrations',
                icon: <ApiOutlined/>,
                pathPatterns: ['admin/system/integrations/\\w+'],
                permission: [ACL_ACTIONS.INTEGRATIONS.VIEW],
            },
            {
                id: 'logs',
                title: 'Logs',
                path: '/admin/system/logs',
                icon: <FileSearchOutlined/>,
                pathPatterns: ['admin/system/logs/\\w+'],
                permission: [ACL_ACTIONS.LOGS.VIEW],
            }
        ]
    }
]

export function getMenus(auth) {
    return auth?.role === USER_TYPES.SUPER_ADMIN ? OVERALL_MENUS : OVERALL_MENUS.filter(menu => {
        if (menu.hasOwnProperty('permission') && menu.permission) {
            return auth?.permissions ? menu.permission.every(p => auth.permissions.includes(p)) : false
        }
        return true;
    }).map(menu => {
        if (menu.children && menu.children.length > 0) {
            menu.children = menu.children.filter(sMenu => {
                if (sMenu.hasOwnProperty('permission') && sMenu.permission) {
                    return auth?.permissions ? sMenu.permission.every(p => auth.permissions.includes(p)) : false
                }
                return true;
            });
        }
        return menu;
    });
}

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {Helmet} from 'react-helmet';
import Routes from "./routes";

import FAVICON from './assets/images/favicon.ico';
import {APP, SETTING_KEYS} from './constants';

import './App.less'
import {settingService} from "./services";

import {ConfigProvider} from "antd";
import {connect} from "react-redux";
import {CommonDispatcher} from './store/helpers';

import InitContainer from "./containers/init";
import Error500 from './screens/errors/500';

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

class App extends React.Component {
    state = {
        init_done: false,
        general_settings: null,
        error: null
    }

    componentDidMount() {
        this.setState({loading: true})
        settingService.general().then(rs => {
            this.props.setGeneralSetting(rs);
            this.setState({general_settings: rs, init_done: true, loading: false});
        }).catch(err => {
            this.setState({
                error: {
                    err,
                    message: `The server is unreachable, please check network connectivity or try again later.`
                }, loading: false
            });
            this.props.notificationError(err);
        });
    }

    render() {
        const {init_done, general_settings, error} = this.state;

        const companyName = general_settings?.[SETTING_KEYS.SYSTEM.COMPANY.NAME];
        const googleSiteVerification = general_settings?.[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.SITE_VERIFICATION_CODE];
        const systemInitDone = general_settings?.init_done;

        const isReady = init_done && !error;

        return <BrowserRouter>
            <Helmet>
                {googleSiteVerification && <meta name="google-site-verification" content={googleSiteVerification} />}
                <title>{APP.NAME}{companyName ? ` | ${companyName}` : ''}</title>
                <link rel="icon" type="image/x-icon" href={FAVICON}/>
            </Helmet>

            <ConfigProvider componentSize="middle">
                {error && <Error500 error={error.err} title={error.message}/>}
                {isReady && (systemInitDone ? <Routes/> : <InitContainer/>)}
            </ConfigProvider>

        </BrowserRouter>
    }
}

export default connect(null, CommonDispatcher)(App);

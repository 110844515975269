import storage from '../../services/storage';
import {ACCESS_TOKEN} from "../storage";
import {API_HOST} from "../../config";

const axios = require('axios');

const {REACT_APP_API_TIMEOUT_MS} = process.env;

const objectToQueryString = (obj) => {
    return Object.keys(obj).map(key => {
        const v = obj[key];
        return (v !== null && v !== undefined) ? (key + '=' + v) : null;
    }).filter(v => v !== null).join('&');
}

export class Http {
    http;

    baseURL = '';

    constructor({baseURL = ''}) {
        this.http = axios.create({
            baseURL,
            timeout: REACT_APP_API_TIMEOUT_MS ? REACT_APP_API_TIMEOUT_MS : 30000,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        this.baseURL = baseURL;

        this.http.interceptors.request.use((config) => {
            const token = storage.getLocalStorage(ACCESS_TOKEN);
            if (token) {
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        this.http.interceptors.response.use((response) => {
            // do nothing on response
            return response ? response.data : null;
        }, async (error) => {
            let res = error;
            if (error) {
                if (error.response && error.response.data) {
                    if (error.response.data.type === 'application/json') {
                        res = JSON.parse(await error.response.data.text());
                    } else if (error?.response?.headers?.['content-type'].indexOf('/json') > 0) {
                        res = error.response.data
                    }
                } else {
                    res = {
                        message: error.message,
                        statusCode: error.status,
                        error,
                    };
                }
            }

            if (res.statusCode === 401) {
                const token = storage.getLocalStorage(ACCESS_TOKEN);
                if (token) {
                    res.message = `Session expired`;
                }
                if (!window.location.pathname.startsWith('/auth')) {
                    // store.dispatch(ACTIONS.SESSION_EXPIRED());
                }
            }
            return Promise.reject(res);
        });
    }

    get(path, params = null) {
        let query = '';
        if (params) {
            query = '?' + objectToQueryString(params);
        }
        return this.http.get(path + query);
    }

    post(path, body = {}) {
        return this.http.post(path, body);
    }

    postMultipart(path, body = {}) {
        return this.http.post(path, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    put(path, body = {}) {
        return this.http.put(path, body);
    }

    patch(path, body = {}) {
        return this.http.patch(path, body);
    }

    delete(path) {
        return this.http.delete(path);
    }

    getDownload(path, params, {acceptType, download} = {}) {

        console.log(download);

        return this.getDownloadBlob(path, params, {acceptType}).then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if (download) {
                link.download = download;
            } else {
                link.target = '_blank';
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            let timeout = setTimeout(() => {
                window.URL.revokeObjectURL(link.href);
                clearTimeout(timeout);
            }, 100);
            return link;
        });
    }

    getDownloadBlob(path, params, {acceptType} = {}) {
        const headers = {
            'Accept': acceptType,
        };
        const query = Object.keys(params).map(k => {
            const value = Array.isArray(params[k]) ? params[k].join(',') : params[k];
            return `${k}=${value}`
        }).join('&');
        return this.http.get(`${path}${query ? `?${query}` : ''}`, {headers, responseType: 'blob'});
    }
}

export default new Http({baseURL: API_HOST});

import React from 'react';
import {Button, Tooltip} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import commonDispatcher from '../../store/helpers/commons-dispatcher';
import {LogoutOutlined} from '@ant-design/icons';

import {SsoMsalAuthProvider} from "../../services/msal";

class MicrosoftLogoutButton extends React.PureComponent {

    static propTypes = {
        clientId: PropTypes.string,
        tenantId: PropTypes.string,
        autoLogin: PropTypes.bool,
        style: PropTypes.object,
        label: PropTypes.string,
        icon: PropTypes.node,
        afterLogout: PropTypes.func
    };

    static defaultProps = {
        icon: <LogoutOutlined/>,
        label: null,
        afterLogout: () => console.warn('not handled after logout callback')
    };

    msalAuthProvider = null;
    logout = null;

    state = {
        init_done: false,
        authentication_state: null
    };

    componentDidMount() {
        const {clientId, tenantId} = this.props;
        this.msalAuthProvider = SsoMsalAuthProvider(clientId, tenantId);
        this.msalAuthProvider.registerAuthenticationStateHandler((authentication_state) => {
            this.setState({authentication_state});
        });
        this.msalAuthProvider.registerAcountInfoHandler((account_info) => {
            this.setState({account_info});
        });
    }

    render() {
        const {icon, label, afterLogout} = this.props;
        const {authentication_state} = this.state;

        let btn;
        if (authentication_state === null) {
            btn = <Button icon={icon} loading={true} type="default">
                {label}
            </Button>
        } else {
            btn = <Button icon={icon} onClick={() => {
                this.props.confirm(`Are you sure to sign out?`, () => {
                    this.msalAuthProvider.logout();
                    afterLogout();
                });
            }} type="default">
                {label}
            </Button>
        }

        return <Tooltip title="Logout">
            {btn}
        </Tooltip>;
    }
}

export default connect(null, commonDispatcher)(MicrosoftLogoutButton);

import React from "react";
import {connect} from "react-redux";

import {deviceService, roomService, advertisementService} from '../../services';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import PageContent from "../../components/page-content";
import history from "../../routes/history";
import DeviceForm from '../../screens/device/form';

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        device: null,
        rooms: [],
        advertisements: []
    };

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = () => {
        const api = [
            roomService.list(),
            advertisementService.list()
        ]

        if (this.id) {
            api.push(deviceService.get(this.id));
        }

        this.setState({loading: true});
        Promise.all(api).then(rs => {
            this.setState({
                device: rs.length > 2 ? rs[2] : null,
                advertisements: rs[1].items,
                rooms: rs[0].items,
                loading: false,
                init_done: true
            });
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false, init_done: true})
        });
    };

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? deviceService.create(values) : deviceService.update(this.id, values)).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    history.goBack();
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    render() {
        const {init_done, loading, device, rooms, advertisements} = this.state;

        const title = this.isCreate ? 'Add Device' : 'Edit Device';

        return <PageContent title={title} hasBack={true} loading={loading}>
            {init_done &&
            <DeviceForm device={device} rooms={rooms} advertisements={advertisements} onSubmit={this.submit}/>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

import {
    CHECK_SESSION,
    SET_USER_SESSION,
    SESSION_EXPIRED,
    SET_GENERAL_SETTINGS,
    SessionExpired,
    SetUserSession,
    MessageDialog,
} from '../models/actions';
import storage, {GENERAL_SETTINGS, USER} from '../services/storage';
import authService from '../services/api/auth';

import store from '../store';
import {ACL} from "../constants/acl";

let auth = {};
try {
    auth = JSON.parse(storage.getLocalStorage('u'));
} catch (e) {
}

const initial = {
    auth, // this value must always not null! (it's using in everywhere of UI)
};

const flag = {
    session: false,
};
const reducer = (state = initial, {type, payload}) => {
    switch (type) {
        case CHECK_SESSION:
            if (!flag.session) {
                if (authService.hasToken()) {
                    flag.session = true;
                    authService.getProfile().then((rs) => {
                        store.dispatch(SetUserSession(rs));
                    }).catch(err => {
                        if ((!payload.silence)) {
                            store.dispatch(MessageDialog({
                                title: 'Error',
                                type: 'error',
                                message: err.message ? err.message : err,
                            }));
                        }
                        store.dispatch(SessionExpired({redirect: payload.autoLogout}));
                    }).finally(() => flag.session = false);
                }
            } else {
                console.warn('session checking still in process ......');
            }
            break;
        case SET_USER_SESSION:
            payload.role = `${payload.type}`;
            storage.setLocalStorage(USER, payload);

            payload.permissions = ACL.hasOwnProperty(payload.role) ? ACL[payload.role] : [];

            return {...state, auth: payload};
        case SET_GENERAL_SETTINGS:
            storage.setSessionStorage(GENERAL_SETTINGS, payload);
            return {...state, settings: payload};
        case SESSION_EXPIRED:
            authService.flush();
            return {...state, auth: {}};
        default:
    }
    return state;
};

export default reducer;

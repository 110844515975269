import React from 'react'
import {Result, Button} from 'antd';
import history from "../../routes/history";

class PermissionDeniedScreen extends React.PureComponent {

    render() {
        return <Result
            status="warning"
            title="You are not authorize to access this page"
            extra={
                <Button type="primary" key="console" onClick={() => {
                    history.goBack();
                }}>
                    Go Back
                </Button>
            }
        />

    }
}

export default PermissionDeniedScreen;

import * as CryptoJs from 'crypto-js';

const crypto = CryptoJs.AES;

export const USER = 'u';
export const ACCESS_TOKEN = 'at';
export const REQUEST_TOKEN = 'rt';

export const GENERAL_SETTINGS = 'gs';


class Service {
    key = null;

    constructor(key) {
        this.key = key;
        if (!this.key) {
            console.warn(`storage is not secure, please define the app secure key!`);
        }
    }

    setLocalStorage = (key, value) => {
        const v = typeof (value) === 'object' ? JSON.stringify(value) : value;
        localStorage.setItem(key, !!this.key ? crypto.encrypt(v, this.key) : v);
    };

    getLocalStorage = (key) => {
        const v = localStorage.getItem(key);
        if (!!this.key) {
            try {
                return crypto.decrypt(v, this.key).toString(CryptoJs.enc.Utf8);
            } catch (e) {
                console.warn(`invalid storage payload`);
            }
        } else {
            return v;
        }
    };

    removeLocalStorage = (key) => {
        localStorage.removeItem(key);
    };

    hasKeyLocalStorage = (key) => {
        return localStorage.getItem(key) !== null;
    };

    getLocalStorageAsObject = (key, defaultValue = null) => {
        try {
            return JSON.parse(this.getLocalStorage(key));
        } catch (e) {
        }
        return defaultValue;
    };

    setSessionStorage = (key, value) => {
        const v = typeof (value) === 'object' ? JSON.stringify(value) : value;
        sessionStorage.setItem(key, v);
    };

    getSessionStorage = (key) => {
        return sessionStorage.getItem(key);
    };

    getSessionStorageAsObject = (key, defaultValue = null) => {
        try {
            const v = this.getSessionStorage(key);
            return v ? JSON.parse(v) : defaultValue;
        } catch (e) {
        }
        return defaultValue;
    };
}

export default new Service(process.env.REACT_APP_KEY);

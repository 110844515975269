import http from './http';

class ReportService {

    list(start = null, limit = null, query = {}) {
        return http.get('/api/reports', {start, limit, ...query});
    }

    create(dto) {
        return http.post(`/api/reports`, dto);
    }

    update(id, dto) {
        return http.put(`/api/reports/${id}`, dto);
    }

    get(id) {
        return http.get(`/api/reports/${id}`);
    }

    delete(id) {
        return http.delete(`/api/reports/${id}`);
    }

    run(id) {
        return http.get(`/api/reports/${id}/run`);
    }

    //----
    graphGetBookingByRooms(timestamps, rooms_id = null) {
        return http.get(`/api/reports/graph/booking-by-room`, {timestamps, rooms_id});
    }

    graphGetBookingDurationByRooms(timestamps, rooms_id = null) {
        return http.get(`/api/reports/graph/booking-duration-by-room`, {timestamps, rooms_id});
    }


}

export default new ReportService();

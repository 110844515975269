import React, {PureComponent} from 'react';
import {Layout} from "antd";

import './style.css';
import Session from "./session";

export default function (AppContent) {

    class AuthLayout extends PureComponent {

        render = () => {
            return <Layout>
                <Session require={false} url={'/admin'} firstTimeLoginUrl={'/first-time-login'}/>
                <AppContent {...this.props}/>
            </Layout>;
        }
    }

    return AuthLayout;
}

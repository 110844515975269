import React from "react";
import {Button, Form, Input, InputNumber} from "antd";
import {SETTING_KEYS} from "../../../constants";
import {LinkOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

class AuthLdapForm extends React.PureComponent {

    static propTypes = {
        onTest: PropTypes.func
    };

    static defaultProps = {
        onTest: (v) => console.warn('action not register', v),
    };

    render() {
        return <>
            <Form.Item label="Server" name={SETTING_KEYS.AUTH.SSO.LDAP.HOST} rules={[{
                required: true,
                message: 'This field is require',
            }]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Server Port" name={SETTING_KEYS.AUTH.SSO.LDAP.PORT} rules={[
                {
                    required: true,
                    message: 'This field is require',
                }
            ]}>
                <InputNumber min={1} max={65535}/>
            </Form.Item>

            <Form.Item label="Base DN" name={SETTING_KEYS.AUTH.SSO.LDAP.BASE_DN} rules={[{
                required: true,
                message: 'This field is require',
            }]}>
                <Input/>
            </Form.Item>

            <Form.Item label="User Filter" name={SETTING_KEYS.AUTH.SSO.LDAP.USER_FILTER}>
                <Input/>
            </Form.Item>

            <Form.Item label="Bind Attribute" name={SETTING_KEYS.AUTH.SSO.LDAP.BIND_ATTR}>
                <Input/>
            </Form.Item>

            <Form.Item label="Login Attribute" name={SETTING_KEYS.AUTH.SSO.LDAP.LOGIN_ATTR}>
                <Input/>
            </Form.Item>

            <Form.Item label="Authenticate Username" name={SETTING_KEYS.AUTH.SSO.LDAP.AUTH_USERNAME}>
                <Input/>
            </Form.Item>
            <Form.Item label="Authenticate Password" name={SETTING_KEYS.AUTH.SSO.LDAP.AUTH_PASSWORD}>
                <Input type="password"/>
            </Form.Item>

            <Form.Item label="Test Connection">
                <Button icon={<LinkOutlined/>} type="default" htmlType="button" onClick={this.props.onTest}>
                    Test
                </Button>
            </Form.Item>
        </>
    }
}

export default AuthLdapForm;

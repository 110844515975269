import React from 'react';
import {Button, Input} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CommonProps, CommonDispatcher} from '../../store/helpers';

import {AuthenticationState} from "react-aad-msal";
import {SsoMsalAuthProvider} from "../../services/msal";

import MicrosoftLogoutButton from './microsoft-logout-button';

class MicrosoftLoginButton extends React.Component {

    static propTypes = {
        clientId: PropTypes.string,
        tenantId: PropTypes.string,
        autoLogin: PropTypes.bool,
        style: PropTypes.object,
        label: PropTypes.string,
        icon: PropTypes.node,
        onAuthenticated: PropTypes.func
    };

    static defaultProps = {
        autoLogin: false,
        label: 'Sign in with Microsoft',
        loading: false,
        onAuthenticated: () => console.warn('not handled authenticated callback')
    };

    msalAuthProvider = null;

    state = {
        init_done: false,

        account_info: null,
        authentication_state: null
    };

    componentDidMount() {
        const {clientId, tenantId, onAuthenticated, autoLogin} = this.props;
        const checkAutoLogin = (account_info, authentication_state) => {
            if (autoLogin) {
                if (authentication_state === AuthenticationState.Authenticated && account_info) {
                    onAuthenticated(account_info);
                }
            }
        };

        this.msalAuthProvider = SsoMsalAuthProvider(clientId, tenantId);

        this.msalAuthProvider.registerAcountInfoHandler((account_info) => {
            // console.log('a', account_info);
            this.setState({account_info});
            checkAutoLogin(account_info, this.state.authentication_state);
        });
        this.msalAuthProvider.registerAuthenticationStateHandler((authentication_state) => {
            // console.log('s', authentication_state);
            this.setState({authentication_state});
            checkAutoLogin(this.state.account_info, authentication_state);
        });
    }

    button(label, loading = false, onClick) {
        const {icon, style} = this.props;
        return <Button icon={icon} onClick={onClick} type="primary" style={style} loading={loading}>
            {label}
        </Button>
    }

    render() {
        const {label, clientId, onAuthenticated} = this.props;

        const {account_info, authentication_state} = this.state;

        return <Input.Group compact>
            {authentication_state === AuthenticationState.Authenticated ?
                this.button(`Continue with ${account_info.account.name}`, false, () => {
                    onAuthenticated(account_info);
                }) :
                this.button(label, !authentication_state, () => {
                    this.msalAuthProvider.login().catch(err => {
                        this.props.notificationError(err);
                    })
                })
            }
            {authentication_state === AuthenticationState.Authenticated && <MicrosoftLogoutButton afterLogout={() => {
                // do nothing (the component ady did logout)
                this.props.sessionExpired();
            }} clientId={clientId}/>}
        </Input.Group>

        /*return this.state.init_done ? <AzureAD provider={this.msalAuthProvider} forceLogin={false}>
            {
                ({login, logout, authenticationState, error, accountInfo}) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            // console.log(accountInfo);
                            if (autoLogin && onAuthenticated) {
                                onAuthenticated(accountInfo);
                            }
                            return <Input.Group compact>
                                {this.button(`Continue with ${accountInfo.account.name}`, false, () => {
                                    onAuthenticated(accountInfo);
                                })}
                                <MicrosoftLogoutButton afterLogout={() => {
                                    logout();
                                }} clientId={clientId}/>
                            </Input.Group>

                        case AuthenticationState.Unauthenticated:
                            if (error) {
                                // console.warn(error);
                                const {errorMessage, errorCode} = error;
                                this.props.notification({message: `${errorMessage} (${errorCode})`, type: 'error'});
                            }
                            return this.button(label, false, login);
                        case AuthenticationState.InProgress:
                            return this.button(label, true, null);
                        default:
                            console.warn(`unknown state ${authenticationState}`)
                    }
                }
            }
        </AzureAD> : '';*/
    }
}

export default connect(CommonProps, CommonDispatcher)(MicrosoftLoginButton);

import React from "react";

import {APP} from "../../constants";
import * as moment from 'moment-timezone';
import {connect} from 'react-redux';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import {authService} from '../../services';

import LOGO from '../../assets/images/logo-long.png';
import {Spin, Button, Card, Col, Form, Input, Row, Space, Typography, Modal} from "antd";
import {UserOutlined, KeyOutlined, LockOutlined} from '@ant-design/icons';
import {withRouter} from "react-router";

class Container extends React.PureComponent {

    now = moment();

    state = {
        loading: false,
        request: {
            is_requested: false,
            sent_to_email: null,
            username: null
        }
    };

    requestForgotPassword = ({username}) => {
        this.setState({loading: true});
        return authService.forgetPassword(username).then(rs => {
            this.setState({
                request: {
                    username,
                    is_requested: true,
                    sent_to_email: rs.email
                },
                loading: false
            })
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    submitForgotPassword = ({token, password}) => {
        const {username} = this.state.request;

        this.setState({loading: true});
        return authService.resetPassword(username, token, password).then(rs => {

            this.props.notification({
                message: 'Success Updated Password. You may login with your new password now',
                type: 'success'
            });

            this.props.history.replace('/login');
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    render() {
        const {app_settings} = this.props;
        const {loading, request} = this.state;

        const compLink = APP.APP_POWERED_BY_WEBSITE ?
            <a style={{color: 'black'}} href={APP.APP_POWERED_BY_WEBSITE} target="_blank" rel="noopener noreferrer">
                {APP.APP_POWERED_BY}
            </a> : APP.APP_POWERED_BY

        const Footer = () => <>
            <Button type="link" href="/login">Back to Login</Button>
            <Typography.Text style={{opacity: 0.5, fontSize: 12}}>
                {compLink} &copy;{this.now.year()} - v{APP.VERSION}
            </Typography.Text>
        </>

        return <Spin spinning={loading}>
            <Row justify="center" align="middle" style={{height: '100vh'}}>
                <Col xs={22} sm={20} md={12} lg={12} xl={8}>
                    <Card>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col span={24}>
                                <Space size={2} style={{width: '100%', textAlign: 'center'}} direction="vertical">
                                    <img alt="Logo" style={{width: '80%', padding: 16}} src={LOGO}/>

                                    <Typography.Title level={3}>
                                        Reset Password
                                    </Typography.Title>

                                    <Typography.Text>
                                        Enter your username and we will send you instructions for resetting your
                                        password.
                                    </Typography.Text>

                                    {request?.is_requested && <>
                                        <br/>
                                        <Typography.Text>
                                            The verification code has been sent to {request?.sent_to_email}
                                        </Typography.Text>
                                        <Typography.Text>
                                            Please check your mailbox for the verification code.
                                        </Typography.Text>
                                    </>}

                                </Space>
                            </Col>

                            <Col md={24}>
                                {!request?.is_requested &&
                                <Form layout="vertical" onFinish={this.requestForgotPassword}>
                                    <Form.Item name={'username'} rules={[{
                                        required: true,
                                        message: 'This field is require',
                                    }]}>
                                        <Input placeholder="username" prefix={<UserOutlined/>}/>
                                    </Form.Item>

                                    <Form.Item>
                                        <Space align="center" direction="vertical"
                                               style={{width: '100%', textAlign: 'center'}}>
                                            <Button htmlType="submit" type="primary">Submit</Button>
                                            {Footer()}
                                        </Space>
                                    </Form.Item>
                                </Form>}

                                {request?.is_requested &&
                                <Form layout="vertical" onFinish={this.submitForgotPassword}>
                                    <Form.Item name="token" rules={[{
                                        required: true,
                                        message: 'This field is require',
                                    }]}>
                                        <Input placeholder="Verification code" prefix={<KeyOutlined/>}/>
                                    </Form.Item>

                                    <Form.Item name="password" rules={[{
                                        required: true,
                                        message: 'This field is require',
                                    }]}>
                                        <Input.Password placeholder="New password" prefix={<LockOutlined/>}/>
                                    </Form.Item>
                                    <Form.Item name="retype_password" rules={[
                                        {required: true, message: 'Please retype your new password'},
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                        <Input.Password placeholder="Retype new password" prefix={<LockOutlined/>}/>
                                    </Form.Item>

                                    <Form.Item>
                                        <Space align="center" direction="vertical"
                                               style={{width: '100%', textAlign: 'center'}}>
                                            <Button htmlType="submit" type="primary">Submit</Button>
                                            {Footer()}
                                        </Space>
                                    </Form.Item>
                                </Form>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Spin>
    }
}

export default connect(CommonProps, CommonDispatcher)(withRouter(Container));

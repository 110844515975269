import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Form,
    Input,
    Switch
} from "antd";
import {connect} from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import * as moment from 'moment';
import SortableDragAndDropComponent from "../../components/sortable-dnd-image";
import fileHelper from '../../helpers/file';

class AdsFormScreen extends React.PureComponent {

    static propTypes = {
        ads: PropTypes.object,
        devices: PropTypes.array,
        onSubmit: PropTypes.func,
        rooms: PropTypes.array
    };

    static defaultProps = {
        devices: [],
        onSubmit: (v) => console.warn('action not register', v),
    };

    formRef = React.createRef();

    handleSubmit = async (form) => {
        if (form.images) {
            form.images = await Promise.all(form.images.map(async (img, index) => {
                const {originFileObj} = img;
                return {
                    id: originFileObj ? originFileObj.uid : img.id,
                    pos: index,
                    base64: originFileObj ? await fileHelper.fileToBase64(originFileObj) : null
                }
            }).filter(v => !!v));
        }

        console.log(form);

        this.props.onSubmit(form);
    }

    render() {
        const {ads} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'left',
            colon: false,
            initialValues: {
                name: ads?.name,
                is_enable: ads ? ads.is_enable : true,
                is_default: ads?.is_default,
                description: ads?.description,
                validity: [
                    ads?.valid_from ? moment(ads.valid_from) : null,
                    ads?.valid_from ? moment(ads.valid_to) : null
                ],
                devices: ads?.devices,
                images: ads?.images ? ads.images.map(img => {
                    return {
                        ...img,
                        uid: img.id
                    }
                }) : []
            }
        };

        return <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef}>

            <Form.Item label="Name" name="name" rules={[{
                required: true,
                message: 'Name is require',
            }, {
                max: 16,
                message: 'Maximum 32 characters',
            }]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Description" name="description" rules={[{
                max: 64,
                message: 'Maximum 64 characters',
            }]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Enable" name="is_enable" valuePropName="checked">
                <Switch/>
            </Form.Item>

            <Form.Item label="Auto Assign" name="is_default" valuePropName="checked" extra={<>
                Ads will auto assign to new added device
            </>}>
                <Switch/>
            </Form.Item>

            <Form.Item wrapperCol={24} label="Images" name="images" valuePropName="files">
                <SortableDragAndDropComponent maxFile={10}/>
            </Form.Item>

            <Form.Item label=" ">
                <Button htmlType="submit" type="primary">
                    {ads ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    }
}

export default connect(null, CommonDispatcher)(AdsFormScreen);

import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Form,
    Input, Select
} from "antd";
import {connect} from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import {DEVICE_TYPES} from "../../constants";
import formatter from "../../helpers/formatter";
import {RoomsSelectors} from "../../components/selectors";

class RoomFormScreen extends React.PureComponent {

    static propTypes = {
        device: PropTypes.object,
        onSubmit: PropTypes.func,
        rooms: PropTypes.array,
        advertisements: PropTypes.array
    };

    static defaultProps = {
        rooms: [],
        advertisements: [],
        onSubmit: (v) => console.warn('action not register', v),
    };

    state = {}

    formRef = React.createRef();

    handleSubmit = (form) => {
        this.props.onSubmit(form);
    }

    render() {
        const {device, rooms, advertisements} = this.props;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'left',
            colon: false,
            initialValues: {
                name: device?.name,
                type: device?.type,
                ip: device?.ip,
                mac: device?.mac,
                serial: device?.serial,
                room: device?.room?.id,
                advertisement: device?.advertisement?.id
            }
        };

        return <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef}>

            <Form.Item label="Name" name="name" rules={[{
                required: true,
                message: 'Name is require',
            }]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Type" name="type" rules={[{
                required: true,
                message: 'Please select the device type',
            }]}>
                <Select>
                    {Object.values(DEVICE_TYPES).map(v => <Select.Option value={v} key={v}>
                        {formatter.toDisplayDeviceType(v)}
                    </Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item label="IP Address" name="ip">
                <Input/>
            </Form.Item>
            <Form.Item label="MAC Address" name="mac">
                <Input/>
            </Form.Item>
            <Form.Item label="Serial No." name="serial">
                <Input/>
            </Form.Item>

            <Form.Item label="Room/Desk" name="room">
                <RoomsSelectors rooms={rooms}/>
            </Form.Item>

            <Form.Item label="Advertisement" name="advertisement">
                <Select allowClear>
                    {advertisements && advertisements.map(ads => <Select.Option value={ads.id} key={ads.id}>
                        {ads.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item label=" ">
                <Button htmlType="submit" type="primary">
                    {device ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    }
}

export default connect(null, CommonDispatcher)(RoomFormScreen);

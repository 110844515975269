import http from './http';

class UserService {

    list(offset, limit, query = {}) {
        return http.get(`/api/users`, {
            ...query,
            offset,
            limit
        });
    }

    get(id) {
        return http.get(`/api/users/${id}`);
    }

    create(dto) {
        return http.post(`/api/users`, dto);
    }

    update(id, dto) {
        return http.put(`/api/users/${id}`, dto);
    }

    delete(id, force = false) {
        return http.delete(`/api/users/${id}${force ? '?force=1' : ''}`);
    }

    import(file, option) {
        const formData = new FormData();
        formData.append('file', file);
        if (option) {
            Object.keys(option).forEach(k => {
                formData.append(k, option[k]);
            })
        }
        return http.postMultipart(`/api/users/import`, formData);
    }

    downloadTemplate() {
        return http.getDownload(`/api/users/export/template`, {}, {
            acceptType: "application/excel",
            download: 'user-template.xlsx'
        });
    }
}

export default new UserService();

import {notification, Modal} from 'antd';
import React from 'react';
import {MessageOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

import {
    GLOBAL_LOADING,
    SPLASH_SCREEN,
    NOTIFICATION,
    MESSAGE_DIALOG,
    CONFIRM_DIALOG,
} from '../models/actions';

const initial = {
    loading: false,
    splash: {show: false},
    notification: {show: false},
    message: {show: false},
    confirm: {show: false},
};

const reducer = (state = initial, {type, payload}) => {
    switch (type) {
        case SPLASH_SCREEN:
            return {...state, splash: payload};
        case GLOBAL_LOADING:
            return {...state, loading: payload};
        case NOTIFICATION:
            if (payload.show) {
                const p = {
                    message: payload.message,
                };
                switch (payload.type) {
                    case 'success':
                        notification.success(p);
                        break;
                    case 'warn':
                        notification.warn(p);
                        break;
                    case 'error':
                        notification.error(p);
                        break;
                    case 'info':
                        notification.info(p);
                        break;
                    default:
                        notification.info(p);
                }
            }
            return {...state, notification: payload};
        case MESSAGE_DIALOG:
            if (payload.show) {
                let modal = Modal.info({
                    title: payload.title,
                    icon: <MessageOutlined/>,
                    content: payload.message,
                    okText: 'Okay',
                    cancelButtonProps: {style: {display: 'none'}},
                    onOk: () => {
                        if (payload.onOkay) {
                            payload.onOkay();
                        }
                        modal.destroy();
                    },
                });
            }
            return {...state, message: payload};
        case CONFIRM_DIALOG:
            if (payload.show) {
                Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined/>,
                    content: payload.message,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: payload.onYes,
                    onCancel: payload.onNo,
                });
            }
            return {...state, confirm: payload};
        default:
    }
    return state;
};

export default reducer;

import http from './http';

import storage, {ACCESS_TOKEN, USER, REQUEST_TOKEN} from '../storage';

class AuthService {

    onSuccessLogin = (rs) => {
        if (rs && rs.token) {
            storage.setLocalStorage(ACCESS_TOKEN, rs.token);
        }
        return rs;
    };

    login(username, password, method = null) {
        const params = {username, password};
        if (method) {
            params.method = method;
        }
        return http.post('/api/auth/login', params).then(this.onSuccessLogin);
    }

    loginWith3rdParty(payload, type) {
        return http.post(`/api/auth/login/${type}`, payload).then(this.onSuccessLogin);
    }

    getProfile() {
        return http.get('/api/auth/profile');
    }

    hasToken() {
        return storage.hasKeyLocalStorage(ACCESS_TOKEN);
    }

    flush() {
        storage.removeLocalStorage(USER);
        storage.removeLocalStorage(REQUEST_TOKEN);
        storage.removeLocalStorage(ACCESS_TOKEN);
    }

    logout() {
        return new Promise((resolve, reject) => {
            this.flush();
            resolve({message: 'success'});
        });
    }

    forgetPassword(username) {
        return http.post('/api/auth/forgot-password', {username});
    }

    resetPassword(username, token, password) {
        return http.post('/api/auth/reset-password', {username, token, password});
    }

    updatePassword(current, new_password) {
        return http.put('/api/auth/password', {current, password: new_password});
    }

    forceUpdatePassword(password) {
        return http.put('/api/auth/password', {password});
    }
}

export default new AuthService();

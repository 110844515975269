import React from 'react';
import {Button, Tooltip} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import commonDispatcher from '../../store/helpers/commons-dispatcher';
import {LogoutOutlined} from '@ant-design/icons';

import {GoogleLogout} from "react-google-login";

class GoogleLogoutButton extends React.PureComponent {

    static propTypes = {
        clientId: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.node,
        afterLogout: PropTypes.func
    };

    static defaultProps = {
        icon: <LogoutOutlined/>,
        label: null,
        afterLogout: () => console.warn('not handled authenticated callback')
    };

    state = {
        loading: false
    };

    button(label, loading = false, onClick) {
        const {icon, style} = this.props;
        return <Button icon={icon} onClick={onClick} type="primary" style={style} loading={loading}>
            {label}
        </Button>
    }

    render() {
        const {label, icon, clientId, afterLogout} = this.props;
        const {loading} = this.state;

        return <GoogleLogout
            clientId={clientId}
            isSignedIn={true}
            accessType="offline"
            onLogoutSuccess={(rs) => {
                afterLogout();
                this.setState({loading: false});
            }}
            onFailure={(rs) => {
                console.error(rs);
                // this.props.notification({message: rs, type: 'error'})
                this.setState({loading: false});
            }}
            render={renderProps => {
                return <Tooltip title="Logout">
                    <Button icon={icon} onClick={() => {
                        this.props.confirm(`Are you sure to logout?`, () => {
                            this.setState({loading: true});
                            renderProps.onClick();
                        })
                    }} loading={loading} disabled={renderProps.disabled}>{label}</Button>
                </Tooltip>
            }}
        />
    }
}

export default connect(null, commonDispatcher)(GoogleLogoutButton);

import React from "react";
import {Select, Card, Button, Form, Input, Space, InputNumber, Modal, Switch} from "antd";
import {AUTH_METHODS, MAILER_TYPES, SETTING_KEYS} from '../../constants';
import PropTypes from "prop-types";
import {CommonDispatcher, CommonProps} from '../../store/helpers';
import {connect} from "react-redux";
import formatter from "../../helpers/formatter";
import AuthLdapForm from './forms/auth-ldap-form';
import {formLayoutColInput} from "./config";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";

export const USING_KEYS = [
    SETTING_KEYS.SYSTEM.COMPANY.NAME,
    SETTING_KEYS.SYSTEM.COMPANY.SUPPORT_EMAIL,

    SETTING_KEYS.AUTH.METHOD,

    ...Object.values(SETTING_KEYS.AUTH.SSO.LDAP),
    ...Object.values(SETTING_KEYS.AUTH.SSO.GOOGLE),
    ...Object.values(SETTING_KEYS.AUTH.SSO.MICROSOFT),

    ...Object.values(SETTING_KEYS.MAILER)
];

class SystemForm extends React.PureComponent {

    static propTypes = {
        settings: PropTypes.object,
        onSubmit: PropTypes.func,
        onTestLdap: PropTypes.func,
        onTestEmail: PropTypes.func
    };

    static defaultProps = {
        onSubmit: (v) => console.warn('action not register', v),
        onTestLdap: (v) => console.warn('action not register', v),
        onTestEmail: (v) => console.warn('action not register', v),
    };

    formRef = React.createRef();

    state = {
        auth_method: AUTH_METHODS.LOCAL,
        selected_type: MAILER_TYPES.GENERIC,
        showTestEmail: false,
        sendTo: null
    }

    constructor(props, context) {
        super(props, context);
        if (props.settings?.[SETTING_KEYS.AUTH.METHOD]) {
            this.state.auth_method = props.settings[SETTING_KEYS.AUTH.METHOD];
        }
        if (props.settings?.[SETTING_KEYS.MAILER.TYPE]) {
            this.state.selected_type = props.settings[SETTING_KEYS.MAILER.TYPE];
        }
    }

    onFinish = (form) => {
        this.props.onSubmit(form);
    }

    doTestLdap = () => {
        this.props.onTestLdap(this.formRef.current.getFieldsValue());
    }

    emailFormItems() {
        const {selected_type, showTestEmail} = this.state;

        return <>
            <Form.Item label="Sender Name" name={SETTING_KEYS.MAILER.SENDER_NAME}>
                <Input/>
            </Form.Item>
            <Form.Item label="Sender Email" name={SETTING_KEYS.MAILER.SENDER_EMAIL}>
                <Input/>
            </Form.Item>

            <Form.Item label="Type" name={SETTING_KEYS.MAILER.TYPE}>
                <Select>
                    {Object.values(MAILER_TYPES).map(v => <Select.Option key={v} value={v}>
                        {formatter.toDisplayMailerType(v)}
                    </Select.Option>)}
                </Select>
            </Form.Item>

            {selected_type === MAILER_TYPES.MAILGUN && <>
                <Form.Item label="API Key" name={SETTING_KEYS.MAILER.API_KEY}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Domain" name={SETTING_KEYS.MAILER.DOMAIN}>
                    <Input/>
                </Form.Item>
            </>}

            {selected_type === MAILER_TYPES.GENERIC && <>
                <Form.Item label="Host" name={SETTING_KEYS.MAILER.HOST}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Port" name={SETTING_KEYS.MAILER.PORT}>
                    <InputNumber min={1} max={65535}/>
                </Form.Item>
                <Form.Item label="Username" name={SETTING_KEYS.MAILER.USERNAME}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Password" name={SETTING_KEYS.MAILER.PASSWORD}>
                    <Input.Password/>
                </Form.Item>
            </>}

            <Form.Item label="Unsecure" name={SETTING_KEYS.MAILER.UNSECURE_TLS} valuePropName="checked"
                       tooltip="Ignore unsecure TLS">
                <Switch/>
            </Form.Item>

            <Form.Item label=" ">
                <Button type="default" htmlType="button" onClick={() => {
                    this.setState({showTestEmail: true});
                }}>Test</Button>
            </Form.Item>

            <Modal visible={showTestEmail} onCancel={() => this.setState({showTestEmail: false})} footer={null}>
                <Form layout="vertical" onFinish={(form) => {
                    const params = {};
                    for (const k of Object.values(SETTING_KEYS.MAILER)) {
                        params[k] = this.formRef.current.getFieldValue(k);
                    }
                    this.setState({showTestEmail: false}, () => this.props.onTestEmail({...params, ...form}));
                }}>
                    <Form.Item label="Send to" name="to" rules={[{
                        required: true, message: 'This field is require'
                    }]}>
                        <Input type="email" onChange={v => this.setState({sendTo: v})}/>
                    </Form.Item>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        <Button type="default" htmlType="button" onClick={() => {
                            this.setState({showTestEmail: false});
                        }}>
                            Close
                        </Button>
                        &nbsp;
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    }

    render() {
        const formItemLayout = {
            ...formLayoutColInput,
            onValuesChange: (values) => {
                if (values.hasOwnProperty(SETTING_KEYS.AUTH.METHOD)) {
                    this.setState({auth_method: values[SETTING_KEYS.AUTH.METHOD]});
                }
                if (values.hasOwnProperty(SETTING_KEYS.MAILER.TYPE)) {
                    this.setState({selected_type: values[SETTING_KEYS.MAILER.TYPE]});
                }
            }
        };

        const {auth_method} = this.state;
        const {auth, settings} = this.props;

        const initialValues = {};

        USING_KEYS.forEach(k => {
            initialValues[k] = settings?.[k]
        });

        const allowEditAuth = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_SYSTEM_AUTH);

        return <>
            <Form {...formItemLayout} ref={this.formRef} initialValues={initialValues} onFinish={this.onFinish}>
                <Space style={{width: '100%'}} direction="vertical" size={16}>
                    <Card title="General" size="small">
                        <Form.Item label="Company Name" name={SETTING_KEYS.SYSTEM.COMPANY.NAME} rules={[{
                            required: true,
                            message: 'Name is require',
                        }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Company Email" name={SETTING_KEYS.SYSTEM.COMPANY.SUPPORT_EMAIL}>
                            <Input type="email"/>
                        </Form.Item>
                    </Card>

                    {allowEditAuth && <Card title="Authentication" size="small">
                        <Form.Item label="Method" name={SETTING_KEYS.AUTH.METHOD} rules={[{
                            required: true,
                            message: 'Please select the authenticate method',
                        }]}>
                            <Select>
                                {[
                                    AUTH_METHODS.LOCAL,
                                    AUTH_METHODS.GOOGLE,
                                    AUTH_METHODS.MICROSOFT,
                                    AUTH_METHODS.LDAP,
                                ].map(v => <Select.Option key={v} value={v}>
                                    {formatter.toDisplayAuthMethod(v)}
                                </Select.Option>)}
                            </Select>
                        </Form.Item>

                        {auth_method === AUTH_METHODS.GOOGLE && <>
                            <Form.Item label="Client ID" name={SETTING_KEYS.AUTH.SSO.GOOGLE.CLIENT_ID} rules={[{
                                required: true,
                                message: 'This field is require',
                            }]}>
                                <Input/>
                            </Form.Item>
                        </>}

                        {auth_method === AUTH_METHODS.MICROSOFT && <>
                            <Form.Item label="Client ID" name={SETTING_KEYS.AUTH.SSO.MICROSOFT.CLIENT_ID} rules={[{
                                required: true,
                                message: 'This field is require',
                            }]}>
                                <Input/>
                            </Form.Item>

                            <Form.Item label="Tenant ID" name={SETTING_KEYS.AUTH.SSO.MICROSOFT.TENANT_ID} rules={[{
                                required: true,
                                message: 'This field is require',
                            }]}>
                                <Input/>
                            </Form.Item>
                        </>}

                        {auth_method === AUTH_METHODS.LDAP && <AuthLdapForm onTest={this.doTestLdap}/>}
                    </Card>}

                    <Card title="Email" size="small">
                        {this.emailFormItems()}
                    </Card>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </>
    }
}

export default connect(CommonProps, CommonDispatcher)(SystemForm);

import http from './http';

class FacilityService {
    list(offset = null, limit = null, query = {}) {
        return http.get('/api/facilities', {offset, limit, ...query});
    }

    get(id) {
        return http.get(`/api/facilities/${id}`);
    }

    update(id, params) {
        return http.put(`/api/facilities/${id}`, params);
    }

    create(params) {
        return http.post(`/api/facilities`, params);
    }

    delete(id) {
        return http.delete(`/api/facilities/${id}`);
    }
}

export default new FacilityService();

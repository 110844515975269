import {USER_TYPES} from "./index";

export const ACL_ACTIONS = {
    BOOKINGS: {
        BOOK_ON_BEHALF: 'booking.book_on_behalf',

        VIEW: 'booking.view',
        CREATE: 'booking.create',
        UPDATE: 'booking.update',
        CANCEL: 'booking.cancel',
        DELETE: 'booking.delete',
    },
    SETTINGS: {
        VIEW: 'settings.view',

        UPDATE_SYSTEM: 'settings.update_system',
        UPDATE_DEVICE: 'settings.update_device',

        UPDATE_NOTIFICATION: 'settings.update_update_notification',
        UPDATE_SYSTEM_AUTH: 'settings.update_sys_auth',
        UPDATE_BOOKING_MODE: 'settings.update_booking_mode',
        UPDATE_BOOKING_INTEGRATION_CALENDER: 'settings.update_booking_integration_calender',
        UPDATE_BOOKING_AUTO_RELEASE_GRACE_PERIOD: 'settings.update_booking_auto_release_grace_period',
    },
    INTEGRATIONS: {
        VIEW: 'integrations.view'
    },
    LOGS: {
        VIEW: 'logs.view'
    },
    REPORTS: {
        VIEW: 'reports.view',
        CREATE: 'reports.create',
    },
    DEVICES: {
        VIEW: 'devices.view'
    },
    FACILITIES: {
        VIEW: 'facilities.view',
        CREATE: 'facilities.create',
    },
    ROOMS: {
        VIEW: 'rooms.view',
        CREATE: 'rooms.create',
    },
    ADS: {
        VIEW: 'ads.view'
    },
    SYSTEM: {
        VIEW: 'system.view'
    },
    USERS: {
        VIEW: 'users.view',

        CREATE: 'users.create',
        UPDATE: 'users.update',
        DELETE: 'users.delete'
    }
}

export const ACL = {
    [USER_TYPES.ADMIN]: [
        ACL_ACTIONS.BOOKINGS.BOOK_ON_BEHALF,

        ACL_ACTIONS.BOOKINGS.VIEW,
        ACL_ACTIONS.BOOKINGS.CREATE,
        ACL_ACTIONS.BOOKINGS.UPDATE,
        ACL_ACTIONS.BOOKINGS.CANCEL,

        ACL_ACTIONS.USERS.CREATE,
        ACL_ACTIONS.USERS.UPDATE,
        ACL_ACTIONS.USERS.DELETE,

        ACL_ACTIONS.ROOMS.VIEW,
        ACL_ACTIONS.ROOMS.CREATE,

        ACL_ACTIONS.DEVICES.VIEW,
        ACL_ACTIONS.USERS.VIEW,

        ACL_ACTIONS.FACILITIES.VIEW,
        ACL_ACTIONS.FACILITIES.CREATE,

        ACL_ACTIONS.REPORTS.VIEW,
        ACL_ACTIONS.REPORTS.CREATE,

        /*ACL_ACTIONS.SYSTEM.VIEW,
        ACL_ACTIONS.SETTINGS.VIEW,

        ACL_ACTIONS.SETTINGS.UPDATE_NOTIFICATION,
        ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_INTEGRATION_CALENDER,*/
    ],

    [USER_TYPES.IT]: [
        ACL_ACTIONS.BOOKINGS.VIEW,
        ACL_ACTIONS.BOOKINGS.CREATE,
        ACL_ACTIONS.BOOKINGS.UPDATE,
        ACL_ACTIONS.BOOKINGS.CANCEL,

        ACL_ACTIONS.DEVICES.VIEW,
        /*ACL_ACTIONS.SYSTEM.VIEW,
        ACL_ACTIONS.SETTINGS.VIEW,
        ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_INTEGRATION_CALENDER,
        ACL_ACTIONS.SETTINGS.UPDATE_NOTIFICATION*/
    ],

    [USER_TYPES.STAFF]: [
        ACL_ACTIONS.BOOKINGS.VIEW,
        ACL_ACTIONS.BOOKINGS.CREATE,
        ACL_ACTIONS.BOOKINGS.UPDATE,
        ACL_ACTIONS.BOOKINGS.CANCEL
    ]
}

import React from "react";
import PropTypes from "prop-types";
import { Tag, Space, DatePicker, Table, Form, Row, Col, Button, Select } from "antd";
import formatter from "../../helpers/formatter";
import { ReloadOutlined, EyeOutlined, EditOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import * as moment from "moment";
import { BOOKING_STATUS, SETTING_KEYS, SYSTEM_BOOKING_MODES } from "../../constants";
import ColorHelper from "../../helpers/colors";
import { RoomsSelectors } from "../../components/selectors";
import { Acl } from "@src/helpers/acl";
import { ACL_ACTIONS } from "@src/constants/acl";
import { connect } from "react-redux";
import { CommonProps } from "@src/store/helpers";

class BookingTableScreen extends React.PureComponent {
    static propTypes = {
        bookings: PropTypes.array,
        rooms: PropTypes.array,
        users: PropTypes.array,
        onChange: PropTypes.func,
        onCancel: PropTypes.func,
        onEdit: PropTypes.func,
    };

    static defaultProps = {
        onChange: (v) => console.warn("action not register", v),
        onCancel: (v) => console.warn("action not register", v),
        onEdit: (v) => console.warn("action not register", v),
        onDelete: (v) => console.warn("action not register", v),
    };

    columns = [
        {
            dataIndex: ["title"],
            title: "Name",
        },
        {
            dataIndex: ["room", "name"],
            title: "Room/Desk",
            align: "center",
        },
        {
            dataIndex: ["user", "name"],
            title: "Booked by",
            align: "center",
            render: (v, r) => {
                if (!v) {
                    return r.organizer_name;
                }
                return v;
            },
        },
        {
            dataIndex: "date",
            sorter: true,
            title: "Booking Date",
            align: "center",
            render: (v) => formatter.toDisplayDatetime(v),
        },
        {
            dataIndex: "start",
            sorter: true,
            title: "Start",
            align: "center",
            render: (v) => formatter.toDisplayDatetime(v),
        },
        {
            dataIndex: "end",
            sorter: true,
            title: "End",
            align: "center",
            render: (v) => formatter.toDisplayDatetime(v),
        },
        {
            dataIndex: "checkin_date",
            title: "Check In",
            align: "center",
            render: (v) => formatter.toDisplayDatetime(v),
        },
        {
            dataIndex: "cancelled_date",
            title: "Status",
            align: "center",
            render: (v, b) => {
                const color = ColorHelper.getBookingColor(b.status);

                let statusLabel = null;
                if (b.status?.includes(BOOKING_STATUS.CANCELLED)) {
                    statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CANCELLED);
                } else if (b.status?.includes(BOOKING_STATUS.ENDED)) {
                    statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ENDED);
                } else if (b.status?.includes(BOOKING_STATUS.NEW)) {
                    statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.NEW);
                } else if (b.status?.includes(BOOKING_STATUS.ONGOING)) {
                    if (b.status?.includes(BOOKING_STATUS.CHECKED_IN)) {
                        statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CHECKED_IN);
                    } else {
                        statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ONGOING);
                    }
                }

                return <Tag color={color}>{statusLabel}</Tag>;
            },
        },
        {
            dataIndex: "id",
            sorter: true,
            title: "Action",
            align: "right",
            render: (id, booking) => {
                const { auth, app_settings } = this.props;

                const isLocalModeBooking = app_settings?.[SETTING_KEYS.BOOKING.MODE] === SYSTEM_BOOKING_MODES.LOCAL;
                const isOrganizer = auth?.id && `${auth?.id}` === `${booking?.user?.id}`;

                const acl = Acl(auth);

                const allowedCancelBooking =
                    !booking?.status.includes(BOOKING_STATUS.CANCELLED) &&
                    !booking?.status.includes(BOOKING_STATUS.ENDED) &&
                    (acl.isAllowed(ACL_ACTIONS.BOOKINGS.CANCEL) ? isOrganizer || acl.isAdmin() : false);
                const allowedDeleteBooking = acl.isAllowed(ACL_ACTIONS.BOOKINGS.DELETE);
                const allowedUpdateBooking = isLocalModeBooking && (isOrganizer || acl.isAdmin());

                return (
                    <Space size={4}>
                        <Button type="link" icon={allowedUpdateBooking ? <EditOutlined /> : <EyeOutlined />} onClick={() => this.props.onEdit(id)} />
                        <Button type="link" danger icon={<StopOutlined />} disabled={!allowedCancelBooking} onClick={() => this.props.onCancel(id)} />
                        {allowedDeleteBooking && <Button type="link" danger icon={<DeleteOutlined />} onClick={() => this.props.onDelete(id)} />}
                    </Space>
                );
            },
        },
    ];

    state = {
        pagination: { current: 1, pageSize: 50, total: 0 },
        sorter: null,
        rooms_id: null,
        users_id: null,
        range: {
            start: moment().startOf("month").toDate(),
            end: moment().endOf("month").toDate(),
        },
    };

    refresh = () => {
        const { rooms_id, users_id, range, pagination, sorter } = this.state;
        this.props.onChange({ pagination, sorter, range, rooms_id, users_id });
    };

    renderFilter = () => {
        const { rooms, users } = this.props;

        const initialValues = {
            rooms_id: [],
            users_id: [],
            range: this.state.range,
        };

        return (
            <Form
                layout="vertical"
                initialValues={initialValues}
                onValuesChange={(values) => {
                    if (values.hasOwnProperty("rooms_id")) {
                        this.setState({ rooms_id: values.rooms_id }, () => {
                            this.refresh();
                        });
                    } else if (values.hasOwnProperty("range")) {
                        const range = {
                            start: moment(values.range[0]).startOf("day"),
                            end: moment(values.range[1]).endOf("day"),
                        };
                        this.setState({ range }, () => this.refresh());
                    } else if (values.hasOwnProperty("users_id")) {
                        this.setState({ users_id: values.users_id }, () => {
                            this.refresh();
                        });
                    }
                }}>
                <Row gutter={[8, 8]}>
                    <Col>
                        <Form.Item label="Start Date" name="range">
                            <DatePicker.RangePicker
                                ranges={{
                                    Today: [moment().startOf("day"), moment().endOf("day")],
                                    "This Week": [moment().startOf("week"), moment().endOf("week")],
                                    "Next Week": [moment().add(1, "week").startOf("week"), moment().add(1, "week").endOf("week")],
                                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                                    "Next Month": [moment().add(1, "month").startOf("month"), moment().add(1, "month").endOf("month")],
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Rooms/Desks" name="rooms_id">
                            <RoomsSelectors rooms={rooms} mode="multiple" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Booked By" name="users_id">
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                filterOption={(input, option) => {
                                    return option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
                                }}
                                options={users?.map((u) => {
                                    return { value: u.id, label: u.name };
                                })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label=" ">
                            <Button icon={<ReloadOutlined />} onClick={() => this.refresh()} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { bookings } = this.props;
        const { pagination } = this.state;
        return (
            <Space direction="vertical" size={16} style={{ width: "100%" }} className="overflow-x">
                {this.renderFilter()}
                <Table
                    bordered
                    rowKey={"id"}
                    columns={this.columns}
                    dataSource={bookings}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.setState({ pagination, filters, sorter }, () => this.refresh());
                    }}
                />
            </Space>
        );
    }
}

export default connect(CommonProps, null)(BookingTableScreen);

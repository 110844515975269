class RoleService {

    isSystemAdmin() {
        return false;
    }

    isAllow(action) {
        return true;
    }
}

export default new RoleService();

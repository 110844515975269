import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Space, Card, Tooltip, Upload, Select, TimePicker, Tag, Modal, Descriptions, Typography } from "antd";
import { MAILER_TYPES, SETTING_KEYS, SYSTEM_BOOKING_MODES } from "../../constants";
import PropTypes from "prop-types";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import fileHelper from "../../helpers/file";
import formatter from "../../helpers/formatter";
import { formLayoutColInput } from "./config";
import { Acl } from "../../helpers/acl";
import { ACL_ACTIONS } from "../../constants/acl";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { ui, api } from "../../services";

export const USING_KEYS = [
    SETTING_KEYS.BOOKING.MODE,
    SETTING_KEYS.BOOKING.TIME_STEP_MINUTES,
    ...Object.values(SETTING_KEYS.BOOKING.OPERATION_TIME),
    ...Object.values(SETTING_KEYS.BOOKING.CALENDER.MICROSOFT),
    ...Object.values(SETTING_KEYS.BOOKING.CALENDER.GOOGLE),

    SETTING_KEYS.BOOKING.AUTO_RELEASE_GRACE_MINUTES,
];

class BookingForm2 extends React.PureComponent {
    static propTypes = {
        settings: PropTypes.object,
        onSubmit: PropTypes.func,
        onUploadFile: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: (v) => console.warn("action not register", v),
        onUploadFile: (v) => console.warn("action not register", v),
    };

    formRef = React.createRef();

    state = {
        selected_type: MAILER_TYPES.GENERIC,
        showTest: false,
    };

    constructor(props) {
        super(props);
    }

    onFinish = (form) => {
        const [from, to] = form["operation_time"] !== undefined && form["operation_time"] !== null ? form.operation_time : [null, null];
        form[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM] = from !== null ? from.toISOString() : from;
        form[SETTING_KEYS.BOOKING.OPERATION_TIME.TO] = to !== null ? to.toISOString() : to;
        delete form.operation_time;
        this.props.onSubmit(form);
    };

    render() {
        const formItemLayout = {
            ...formLayoutColInput,
            onValuesChange: (values) => {
                if (values.hasOwnProperty(SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE)) {
                    this.setState({ ...this.state, ...values });
                }
            },
        };

        const { settings, auth } = this.props;

        const allowEditBookingMode = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_MODE);
        const allowEditBookingIntegrationCalender = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_INTEGRATION_CALENDER);
        const allowEditBookingAutoReleaseGracePeriod = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_AUTO_RELEASE_GRACE_PERIOD);

        const initialValues = {};

        USING_KEYS.forEach((k) => {
            initialValues[k] = settings?.[k];
        });

        const integrationFormItem = (title, formParams) => {
            const { client_id_key, client_secret_key, tenant_id_key, credential_file_key, redirect_uri_key, authority_key } = formParams;
            const isUseConfigureFile = formParams.hasOwnProperty("credential_file_key");
            const isAuthConfigured = !!this.state[credential_file_key];

            return (
                <Card title={title} bordered={false} size="small">
                    {/*{verification_code_key && <Form.Item label="Site Verification Code" name={verification_code_key}>
                    <Input/>
                </Form.Item>}*/}

                    {isUseConfigureFile && (
                        <Form.Item
                            name={credential_file_key}
                            label={
                                <span>
                                    Authentication File&nbsp;
                                    <Tooltip title="You can download the credential JSON from your credential portal.">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            valuePropName="file">
                            <Space direction="horizontal">
                                <Upload
                                    listType="text"
                                    fileList={[]}
                                    beforeUpload={(file, list) => {
                                        fileHelper.fileToBase64(file).then((base64) => {
                                            this.formRef.current.setFieldsValue({ [credential_file_key]: base64 });
                                            this.setState({ [credential_file_key]: base64 });
                                        });
                                        return false;
                                    }}>
                                    <Button icon={<UploadOutlined />}>
                                        Click to upload
                                        {isAuthConfigured ? (
                                            <CheckCircleOutlined style={{ color: "green" }} />
                                        ) : (
                                            <CloseCircleOutlined style={{ color: "red" }} />
                                        )}
                                    </Button>
                                </Upload>

                                {isAuthConfigured && (
                                    <Button
                                        danger
                                        htmlType="button"
                                        onClick={() => {
                                            this.formRef.current.setFieldsValue({ [credential_file_key]: null });
                                            this.setState({ [credential_file_key]: null });
                                        }}
                                        icon={<DeleteOutlined />}
                                    />
                                )}
                            </Space>
                        </Form.Item>
                    )}

                    {!isUseConfigureFile && (
                        <>
                            <Form.Item label="Client ID" name={client_id_key}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Client Secret" name={client_secret_key}>
                                <Input.Password />
                            </Form.Item>
                            {authority_key && (
                                <Form.Item label="Authority" name={authority_key}>
                                    <Input />
                                </Form.Item>
                            )}
                            <Form.Item label="Tenant ID" name={tenant_id_key}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Redirect URI" name={redirect_uri_key}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                </Card>
            );
        };

        return (
            <Form {...formItemLayout} initialValues={initialValues} onFinish={this.onFinish} ref={this.formRef}>
                <Space style={{ width: "100%" }} direction="vertical" size={16}>
                    <Card title="General" size="small">
                        {allowEditBookingMode && (
                            <Form.Item label="Mode" name={SETTING_KEYS.BOOKING.MODE}>
                                <Select>
                                    {[SYSTEM_BOOKING_MODES.LOCAL, SYSTEM_BOOKING_MODES.GOOGLE, SYSTEM_BOOKING_MODES.MICROSOFT].map((v) => (
                                        <Select.Option key={v} value={v}>
                                            {formatter.toDisplayBookingMode(v)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item label="Time span" name={SETTING_KEYS.BOOKING.TIME_STEP_MINUTES}>
                            <Select>
                                {[5, 10, 15].map((t) => (
                                    <Select.Option key={t} value={t}>{`${t} minutes`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {allowEditBookingAutoReleaseGracePeriod && (
                            <Form.Item label="Auto Release (no check in)" name={SETTING_KEYS.BOOKING.AUTO_RELEASE_GRACE_MINUTES}>
                                <Select>
                                    {[0, 5, 10, 15, 20, 25, 30].map((t) => (
                                        <Select.Option key={t} value={t}>
                                            {t === 0 ? `Disable` : `${t} minutes`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item label="Operations Hours" name="operation_time">
                            <TimePicker.RangePicker style={{ width: "100%" }} showNow={false} format="h:mm a" />
                        </Form.Item>
                    </Card>

                    {allowEditBookingIntegrationCalender && (
                        <Card title="Calender" size="small">
                            {integrationFormItem("Google", {
                                // credential_file_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE,
                                verification_code_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.SITE_VERIFICATION_CODE,
                                client_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_ID,
                                client_secret_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_SECRET,
                                tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.PROJECT_ID,
                            })}

                            {integrationFormItem("Microsoft", {
                                client_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID,
                                client_secret_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET,
                                tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID,
                                redirect_uri_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI,
                                authority_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY,
                            })}
                        </Card>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        );
    }
}

//export default connect(CommonProps, null)(BookingForm);

const IntegrationFormItem = ({ title, type, onTest, formParams }) => {
    const { client_id_key, client_secret_key, tenant_id_key, redirect_uri_key, authority_key } = formParams;
    return (
        <Card title={title} bordered={false} size="small">
            <>
                <Form.Item label="Client ID" name={client_id_key}>
                    <Input />
                </Form.Item>
                <Form.Item label="Client Secret" name={client_secret_key}>
                    <Input.Password />
                </Form.Item>
                {authority_key && (
                    <Form.Item label="Authority" name={authority_key}>
                        <Input />
                    </Form.Item>
                )}
                <Form.Item label="Tenant ID" name={tenant_id_key}>
                    <Input />
                </Form.Item>
                <Form.Item label="Redirect URI" name={redirect_uri_key}>
                    <Input />
                </Form.Item>

                {type === "microsoft" && (
                    <Form.Item label=" ">
                        <Button onClick={onTest}>Test Microsfot App</Button>
                    </Form.Item>
                )}
            </>
        </Card>
    );
};

export const BookingForm = (props) => {
    const { onSubmit, onUploadFile } = props ?? {};

    const formRef = useRef();

    const [ready, setReady] = useState(false);
    const [formValues, setFormValues] = useState({
        selected_type: MAILER_TYPES.GENERIC,
        showTest: false,
    });

    const [testResult, setTestResult] = useState({ ms365: null });

    const handlers = {
        doTestMs365app: () => {
            ui.confirm(`Are you sure to test the Microsoft App connection with current settings?`, async () => {
                try {
                    const config = {};
                    [
                        SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID,
                        SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET,
                        SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID,
                        SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI,
                        SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY,
                    ].forEach((k) => {
                        config[k] = formValues[k];
                    });

                    const rs = await api.setting.testMs365App(config);
                    setTestResult({ ...testResult, ms365: rs });
                } catch (err) {
                    ui.notify.error(err);
                }
            });
        },
    };

    const { auth } = useSelector(({ backend }) => {
        return {
            auth: backend.auth,
            app_settings: backend.settings,
        };
    });

    const allowEditBookingMode = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_MODE);
    const allowEditBookingIntegrationCalender = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_INTEGRATION_CALENDER);
    const allowEditBookingAutoReleaseGracePeriod = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_AUTO_RELEASE_GRACE_PERIOD);

    const formProps = {
        ...formLayoutColInput,
        ref: formRef,
        initialValues: formValues,
        onValuesChange: (v, values) => setFormValues(values),
        onFinish: (formValues) => {
            const values = { ...formValues };
            const [from, to] = values["operation_time"] !== undefined && values["operation_time"] !== null ? values.operation_time : [null, null];
            values[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM] = from !== null ? from.toISOString() : from;
            values[SETTING_KEYS.BOOKING.OPERATION_TIME.TO] = to !== null ? to.toISOString() : to;
            delete values.operation_time;
            if (onSubmit) {
                onSubmit(values);
            }
        },
        onValuesChange: (values) => {
            if (values.hasOwnProperty(SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE)) {
                this.setState({ ...this.state, ...values });
            }
        },
    };

    useEffect(() => {
        if (props.settings) {
            const values = { ...props.setting };
            if (props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.MODE)) {
                values.selected_type = props.settings[SETTING_KEYS.BOOKING.MODE];
            }

            if (props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE)) {
                values[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE] = props.settings[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE];
            }

            if (
                props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.OPERATION_TIME.FROM) &&
                props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.OPERATION_TIME.TO)
            ) {
                const from =
                    props.settings[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM] !== null ? moment(props.settings[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM]) : null;
                const to =
                    props.settings[SETTING_KEYS.BOOKING.OPERATION_TIME.TO] !== null ? moment(props.settings[SETTING_KEYS.BOOKING.OPERATION_TIME.TO]) : null;

                props.settings["operation_time"] = [from, to];
                delete values[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM];
                delete values[SETTING_KEYS.BOOKING.OPERATION_TIME.TO];
                USING_KEYS.push("operation_time");
            }

            USING_KEYS.forEach((k) => {
                values[k] = props.settings?.[k];
            });

            setFormValues(values);
            setReady(true);
        }
    }, [props.settings]);

    return (
        <>
            {ready && (
                <Form {...formProps}>
                    <Space style={{ width: "100%" }} direction="vertical" size={16}>
                        <Card title="General" size="small">
                            {allowEditBookingMode && (
                                <Form.Item label="Mode" name={SETTING_KEYS.BOOKING.MODE}>
                                    <Select>
                                        {[SYSTEM_BOOKING_MODES.LOCAL, SYSTEM_BOOKING_MODES.GOOGLE, SYSTEM_BOOKING_MODES.MICROSOFT].map((v) => (
                                            <Select.Option key={v} value={v}>
                                                {formatter.toDisplayBookingMode(v)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item label="Time span" name={SETTING_KEYS.BOOKING.TIME_STEP_MINUTES}>
                                <Select>
                                    {[5, 10, 15].map((t) => (
                                        <Select.Option key={t} value={t}>{`${t} minutes`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {allowEditBookingAutoReleaseGracePeriod && (
                                <Form.Item label="Auto Release (no check in)" name={SETTING_KEYS.BOOKING.AUTO_RELEASE_GRACE_MINUTES}>
                                    <Select>
                                        {[0, 5, 10, 15, 20, 25, 30].map((t) => (
                                            <Select.Option key={t} value={t}>
                                                {t === 0 ? `Disable` : `${t} minutes`}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item label="Operations Hours" name="operation_time">
                                <TimePicker.RangePicker style={{ width: "100%" }} showNow={false} format="h:mm a" />
                            </Form.Item>
                        </Card>

                        {allowEditBookingIntegrationCalender && (
                            <Card title="Calender" size="small">
                                <IntegrationFormItem
                                    title="Microsoft 365"
                                    type="microsoft"
                                    onTest={handlers.doTestMs365app}
                                    formParams={{
                                        client_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID,
                                        client_secret_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET,
                                        tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID,
                                        redirect_uri_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI,
                                        // authority_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY,
                                    }}
                                />

                                <IntegrationFormItem
                                    type="google"
                                    title="Google"
                                    formParams={{
                                        verification_code_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.SITE_VERIFICATION_CODE,
                                        client_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_ID,
                                        client_secret_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_SECRET,
                                        tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.PROJECT_ID,
                                    }}
                                />
                            </Card>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Space>
                </Form>
            )}

            <Modal
                title="Miscrosoft APP Test Result"
                visible={!!testResult?.ms365}
                closable
                footer={null}
                onCancel={() => setTestResult({ ...testResult, ms365: null })}>
                <Descriptions column={1} bordered>
                    <Descriptions.Item label="Status">{testResult?.ms365?.success ? "Success" : "Failed"}</Descriptions.Item>
                    {!testResult?.ms365?.success && <Descriptions.Item label="Error">{testResult?.ms365?.error}</Descriptions.Item>}
                    {testResult?.ms365?.success && (
                        <Descriptions.Item label="Users">
                            <Space direction="vertical" size={2}>
                                {testResult?.ms365?.users?.map((u) => {
                                    const email = u.mail ? u.mail : u.userPrincipalName;
                                    const name = u.displayName ? u.displayName : u.id;
                                    return (
                                        <Tag key={u.id}>
                                            {name} ({email})
                                        </Tag>
                                    );
                                })}
                            </Space>
                            <br />
                            <small>
                                <i>showing top 10 users only</i>
                            </small>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </Modal>
        </>
    );
};

export default BookingForm;

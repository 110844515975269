import React from "react";

import { connect } from "react-redux";
import { Form, Input, Typography } from "antd";
import PageContent from "../../components/page-content";
import { CommonProps, CommonDispatcher } from "../../store/helpers";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { settingService } from "@src/services";

class Container extends React.Component {
    static propTypes = {
        types: PropTypes.array,
    };

    static defaultProps = {};

    state = {
        api_host: `${window.location.protocol}//${window.location.host}/api/v2`,
        api_secret: "",
    };

    renderForm = () => {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
            labelAlign: "left",
            colon: false,
        };

        const { api_host, api_secret } = this.state;

        return (
            <Form {...formItemLayout}>
                <Form.Item label="API Host">
                    <Input value={api_host} onChange={(e) => this.setState({ api_host: e.target.value })} />
                </Form.Item>
                <Form.Item label="API Secret">
                    <Input.Password value={api_secret} readOnly />
                </Form.Item>
                <Form.Item label=" " help="Scan QRCode to get the configuration">
                    <QRCode value={`api_host=${api_host}&api_secret=${api_secret}`} />
                </Form.Item>
            </Form>
        );
    };

    render() {
        return <PageContent title="Integrations">{this.renderForm()}</PageContent>;
    }

    componentDidMount() {
        settingService
            .deviceConfig()
            .then((rs) => {
                this.setState({ api_secret: rs?.secret });
            })
            .catch((err) => {
                this.props.notificationError(err);
            });
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

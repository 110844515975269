import React from "react";
import PropTypes from "prop-types";
import { Row, Button, Form, Input, Select, DatePicker, TimePicker, Col, Descriptions, Typography, Tag, Radio } from "antd";
import { connect } from "react-redux";
import { CommonProps, CommonDispatcher } from "../../store/helpers";
import { StopOutlined, MinusCircleOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../assets/react-big-calender.css";
import * as moment from "moment";
import { BOOKING_STATUS, ROOM_TYPES, SETTING_KEYS } from "../../constants";
import formatter from "../../helpers/formatter";
import { ACL_ACTIONS } from "../../constants/acl";
import { Acl } from "../../helpers/acl";
import ColorHelper from "../../helpers/colors";

class BookingFormScreen extends React.Component {
    static propTypes = {
        booking: PropTypes.object,
        onSubmit: PropTypes.func,
        onDelete: PropTypes.func,
        onCancel: PropTypes.func,
        getRoomFacilities: PropTypes.func,
        users: PropTypes.array,
        rooms: PropTypes.array,
    };

    static defaultProps = {
        users: [],
        rooms: [],
        onSubmit: (v) => console.warn("action not register", v),
        onDelete: (v) => console.warn("action not register", v),
        onCancel: (v) => console.warn("action not register", v),
        getRoomFacilities: (v) => console.warn("action not register", v),
    };

    state = {
        selected_room: null,
        selected_room_type: ROOM_TYPES.ROOM,
    };

    formRef = React.createRef();

    constructor(props) {
        super(props);
        if (props.hasOwnProperty("booking")) {
            if (props.booking?.room?.type) {
                this.state.selected_room_type = props.booking?.room?.type;
            }

            if (props.booking?.room?.id) {
                this.state.selected_room = props.rooms?.find((r) => r.id === props.booking?.room?.id);
            }
        }
    }

    doLoadRoomFacilities = (id) => {
        if (id) {
            const callback = this.props.getRoomFacilities(id);
            if (callback) {
                callback.then((rs) => {
                    this.setState({ select_room_facilities: rs.items });
                });
            }
        }
    };

    componentDidMount() {
        const { selected_room } = this.state;
        this.doLoadRoomFacilities(selected_room?.id);
    }

    handleSubmit = (form) => {
        const { date, period } = form;

        const start = moment(date).hour(moment(period[0]).hour()).minute(moment(period[0]).minute()).add(1, "second").toISOString();
        const end = moment(date).hour(moment(period[1]).hour()).minute(moment(period[1]).minute()).toISOString();

        delete form.date;
        delete form.period;

        if (form.notify_emails) {
            form.notify_emails = form.notify_emails.filter((value, index, self) => self.indexOf(value) === index);
        }

        this.props.onSubmit({ ...form, start, end });
    };

    renderInfo(isOrganizer = false) {}

    render() {
        const { booking, rooms, users, app_settings, auth } = this.props;
        const { selected_room, select_room_facilities, selected_room_type } = this.state;

        const formItemLayout = {
            layout: "vertical",
            initialValues: {
                type: booking?.type ?? ROOM_TYPES.ROOM,
                title: booking?.title,
                room: booking?.room?.id,
                date: moment(booking?.start).startOf("day"),
                notify_emails: booking?.notify_emails,
                period: booking ? [moment(booking?.start), moment(booking?.end)] : [],
                user: booking?.user?.id,
                remarks: booking?.remarks,
                invited_users: booking?.invited_users,
            },
            onValuesChange: (values) => {
                if (values.hasOwnProperty("type")) {
                    this.setState({ selected_room_type: values.type });
                }
                if (values.hasOwnProperty("room")) {
                    const r = rooms.find((r) => r.id === values.room);
                    this.setState({ selected_room: r });
                    this.doLoadRoomFacilities(r?.id);
                }
            },
        };

        const acl = Acl(auth);

        const isOrganizer = auth?.id && `${auth?.id}` === `${booking?.user?.id}`;

        const allowedCancelBooking =
            !booking?.status.includes(BOOKING_STATUS.CANCELLED) &&
            !booking?.status.includes(BOOKING_STATUS.ENDED) &&
            (acl.isAllowed(ACL_ACTIONS.BOOKINGS.CANCEL) ? isOrganizer || acl.isAdmin() : false);
        const allowedDeleteBooking = acl.isAllowed(ACL_ACTIONS.BOOKINGS.DELETE);
        const allowedUpdateBooking = isOrganizer || acl.isAdmin();

        const step = app_settings?.[SETTING_KEYS.BOOKING.TIME_STEP_MINUTES];
        /* const operation_time = {
            from: app_settings?.[SETTING_KEYS.BOOKING.OPERATION_TIME.FROM],
            to: app_settings?.[SETTING_KEYS.BOOKING.OPERATION_TIME.TO]
        } */

        const isAllowedBookOnBehalf =
            app_settings?.[SETTING_KEYS.BOOKING.IS_ALLOWED_BOOK_ON_BEHALF] && Acl(auth).isAllowed(ACL_ACTIONS.BOOKINGS.BOOK_ON_BEHALF);

        const isUpdate = !!booking?.id;

        const displayInfoOnly = isUpdate && (!booking?.status?.includes(BOOKING_STATUS.NEW) || (!acl.isAdmin() && !isOrganizer));

        return (
            <Form {...formItemLayout} onFinish={this.handleSubmit} ref={this.formRef}>
                <Form.Item
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "Please select the room",
                        },
                    ]}>
                    <Radio.Group
                        options={[
                            { label: "Room", value: ROOM_TYPES.ROOM },
                            { label: "Desk", value: ROOM_TYPES.DESK },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Title is require",
                        },
                    ]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label={formatter.toDisplayRoomType(selected_room_type)}
                    name="room"
                    rules={[
                        {
                            required: true,
                            message: `Please select the ${formatter.toDisplayRoomType(selected_room_type)}`,
                        },
                    ]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        options={rooms
                            ?.filter((r) => r.type === selected_room_type)
                            .map((r) => {
                                return {
                                    value: r.id,
                                    label: r.name,
                                };
                            })}
                    />
                </Form.Item>

                {selected_room && (
                    <Form.Item>
                        <Descriptions bordered size="small" column={1} labelStyle={{ width: "40%" }}>
                            <Descriptions.Item label="Name">{selected_room?.name}</Descriptions.Item>
                            <Descriptions.Item label="Capacity">{selected_room?.capacity}</Descriptions.Item>
                            <Descriptions.Item label="Facilities">
                                {select_room_facilities &&
                                    select_room_facilities.map((f) => {
                                        const { id, facility, is_malfunction } = f;
                                        return (
                                            <li key={id}>
                                                <Typography.Text delete={is_malfunction}>{facility.name}</Typography.Text>
                                            </li>
                                        );
                                    })}
                            </Descriptions.Item>
                        </Descriptions>
                    </Form.Item>
                )}

                {isAllowedBookOnBehalf && (
                    <Form.Item label="Booked by" name="user">
                        <Select allowClear={true} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {users &&
                                users.map((u) => (
                                    <Select.Option value={u.id} key={u.id}>
                                        {u.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                )}

                {isUpdate && !isAllowedBookOnBehalf && (
                    <Form.Item>
                        <Descriptions bordered size="small" column={1} labelStyle={{ width: "40%" }}>
                            <Descriptions.Item label="Booked by">{booking?.user?.name}</Descriptions.Item>
                        </Descriptions>
                    </Form.Item>
                )}

                <Form.Item
                    label="Remarks"
                    name="remarks"
                    rules={[
                        {
                            max: 300,
                            message: `Maximum 300 characters only`,
                        },
                    ]}>
                    <Input.TextArea rows={5} />
                </Form.Item>

                <Form.List name="notify_emails">
                    {(fields, { add, remove }) => (
                        <>
                            Notify Emails
                            {fields.map((field) => (
                                <Row align="middle" gutter={[8, 8]} key={field.key}>
                                    <Col span={22}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name]}
                                            fieldKey={[field.fieldKey]}
                                            style={{ marginBottom: 0 }}
                                            rules={[{ type: "email", message: "Please insert a valid email format" }]}>
                                            <Input type="email" autocomplete="chrome-off" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Email
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item label="Invited Users" name="invited_users">
                    <Select
                        mode="multiple"
                        allowClear={true}
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {users &&
                            users.map((u) => (
                                <Select.Option value={u.id} key={u.id}>
                                    {u.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the booking date",
                                },
                            ]}>
                            <DatePicker
                                style={{ width: "100%" }}
                                disabledDate={(current) => {
                                    return current.isBefore(moment().subtract(1, "day").endOf("day"));
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Time"
                            name="period"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select the booking time",
                                } /* , {
                            validator: async (rule, value) => {
                                moment(value[0]).year()
                                if (moment(value[0]) < moment(operation_time.from) || moment(value[1]) > moment(operation_time.to)) {
                                    throw new Error(`Please select the booking time within the operation time from ${moment(operation_time.from).format('h:mm a')} to ${moment(operation_time.to).format('h:mm a')}.`);
                                }
                            },
                        } */,
                            ]}>
                            <TimePicker.RangePicker
                                style={{ width: "100%" }}
                                showNow={false}
                                format="h:mm a"
                                minuteStep={step ? step : 15}
                                disabledHours={() => {
                                    if (this.formRef.current) {
                                        const selectedDate = this.formRef.current.getFieldValue("date");
                                        if (moment().startOf("day").isSame(moment(selectedDate).startOf("day"))) {
                                            const nowHour = moment().hour();
                                            const hours = [];
                                            for (let hour = 0; hour < nowHour; hour++) {
                                                hours.push(hour);
                                            }
                                            return hours;
                                        }
                                    }
                                    return [];
                                }}
                                disabledMinutes={(hour) => {
                                    if (this.formRef.current) {
                                        const selectedDate = this.formRef.current.getFieldValue("date");
                                        const isToday = moment().startOf("day").isSame(moment(selectedDate).startOf("day"));
                                        if (isToday) {
                                            const nowMinute = moment().minutes();
                                            const minutes = [];
                                            if (hour <= moment().hour()) {
                                                for (let minute = 0; minute <= nowMinute; minute++) {
                                                    minutes.push(minute);
                                                }
                                            }
                                            return minutes;
                                        }
                                    }
                                    return [];
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item style={{ textAlign: "right" }}>
                    {allowedDeleteBooking && isUpdate && (
                        <>
                            <Button danger icon={<DeleteOutlined />} htmlType="button" type="default" onClick={() => this.props.onDelete()}>
                                Delete
                            </Button>
                            &nbsp;
                        </>
                    )}

                    {isUpdate && (
                        <>
                            <Button
                                danger
                                icon={<StopOutlined />}
                                htmlType="button"
                                type="default"
                                disabled={!allowedCancelBooking}
                                onClick={() => this.props.onCancel()}>
                                Cancel
                            </Button>
                            &nbsp;
                        </>
                    )}
                    <Button htmlType="submit" type="primary" disabled={isUpdate && !allowedUpdateBooking}>
                        {isUpdate ? "Update" : "Submit"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default connect(CommonProps, CommonDispatcher)(BookingFormScreen);

import React from "react";
import {connect} from "react-redux";

import {userService} from '../../services';
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import PageContent from "../../components/page-content";
import history from "../../routes/history";
import {Input, Select, Form, Button, Modal, Switch} from "antd";
import {USER_TYPES} from "../../constants";
import formatter from "../../helpers/formatter";
import {LockOutlined} from '@ant-design/icons';

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        user: null,
        init_done: false,
        showChangePassword: false
    };

    passwordFormRef = React.createRef();

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = () => {
        if (this.id) {
            this.setState({loading: true});
            userService.get(this.id).then(rs => {
                this.setState({user: rs, loading: false, init_done: true})
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false, init_done: true})
            });
        } else {
            this.setState({init_done: true})
        }
    };

    doChangePassword = (form) => {
        this.props.confirm('Are you sure to update the user password?', () => {
            this.setState({loading: true, showChangePassword: false});
            userService.update(this.state.user.id, {password: form.new_password}).then(() => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    }

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? userService.create(values) : userService.update(this.id, values)).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    history.goBack();
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    renderForm() {
        const {user, showChangePassword} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'left',
            colon: false,
            initialValues: {
                type: user?.type,
                username: user?.username,
                name: user?.name,
                email: user?.email,
                pin: user?.pin,
                is_first_time_login: user?.is_first_time_login
            }
        };

        const userTypes = Object.values(USER_TYPES).filter(v => v !== USER_TYPES.SUPER_ADMIN)

        return <>
            <Form {...formItemLayout} ref={this.formRef} onFinish={this.submit}>
                {this.isCreate && <>
                    <Form.Item label="Username" name="username" rules={[{
                        required: true,
                        message: 'This field is require',
                    }]}>
                        <Input/>
                    </Form.Item>

                    {/*<Form.Item label="Password" name="password" rules={[{
                        required: true,
                        message: 'This field is require',
                    }]}>
                        <Input.Password/>
                    </Form.Item>*/}
                </>}

                {!this.isCreate && <>
                    <Form.Item label="Username">
                        <Input.Group compact>
                            <Input style={{width: '95%'}} disabled value={user?.username}/>
                            <Button style={{width: '5%'}} icon={<LockOutlined/>}
                                    onClick={() => this.setState({showChangePassword: !showChangePassword})}/>
                        </Input.Group>
                    </Form.Item>
                </>}

                {/*<Form.Item label="Force Update Password" name="is_first_time_login" valuePropName="checked">
                    <Switch/>
                </Form.Item>*/}

                <Form.Item label="Type" name="type" rules={[{
                    required: true,
                    message: 'Please select the user type',
                }]}>
                    <Select>
                        {userTypes.map(v => <Select.Option key={v} value={v}>
                            {formatter.toDisplayUserType(v)}
                        </Select.Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label="Name" name="name" rules={[{
                    required: true,
                    message: 'This field is require',
                }]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Email" name="email" rules={[{
                    required: true,
                    message: 'E-mail is required'
                }, {
                    type: "email",
                    message: 'Invalid email format',
                }]}>
                    <Input type="email"/>
                </Form.Item>

                <Form.Item label="Pin" name="pin">
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {this.isCreate ? 'Submit' : 'Update'}
                    </Button>
                </Form.Item>
            </Form>

            <Modal title="Change Password" visible={showChangePassword} onOk={() => {
                if (this.passwordFormRef.current) {
                    this.passwordFormRef.current.submit();
                }
            }} onCancel={() => this.setState({showChangePassword: false})}>
                <Form ref={this.passwordFormRef} layout="vertical" onFinish={values => {
                    this.doChangePassword(values);
                    this.setState({modalVisible: false});
                }}>
                    <Form.Item name={'new_password'} rules={[
                        {required: true, message: 'Please insert your current password'},
                    ]}>
                        <Input.Password placeholder="New Password"/>
                    </Form.Item>
                    <Form.Item name={'retype_password'} rules={[
                        {required: true, message: 'Please retype your new password'},
                        {
                            validator: async (rule, value) => {
                                if (value !== this.passwordFormRef.current.getFieldValue('new_password')) {
                                    throw new Error('must be same with new password!');
                                }
                            },
                        },
                    ]}>
                        <Input.Password placeholder="Retype new Password"/>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }


    render() {
        const {init_done, loading} = this.state;

        const title = this.isCreate ? 'Add User' : 'Edit User';

        return <PageContent title={title} hasBack={true} loading={loading}>
            {init_done && this.renderForm()}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);

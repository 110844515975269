import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch, Tooltip, Typography } from "antd";

import formatter from "../../helpers/formatter";
import { connect } from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import {
    CalendarOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExportOutlined,
    GoogleOutlined,
    SwapOutlined,
    WindowsOutlined,
} from "@ant-design/icons";
import { ROOM_CALENDER_API_TYPES, SETTING_KEYS, SYSTEM_BOOKING_MODES } from "../../constants";
import { api, ui } from "@src/services";
import debounce from "lodash.debounce";
import { CommonProps } from "@src/store/helpers";

class RoomFormScreen extends React.Component {
    static propTypes = {
        room: PropTypes.object,
        isCreate: PropTypes.bool,
        onSubmit: PropTypes.func,
        onAuthorizeApi: PropTypes.func,
        onTestApi: PropTypes.func,
        onRevokeApi: PropTypes.func,
        onRefreshApiToken: PropTypes.func,
        doingTest: PropTypes.bool,
        doShowGoogleAuthorize: PropTypes.func,
    };

    static defaultProps = {
        isCreate: true,
        onSubmit: (v) => console.warn("action not register", v),
        onAuthorizeApi: (v) => console.warn("action not register", v),
        onTestApi: (v) => console.warn("action not register", v),
        onRevokeApi: (v) => console.warn("action not register", v),
        onRefreshApiToken: () => console.warn("action not register"),
    };

    state = {
        selected_calender_api_type: null,
        calender_auth_url: null,
        has_token: false,

        ms365_users: [],
    };

    formRef = React.createRef();

    constructor(props) {
        super(props);
        if (props.room) {
            this.state.selected_calender_api_type = props.room?.calender_api_type;
            this.state.has_token = props.room?.calender_api_params?.code;
            this.state.calender_auth_url = this.getCalenderAuthUrl(props.room?.calender_api_type);
        }
    }

    handleSubmit = (form) => {
        this.props.onSubmit(form);
    };

    onAuthorize = () => {
        const type = this.formRef.current.getFieldValue("calender_api_type");
        const params = this.formRef.current.getFieldValue("calender_api_params");
        if (type) {
            this.props.onAuthorizeApi(type, params);
        }
    };

    loadMs365Users = debounce((email) => {
        console.log("calling search", email);
        const params = this.formRef.current.getFieldValue("calender_api_params");
        api.setting.ms365
            .users({email, userId: params?.userId, limit: 100})
            .then((rs) => {
                this.setState({ms365_users: rs});
            })
            .catch((err) => ui.notify.error(err));
    }, 500);

    componentDidMount() {
        if (this.state.selected_calender_api_type === ROOM_CALENDER_API_TYPES.MS365_EXCHANGE) {
            this.loadMs365Users();
        }
    }

    componentDidUpdate(prevProps, preStats, snapshoot) {
        if (preStats.selected_calender_api_type !== this.state.selected_calender_api_type) {
            if (!this.state.ms365_users?.length && this.state.selected_calender_api_type === ROOM_CALENDER_API_TYPES.MS365_EXCHANGE) {
                this.loadMs365Users();
            }
        }
    }

    getCalenderAuthUrl = (type) => {
        switch (type) {
            case ROOM_CALENDER_API_TYPES.GOOGLE:
                return "/google-oauth";
            case ROOM_CALENDER_API_TYPES.MICROSOFT:
                return "/microsoft-oauth";
            default:
                return null;
        }
    };

    render() {
        const {room, isCreate, doingTest, authorizing, onTestApi, onRevokeApi, app_settings} = this.props;
        const {selected_calender_api_type, calender_auth_url} = this.state;

        const bookingMode = app_settings?.[SETTING_KEYS.BOOKING.MODE];

        const initialValues = {
            name: room?.name,
            description: room?.description,
            capacity: room?.capacity,
            calender_api_type: room?.calender_api_type,
            is_sync_calender_api: !!room?.is_sync_calender_api,
            calender_api_params: room?.calender_api_params,
        };

        if (initialValues.calender_api_params?.code) {
            delete initialValues.calender_api_params.code;
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: "left",
            colon: false,
            onValuesChange: async (v) => {
                if (v.hasOwnProperty("calender_api_params")) {
                    const calender_api_params = v.calender_api_params;
                    if (calender_api_params.hasOwnProperty("token")) {
                        this.setState({has_token: !!calender_api_params.token});
                    }
                }

                if (v.hasOwnProperty("calender_api_type")) {
                    let calender_auth_url = this.getCalenderAuthUrl(v.calender_api_type);

                    if (!v.calender_api_type) {
                        this.formRef.current.setFieldsValue({is_sync_calender_api: false});
                    }
                    this.setState({selected_calender_api_type: v.calender_api_type, calender_auth_url});
                }
            },
        };

        const innerFormItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
        };

        const isApiTokenGranted = room && room.is_api_granted;

        const roomCalenderApiIcon = (v) => {
            switch (v) {
                case ROOM_CALENDER_API_TYPES.GOOGLE:
                    return <GoogleOutlined/>;
                case ROOM_CALENDER_API_TYPES.MICROSOFT:
                    return <WindowsOutlined/>;
                case ROOM_CALENDER_API_TYPES.MS365_EXCHANGE:
                    return <WindowsOutlined/>;
                default:
                    return <CalendarOutlined/>;
            }
        };

        const apiLink = () => {
            switch (selected_calender_api_type) {
                case ROOM_CALENDER_API_TYPES.MICROSOFT:
                    return (
                        <Typography.Link href={calender_auth_url} target="_blank">
                            Click here to start the calender authorization&nbsp;
                            <ExportOutlined/>
                        </Typography.Link>
                    );
                case ROOM_CALENDER_API_TYPES.GOOGLE:
                    return (
                        <Typography.Link onClick={this.props.doShowGoogleAuthorize} target="_blank">
                            Click here to start the calender authorization&nbsp;
                            <ExportOutlined/>
                        </Typography.Link>
                    );
                default:
                    return null;
            }
        };

        const directEmailType = [ROOM_CALENDER_API_TYPES.MICROSOFT, ROOM_CALENDER_API_TYPES.GOOGLE].includes(selected_calender_api_type);
        const useAppType = [ROOM_CALENDER_API_TYPES.MS365_EXCHANGE].includes(selected_calender_api_type);

        const hideBookingMode = !Object.values(ROOM_CALENDER_API_TYPES).includes(selected_calender_api_type)
            && bookingMode === SYSTEM_BOOKING_MODES.LOCAL;

        return (
            <Form {...formItemLayout} initialValues={initialValues} onFinish={this.handleSubmit} ref={this.formRef}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Name is require",
                        },
                    ]}>
                    <Input maxLength={64}/>
                </Form.Item>
                {/*<Form.Item label="Description" name="description">
                    <Input/>
                </Form.Item>*/}

                <Form.Item label="Capacity" name="capacity">
                    <InputNumber max={99999}/>
                </Form.Item>

                {!isCreate && (
                    <>
                        <Form.Item label="Calender Type" name="calender_api_type" hidden={hideBookingMode}>
                            <Select allowClear={true}>
                                {[
                                    ROOM_CALENDER_API_TYPES.MS365_EXCHANGE,
                                    ROOM_CALENDER_API_TYPES.GOOGLE,
                                ].map((value) => (
                                    <Select.Option key={value} value={value}>
                                        {roomCalenderApiIcon(value)}&nbsp;
                                        {formatter.toDisplayCalenderApiType(value)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Calender API" hidden={hideBookingMode}>
                            <Row>
                                <Col span={24}>
                                    {directEmailType && (
                                        <Form.Item {...innerFormItem} label="Authorization Code" name={["calender_api_params", "code"]}
                                                   extra={apiLink()}>
                                            <Input.Password/>
                                        </Form.Item>
                                    )}

                                    {useAppType && (
                                        <Form.Item {...innerFormItem} label="Room Email" name={["calender_api_params", "userId"]}
                                                   extra={apiLink()}>
                                            <Select
                                                showSearch
                                                filterOption={(input, option) => {
                                                    return `${option.label}`.toLowerCase().indexOf(`${input}`.toLowerCase()) >= 0;
                                                }}
                                                options={this.state.ms365_users
                                                    ?.map((u) => {
                                                        const mail = u.mail ? u.mail : u.userPrincipalName;
                                                        const name = u.displayName ? u.displayName : u.id;
                                                        return {value: u.id, label: `${name} (${mail})`};
                                                    })
                                                    .sort((a, b) => {
                                                        return `${a.label}`.localeCompare(`${b.label}`);
                                                    })}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>

                                <Col>
                                    <Row gutter={[8, 8]}>
                                        <Col>
                                            {directEmailType && (
                                                <Input.Group compact>
                                                    <Button loading={authorizing} htmlType="button" onClick={() => this.onAuthorize()}>
                                                        Authorize
                                                        {isApiTokenGranted ? (
                                                            <CheckCircleOutlined style={{color: "green"}}/>
                                                        ) : (
                                                            <CloseCircleOutlined style={{color: "red"}}/>
                                                        )}
                                                    </Button>

                                                    {isApiTokenGranted && (
                                                        <>
                                                            <Tooltip title="Revoke">
                                                                <Button icon={<DeleteOutlined/>} htmlType="button" type="default"
                                                                        onClick={onRevokeApi}/>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </Input.Group>
                                            )}
                                        </Col>
                                        <Col span={1}>
                                            {isApiTokenGranted && (
                                                <Space>
                                                    <Tooltip title="Test API">
                                                        <Button
                                                            icon={<SwapOutlined/>}
                                                            htmlType="button"
                                                            type="default"
                                                            onClick={() => {
                                                                const type = this.formRef.current
                                                                    ? this.formRef.current.getFieldValue("calender_api_type")
                                                                    : null;
                                                                onTestApi(type);
                                                            }}
                                                            loading={doingTest}
                                                        />
                                                    </Tooltip>
                                                </Space>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Enable Calender sync" name="is_sync_calender_api" valuePropName="checked"
                                   hidden={hideBookingMode}>
                            <Switch/>
                        </Form.Item>
                    </>
                )}

                <Form.Item label=" ">
                    <Button htmlType="submit" type="primary">
                        {room ? "Update" : "Submit"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default connect(CommonProps, CommonDispatcher)(RoomFormScreen);

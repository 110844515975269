import React from "react";
import * as moment from "moment-timezone";

import { ui, userService } from "../../services";
import { connect } from "react-redux";
import formatter from "../../helpers/formatter";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip,
    Typography
} from "antd";
import PageContent from "../../components/page-content";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, FileOutlined, ReloadOutlined, } from "@ant-design/icons";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CommonProps } from "@src/store/helpers";
import { FileSelect } from "@src/components/input";

class Container extends React.PureComponent {
    static propTypes = {
        types: PropTypes.array,
    };

    static defaultProps = {
        loading: false,
        select_access_control_levels: [],
        onSubmit: (v) => console.warn("action not register", v),
        onUpdateProfilePicture: (v) => console.warn("action not register", v),
    };

    now = moment();

    columns = [
        {
            dataIndex: ["name"],
            sorter: true,
            title: "Name",
        },
        {
            dataIndex: ["username"],
            title: "Username",
            sorter: true,
        },
        {
            dataIndex: "type",
            title: "Type",
            sorter: true,
            render: formatter.toDisplayUserType,
        },
        {
            dataIndex: "email",
            title: "E-mail",
            sorter: true,
        },
        {
            dataIndex: "auth",
            title: "Auth",
            sorter: true,
            align: "center",
            width: 120,
            render: formatter.toDisplayAuthMethod,
        },
        {
            dataIndex: "is_disable",
            title: "Status",
            width: 120,
            align: "center",
            render: (v) => (
                <Tag color={v ? "error" : "success"}>{v ? "Disabled" : "Active"}</Tag>
            ),
        },
        {
            dataIndex: ["id"],
            title: "Actions",
            align: "right",
            render: (id, r) => (
                <Space direction="horizontal" size={4}>
                    <Link to={`/admin/users/${r.id}`}>
                        <Tooltip title="Edit">
                            <Button type="link" icon={<EditOutlined/>} title="Edit"/>
                        </Tooltip>
                    </Link>

                    {!r.is_disable && (
                        <Tooltip title="Disable User">
                            <Button
                                danger
                                type="link"
                                icon={<CloseCircleOutlined/>}
                                title="Disable"
                                onClick={() => this.onEnableDisable(r, false)}
                            />
                        </Tooltip>
                    )}

                    {r.is_disable && (
                        <Tooltip title="Enable User">
                            <Button
                                type="link"
                                icon={<CheckCircleOutlined/>}
                                title="Enable"
                                style={{color: "green"}}
                                onClick={() => this.onEnableDisable(r, true)}
                            />
                        </Tooltip>
                    )}

                    <Tooltip title="Delete">
                        <Button
                            danger
                            type="link"
                            icon={<DeleteOutlined/>}
                            title="Delete"
                            onClick={() => this.onDelete(r)}
                            disabled={['superadmin', 'admin'].includes(r.username)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    state = {
        pagination: {current: 1, pageSize: 10, total: 0},
        sorter: null,
        rows: [],
        showAdvanceFilter: true,
        showImport: false,
        importForm: {show: false, file: undefined, overwrite: false, results: undefined, loading: false}
    };

    filterRef = React.createRef();

    onDelete(r) {

        this.props.confirm(
            // `Removing the user you will remove all the bookings from this user, this might cause the inaccurate on reporting. You may disable the user to keep the historical booking record. Are you to remove this user? This action cannot be undone.`,
            `Are you to remove this user? This action cannot be undone.`,
            () => {
                this.setState({loading: true});

                // const isAllowedForceDelete = [USER_TYPES.ADMIN, USER_TYPES.SUPER_ADMIN].includes(this.props.auth?.type);

                userService
                    .delete(r.id)
                    .then((rs) => {
                        this.props.notification({message: "Success", type: "success"});
                        this.refresh();
                    })
                    .catch((err) => {
                        this.props.notificationError(err);
                        this.setState({loading: false});
                    });
            }
        );
    }

    onEnableDisable(r, enable) {
        this.props.confirm(
            `Are you sure to ${enable ? "enable" : "disable"} this user?`,
            () => {
                this.setState({loading: true});
                userService
                    .update(r.id, {is_disable: !enable})
                    .then((rs) => {
                        this.props.notification({message: "Success", type: "success"});
                        this.refresh();
                    })
                    .catch((err) => {
                        this.props.notificationError(err);
                        this.setState({loading: false});
                    });
            }
        );
    }

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const filter = this.filterRef.current.getFieldsValue();
        const {filter_type, keyword, is_disable} = filter;
        const query = {};

        if (keyword) {
            query[filter_type] = keyword;
        }
        if (`${is_disable}`) {
            query.is_disable = is_disable;
        }

        if (sorter) {
            query.sort = `${sorter.order === "descend" ? "-" : "+"}${Array.isArray(sorter.field) ? sorter.field.join(".") : sorter.field}`;
        }

        if (this.props.types) {
            query.types = this.props.types;
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        userService
            .list(start, pagination.pageSize, query)
            .then((rs) => {
                pagination.total = rs.total;
                this.setState({
                    rows: rs.items,
                    loading: false,
                    pagination,
                    sorter,
                    filter,
                });
            })
            .catch((err) => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
    };

    onDownloadTemplate = () => {
        ui.confirm(`Are you sure to download the import template?`, async () => {
            await userService.downloadTemplate();
        })
    }

    onImport = () => {
        ui.confirm(`Are you sure to import the selected template?`, async () => {
            try {
                this.setState({importForm: {...this.state.importForm, loading: true}})
                const rs = await userService.import(this.state.importForm.file, {
                    isUpdate: this.state.importForm.overwrite ? "1" : "0"
                })
                this.setState({
                    importForm: {...this.state.importForm, loading: false, results: rs.results}
                });
                this.refresh();
            } catch (err) {
                ui.notify.error(err);
                this.setState({importForm: {...this.state.importForm, loading: false}})
            }
        })
    }

    renderFilter = () => {
        const initialValues = {
            filter_type: "name",
            keyword: "",
            is_disable: "false"
        };

        const width = window.innerWidth;

        return (
            <Form
                layout="vertical"
                initialValues={initialValues}
                onValuesChange={() => this.refresh()}
                ref={this.filterRef}
            >
                <Row>
                    {width >= 600 ?
                        <Col span={18}>
                            <Row>
                                <Col span={3}>
                                    <Form.Item label="Status" name="is_disable">
                                        <Select>
                                            <Select.Option value="false">Active</Select.Option>
                                            <Select.Option value="true">Inactive</Select.Option>
                                            <Select.Option value="">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Filter by" name="filter_type">
                                        <Select>
                                            <Select.Option value="name">Name</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label=" " name="keyword">
                                        <Input allowClear/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label=" ">
                                        <Button
                                            icon={<ReloadOutlined/>}
                                            onClick={() => this.refresh()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Status" name="is_disable">
                                        <Select>
                                            <Select.Option value="false">Active</Select.Option>
                                            <Select.Option value="true">Inactive</Select.Option>
                                            <Select.Option value="">All</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Filter by" name="filter_type">
                                        <Select>
                                            <Select.Option value="name">Name</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="keyword">
                                        <Input allowClear/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            icon={<ReloadOutlined/>}
                                            onClick={() => this.refresh()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Form>
        );
    };

    render() {
        const {pagination, loading, rows, showImport, importForm} = this.state;

        const extra = <Space>
            <Button onClick={() => this.setState({showImport: true})}>
                Bulk Import
            </Button>

            <Link to={"/admin/users/create"}>
                <Button type="primary">Add</Button>
            </Link>
        </Space>

        return (
            <PageContent title="Users" extra={extra}>
                <Space
                    direction="vertical"
                    size={16}
                    style={{width: "100%"}}
                    className="overflow-x"
                >
                    {this.renderFilter()}
                    <Table
                        bordered
                        rowKey={"id"}
                        footer={(data) => <span>
                            Showing {data.length} record(s) of total {pagination.total}
                        </span>}
                        columns={this.columns}
                        loading={loading}
                        dataSource={rows}
                        pagination={pagination}
                        onChange={(pagination, filters, sorter) => {
                            this.refresh({pagination, filters, sorter});
                        }}
                    />
                </Space>


                <Modal title="Import Users" open={showImport} footer={false} closables
                       onCancel={() => this.setState({showImport: false})}>

                    <Card>
                        <Space direction="vertical" size={8}>
                            <Typography.Text>
                                Download the new template before uploading to ensure you are using our latest template.
                            </Typography.Text>
                            <Button onClick={() => this.onDownloadTemplate()}>
                                Download Template (.xlsx)
                            </Button>
                        </Space>
                        <Divider/>
                        <Space direction="vertical" size={8}>
                            <Typography.Text>
                                Import the users list by uploading the completed users template.
                            </Typography.Text>
                            <div>
                                <Button type="primary" onClick={() => this.setState({
                                    importForm: {show: true},
                                    showImport: false
                                })}>
                                    Upload Excel Sheet Template
                                </Button><br/>
                                <Typography.Text italic style={{fontSize: 12}}>
                                    Files supported (.xlsx)
                                </Typography.Text>
                            </div>
                        </Space>
                    </Card>

                </Modal>

                <Modal
                    title="Import User Template"
                    open={importForm.show}
                    closable={true}
                    footer={null}
                    destroyOnClose
                    onCancel={() => this.setState({importForm: {show: false}})}
                >
                    <Row>
                        {importForm.results && (
                            // created: number, updated: number, failed: any[], received: number, exists: number
                            <>
                                <Col span={24}>
                                    <Descriptions bordered column={1} labelStyle={{width: "40%"}}>
                                        <Descriptions.Item label="Received">
                                            {importForm.results?.received}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Created New">
                                            {importForm.results?.created}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Updated">
                                            {importForm.results?.updated}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Already Exists">
                                            {importForm.results?.exists}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Failed">
                                            {importForm.results?.failed?.length > 0 ? importForm.results?.failed?.map((f, i) => {
                                                return (
                                                    <div key={i}>
                                                        <strong>Row {f.rowNo}</strong>:&nbsp;&nbsp;{f.message}
                                                    </div>
                                                );
                                            }) : 0}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={24} style={{textAlign: "right", marginTop: 24}}>
                                    <Space direction="horizontal">
                                        <Button onClick={() => this.setState({importForm: {show: false}})}>
                                            Close
                                        </Button>
                                    </Space>
                                </Col>
                            </>
                        )}
                        {!importForm.results && (
                            <Spin spinning={importForm.loading ?? false}>
                                <Col span={24}>
                                    <Space direction="horizontal">
                                        File:&nbsp;&nbsp;
                                        <FileSelect
                                            acceptTypes={[
                                                "application/vnd.ms-excel",
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            ]}
                                            value={importForm.file}
                                            maxSizeKb={10 * 1024}
                                            onChange={(file) => {
                                                this.setState({importForm: {...importForm, file}});
                                            }}
                                        />
                                    </Space>
                                    <br/>

                                    <Typography.Text italic style={{fontSize: 12}}>
                                        Files supported (.xls) - Maximum file size 10MB
                                    </Typography.Text>
                                    <br/>
                                    <br/>
                                    <Checkbox onChange={(e) => {
                                        this.setState({importForm: {...importForm, overwrite: e.target.checked}});
                                    }}>Overwrite</Checkbox>

                                    {importForm.file?.name && <>
                                        <br/>
                                        <br/>
                                        <FileOutlined/>&nbsp;&nbsp;
                                        <Typography.Text style={{marginTop: 16}}>
                                            {importForm.file?.name}
                                        </Typography.Text>
                                    </>}
                                </Col>
                                <Col span={24} style={{marginTop: 32}}>
                                    <Space direction="horizontal">
                                        <Button onClick={() => this.setState({importForm: {...importForm, show: false}})}>Cancel</Button>
                                        <Button type="primary" disabled={!importForm.file}
                                                onClick={() => this.onImport()}>
                                            Import
                                        </Button>
                                    </Space>
                                </Col>
                            </Spin>
                        )}
                    </Row>
                </Modal>
            </PageContent>
        );
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

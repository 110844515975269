import authService from "./api/auth";
import fileService from "./api/file";
import eventService from "./api/event";
import reportService from "./api/report";
import summaryService from "./api/summary";
import settingService from "./api/setting";
import userService from "./api/user";
import bookingService from "./api/booking";
import roomService from "./api/room";
import deviceService from "./api/device";
import integrationService from "./api/integration";
import facilityService from "./api/facility";
import advertisementService from "./api/advertisement";

import roleService from "./role";

import * as ui from "./ui";

const api = {
    setting: settingService,
    device: deviceService,
};
export {
    ui,
    api,
    advertisementService,
    roleService,
    authService,
    roomService,
    fileService,
    eventService,
    bookingService,
    reportService,
    summaryService,
    settingService,
    userService,
    deviceService,
    integrationService,
    facilityService,
};

import React from 'react';
import {Col, Layout, Menu, Input, Row, Button} from 'antd';
import {LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import {CommonProps, CommonDispatcher} from '../../store/helpers';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

import {authService} from '../../services';

import {OVERALL_MENUS} from '../../constants/menu';
import {Link} from "react-router-dom";
import history from "../../routes/history";
import {SETTING_KEYS, AUTH_METHODS} from "../../constants";

import MicrosoftLogoutButton from "../buttons/microsoft-logout-button";
import GoogleLogoutButton from "../buttons/google-logout-button";


class LayoutHeader extends React.PureComponent {

    static propTypes = {
        collapsed: PropTypes.bool,
        onMenuIconClick: PropTypes.func,
    };

    static defaultProps = {
        collapsed: false,
        onMenuIconClick: () => console.warn('not register any action!'),
    };

    state = {};

    afterLogout = () => {
        this.props.splash(false);
        this.props.sessionExpired();
        history.replace('/login');
    };

    doLogout = () => {
        this.props.confirm('Are you sure to logout ?', () => {
            this.props.splash(true);
            authService.logout().finally(this.afterLogout);
        });
    };

    renderMenus = () => {
        const {auth} = this.props;

        return Object.keys(OVERALL_MENUS).map(k => {
            const menu = OVERALL_MENUS[k];
            const {title, path, children, permission} = menu;

            if (menu[k].hasOwnProperty('permission') && permission) {
                const show = auth?.permissions ? permission.every(p => auth.permissions.includes(p)) : false
                if (!show) {
                    return ''
                }
            }

            if (children && children.length > 0) {
                return <Menu.SubMenu title={title} key={`menu-${k}`}>
                    {children.filter(m => {
                        if (m.hasOwnProperty('permission') && m.permission) {
                            return auth?.permissions ? m.permission.every(p => auth.permissions.includes(p)) : false
                        }
                        return true;
                    }).map((m, index) => <Menu.Item key={`${k}-${index}`}>
                        <Link to={m.path}>
                            {m.title}
                        </Link>
                    </Menu.Item>)}
                </Menu.SubMenu>
            }
            return <Menu.Item key={`menu-${k}`}>
                <Link to={path}>
                    {title}
                </Link>
            </Menu.Item>
        });
    }

    render() {
        const {auth, app_settings, collapsed, onMenuIconClick} = this.props;

        const gClientId = app_settings ? app_settings[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_ID] : null;
        const msClientId = app_settings ? app_settings[SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID] : null;

        let logoutButton;
        if (auth?.auth === AUTH_METHODS.MICROSOFT) {
            logoutButton = <MicrosoftLogoutButton clientId={gClientId} afterLogout={this.afterLogout}/>;
        } else if (auth?.auth === AUTH_METHODS.GOOGLE) {
            logoutButton = <GoogleLogoutButton clientId={msClientId} afterLogout={this.afterLogout}/>;
        } else {
            logoutButton = <Button onClick={this.doLogout} icon={<LogoutOutlined/>}/>;
        }
        return (
            <Layout.Header className="header">
                <Row>
                    <Col span={3}>
                        <span style={{cursor: 'pointer', paddingLeft: 32, fontSize: '1em'}}
                              onClick={onMenuIconClick}>
                            {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        </span>
                    </Col>
                    <Col span={15}>
                        &nbsp;
                    </Col>
                    <Col span={6} className="header-actions">
                        <Input.Group compact>
                            <Button>
                                <Link to={'/profile'}>{auth?.name}&nbsp;</Link>
                            </Button>
                            {logoutButton}
                        </Input.Group>
                    </Col>
                </Row>
            </Layout.Header>
        );
    }
}

export default connect(CommonProps, CommonDispatcher)(LayoutHeader);

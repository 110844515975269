class FileHelper {
    fileToBase64 = (f) => {
        return new Promise(((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(f);
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            } catch (e) {
                reject(e);
            }
        }))
    };
}


export default new FileHelper();

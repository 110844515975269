import React from "react";

import {APP, AUTH_METHODS, SETTING_KEYS} from "../../constants";
import * as moment from 'moment-timezone';
import {connect} from 'react-redux';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import {authService} from '../../services';

import LOGO from '../../assets/images/logo-long.png';
import {Spin, Button, Card, Col, Form, Input, Row, Space, Typography, Modal} from "antd";
import {
    UserOutlined,
    LockOutlined,
    ArrowRightOutlined,
    LoginOutlined,
    GoogleOutlined,
    WindowsOutlined
} from '@ant-design/icons';

import MicrosoftLoginButton from '../../components/buttons/microsoft-login-button';
import GoogleLoginButton from "../../components/buttons/google-login-button";

class Container extends React.PureComponent {

    lockPrevent = {
        microsoft: false,
        google: false
    }

    now = moment();

    state = {
        loading: false,
        settings: {},
        show_admin_login: false
    };

    afterLogin = () => {
        authService.getProfile().then(user => {
            this.props.notification({message: `Welcome, ${user.name}`, type: 'success'});
            this.props.setUserSession(user);
            if (user.is_first_time_login) {
                this.props.history.push('/first-time-login');
            } else {
                this.props.history.push('/admin');
            }
        }).catch(err => {
            authService.flush();
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    }

    doLogin = ({username, password}, method = null) => {
        this.setState({loading: true});
        return authService.login(username, password, method).then(rs => {
            this.afterLogin();
        }).catch(err => {
            authService.flush();
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    doOthersLogin = (type, payload) => {
        let api;
        switch (type) {
            case 'google':
                api = authService.loginWith3rdParty(payload, type);
                break;
            case 'microsoft':
                api = authService.loginWith3rdParty(payload, type);
                break;
            default:
                console.warn(`unknown login type ${type}`);
        }

        if (api) {
            this.setState({loading: true});
            api.then(rs => {
                this.afterLogin();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        }
    };

    renderLoginButtons = () => {
        const {app_settings} = this.props;

        const localLogin = {type: 'local', icon: <LoginOutlined/>, label: 'Sign In'};
        const microsoftLogin = {
            type: 'microsoft',
            icon: <WindowsOutlined/>,
            label: 'Sign In with Microsoft',
            client_id: app_settings?.[SETTING_KEYS.AUTH.SSO.MICROSOFT.CLIENT_ID],
            tenant_id: app_settings?.[SETTING_KEYS.AUTH.SSO.MICROSOFT.TENANT_ID],
            autoLogin: false
        };
        const googleLogin = {
            type: 'google',
            icon: <GoogleOutlined/>,
            label: 'Sign In with Google',
            client_id: app_settings?.[SETTING_KEYS.AUTH.SSO.GOOGLE.CLIENT_ID],
            autoLogin: false
        };

        const availableLogins = [];
        if (app_settings?.[SETTING_KEYS.AUTH.METHOD] === AUTH_METHODS.GOOGLE) {
            availableLogins.push(googleLogin);
        } else if (app_settings?.[SETTING_KEYS.AUTH.METHOD] === AUTH_METHODS.MICROSOFT) {
            availableLogins.push(microsoftLogin);
        } else {
            availableLogins.push(localLogin);
        }

        const style = {minWidth: 120};
        return availableLogins.map((login, index) => {
            const {type, label, icon, client_id, tenant_id, autoLogin} = login;

            switch (type) {
                case 'google' :
                    return <GoogleLoginButton
                        key={index}
                        clientId={client_id}
                        style={style}
                        icon={icon}
                        label={label}
                        autoLogin={autoLogin}
                        onAuthenticated={account => {
                            if (autoLogin && this.lockPrevent.google) {
                                console.warn(`tried once authenticate but failed`)
                                return;
                            }
                            this.lockPrevent.google = true;
                            this.doOthersLogin(type, account)
                        }}
                    />
                case 'microsoft':
                    return <MicrosoftLoginButton
                        key={index}
                        clientId={client_id}
                        tenantId={tenant_id}
                        style={style}
                        icon={icon}
                        label={label}
                        autoLogin={autoLogin}
                        onAuthenticated={account => {
                            if (autoLogin && this.lockPrevent.microsoft) {
                                console.warn(`tried once authenticate but failed`)
                                return;
                            }
                            this.lockPrevent.microsoft = true;
                            this.doOthersLogin(type, account)
                        }}
                    />;
                default:
                    return <Button key={index} icon={icon} htmlType="submit" type="primary" style={style}>
                        {label}
                    </Button>
            }
        });
    };

    renderAdminLogin() {
        return <Form layout="vertical" onFinish={(values) => this.doLogin(values, AUTH_METHODS.LOCAL)}>
            <Form.Item name={'username'} rules={[{
                required: true,
                message: 'This field is require',
            }]}>
                <Input placeholder="username" prefix={<UserOutlined/>}
                />
            </Form.Item>
            <Form.Item name={'password'} rules={[{
                required: true,
                message: 'This field is require',
            }]}>
                <Input placeholder="password" prefix={<LockOutlined/>} type="password"/>
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    }

    render() {
        const {app_settings} = this.props;
        const {loading, show_admin_login} = this.state;

        const authMethod = app_settings?.[SETTING_KEYS.AUTH.METHOD];
        const isLdap = authMethod === AUTH_METHODS.LDAP;
        const isPublicDomainLogin = [
            AUTH_METHODS.GOOGLE,
            AUTH_METHODS.MICROSOFT
        ].includes(authMethod);

        const compLink = APP.APP_POWERED_BY_WEBSITE ?
            <a style={{color: 'black'}} href={APP.APP_POWERED_BY_WEBSITE} target="_blank" rel="noopener noreferrer">
                {APP.APP_POWERED_BY}
            </a> : APP.APP_POWERED_BY

        return <Spin spinning={loading}>
            <Row justify="center" align="middle" style={{height: '100vh'}}>
                <Col xs={22} sm={20} md={12} lg={12} xl={8}>
                    <Card>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col span={24}>
                                <Space size={2} style={{width: '100%', textAlign: 'center'}} direction="vertical">
                                    <img alt="Logo" style={{width: '80%', padding: 16}} src={LOGO}/>

                                    <Typography.Title level={3}>
                                        {app_settings?.[SETTING_KEYS.SYSTEM.COMPANY.NAME]}
                                    </Typography.Title>

                                    <Typography.Text>
                                        {APP.NAME}
                                    </Typography.Text>
                                </Space>
                            </Col>

                            <Col md={24}>
                                <Form layout="vertical" onFinish={this.doLogin}>
                                    {!isPublicDomainLogin && <>
                                        <Form.Item name={'username'} rules={[{
                                            required: true,
                                            message: 'This field is require',
                                        }]}>
                                            <Input placeholder="username" prefix={<UserOutlined/>}
                                            />
                                        </Form.Item>
                                        <Form.Item name={'password'} rules={[{
                                            required: true,
                                            message: 'This field is require',
                                        }]}>
                                            <Input placeholder="password" prefix={<LockOutlined/>} type="password"/>
                                        </Form.Item>
                                    </>}

                                    <Form.Item>
                                        <Space align="center" direction="vertical"
                                               style={{width: '100%', textAlign: 'center'}}>
                                            {this.renderLoginButtons()}
                                            <Button type="link" href="/forgot-password">Forgot Password</Button>
                                            <Typography.Text style={{opacity: 0.5, fontSize: 12}}>
                                                {compLink} &copy;{this.now.year()} - v{APP.VERSION}
                                            </Typography.Text>
                                        </Space>
                                    </Form.Item>

                                </Form>
                            </Col>
                        </Row>

                        {(isLdap || isPublicDomainLogin) && <Row justify="end">
                            <Col>
                                <Typography.Text type="secondary">
                                    <Button icon={<ArrowRightOutlined/>} type="link"
                                            onClick={() => this.setState({show_admin_login: true})}>
                                        Admin Login
                                    </Button>
                                </Typography.Text>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
            </Row>

            <Modal title="Admin Login" visible={show_admin_login} footer={null}
                   onCancel={() => this.setState({show_admin_login: false})}>
                {this.renderAdminLogin()}
            </Modal>
        </Spin>
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

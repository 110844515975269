import React from 'react';
import {Spin, PageHeader} from 'antd';
import {connect} from 'react-redux';
import commonDispatcher from '../../store/helpers/commons-dispatcher';

import PropTypes from 'prop-types';

import history from "../../routes/history";

class PageContent extends React.PureComponent {

    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        hasBack: PropTypes.bool,
        extra: PropTypes.object,
        loading: PropTypes.bool
    };

    static defaultProps = {
        loading: false
    };

    state = {};

    render() {
        const {title, subTitle, extra, hasBack, loading} = this.props;
        return <PageHeader
            ghost={false}
            onBack={hasBack ? () => history.goBack() : null}
            title={title}
            subTitle={subTitle}
            extra={extra}
        >
            <Spin spinning={loading}>
                {this.props.children}
            </Spin>
        </PageHeader>
    }
}

export default connect(null, commonDispatcher)(PageContent);

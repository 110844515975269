import React from 'react';
import {Upload, Modal, Tooltip, Badge} from 'antd';
import {DndProvider, useDrag, useDrop, createDndContext} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {PlusOutlined} from '@ant-design/icons';
import PropTypes from "prop-types";

import './style.css';
import {CommonDispatcher} from '../../store/helpers';
import {connect} from "react-redux";

const type = 'DragableUploadList';

const DragableUploadListItem = ({originNode, moveRow, file, fileList, onPreview, onDelete}) => {
    const ref = React.useRef();
    const index = fileList.indexOf(file);
    const [{isOver, dropClassName}, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const {index: dragIndex} = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        item: {type, index},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    const errorNode = (
        <Tooltip title="Upload Error" getPopupContainer={() => document.body}>
            {originNode.props.children}
        </Tooltip>
    );

    return <div ref={ref} key={`item=${index}`}
                className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
                style={{cursor: 'move', height: '100%'}}>
        <Badge.Ribbon placement="start" text={(index + 1)}>
            {file.status === 'error' ? errorNode : originNode}
            {/*<>
                <Card bordered style={{borderRadius: 8, height: '100%'}} bodyStyle={{padding: 8, height: '100%'}}>
                    <Row align="middle" justify="center" style={{height: '100%'}}>{}
                        <Col span={12}>
                            {imgBase64 && <img src={imgBase64} style={{width: '100%'}}/>}
                        </Col>
                    </Row>
                </Card>
            </>*/}
        </Badge.Ribbon>
    </div>
};

class Component extends React.Component {

    static propTypes = {
        files: PropTypes.array,
        onChange: PropTypes.func,
        maxFile: PropTypes.number
    };

    static defaultProps = {
        files: [],
        maxFile: 10
    };

    state = {
        files: []
    }

    dndRef = createDndContext(HTML5Backend);

    constructor(props) {
        super(props);
        if (props.hasOwnProperty('files')) {
            this.state.files = props.files.map(f => {
                if (f.base64) {
                    f.thumbUrl = `data:image/png;base64,${f.base64.substring(f.base64.indexOf('base64,') + 7)}`
                }
                return f;
            });
        }
    }

    render() {
        const {files, preview} = this.state;
        const {onChange, maxFile} = this.props;
        const uploadButton = <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>

        return <DndProvider backend={HTML5Backend}>
            <Upload
                key={`uid`}
                listType="picture-card"
                fileList={files}
                onChange={({fileList}) => {
                    this.setState({files: fileList});
                    onChange(fileList);
                }}
                onPreview={async file => {
                    this.setState({preview: file});
                }}
                beforeUpload={(file) => {
                    return false;
                }}
                itemRender={(originNode, file, currFileList) => (
                    <DragableUploadListItem
                        originNode={originNode}
                        file={file}
                        fileList={currFileList}
                        moveRow={(dragIndex, hoverIndex) => {
                            const dragRow = files[dragIndex];
                            const newArrangedFiles = update(files, {
                                $splice: [
                                    [dragIndex, 1],
                                    [hoverIndex, 0, dragRow],
                                ],
                            });
                            this.setState({
                                files: newArrangedFiles
                            })
                            onChange(newArrangedFiles);
                        }}
                    />
                )}
            >
                {files.length >= maxFile ? null : uploadButton}
            </Upload>

            <Modal
                visible={!!preview}
                footer={null}
                onCancel={() => this.setState({preview: null})}
            >
                <img alt="preview" style={{width: '100%'}} src={preview?.base64}/>
            </Modal>
        </DndProvider>;
    }
}

export default connect(null, CommonDispatcher)(Component);

import http from './http';

class EventService {
    list(offset = 0, limit = 100, query = {}) {
        return http.get(`/api/events`, {offset, limit, ...query});
    }

    clear(date) {
        return http.delete(`/api/events/${date}`);
    }
}

export default new EventService();

import React, {Component} from 'react';
import {CommonDispatcher} from '../../store/helpers';
import {connect} from 'react-redux';

import {authService} from '../../services';
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";


class Session extends Component {

    static propTypes = {
        require: PropTypes.bool,
        url: PropTypes.string,
        firstTimeLoginUrl: PropTypes.string
    };
    static defaultProps = {
        require: true,
    };

    state = {
        redirect: false
    }

    render() {
        const {redirect, url} = this.state;
        return <>
            {redirect && <Redirect to={url}/>}
        </>
    }

    componentDidMount() {
        const {require} = this.props;
        if (require) {
            if (authService.hasToken()) {
                authService.getProfile().then(rs => {
                    this.props.setUserSession(rs);
                    if (rs.is_first_time_login) {
                        this.setState({redirect: true, url: this.props.firstTimeLoginUrl})
                    }
                }).catch(err => {
                    if (err.statusCode === 401) {
                        this.props.message({title: 'Session', message: 'Login Require!'});
                        this.setState({redirect: true, url: this.props.url})
                    } else {
                        this.props.notificationError(err);
                    }
                });
            } else {
                this.setState({redirect: true, url: this.props.url})
                this.props.message({title: 'Session', message: 'Login Require!'});
            }
        } else {
            if (authService.hasToken()) {
                authService.getProfile().then(rs => {
                    this.props.setUserSession(rs);
                    if (!rs.is_first_time_login) {
                        this.setState({redirect: true, url: this.props.url})
                    }
                }).catch(err => {
                    if (err.statusCode !== 401) {
                        this.props.notificationError(err)
                    }
                });
            }
        }
    }
}

export default connect(null, CommonDispatcher)(Session);

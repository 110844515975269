import { Acl } from "@src/helpers/acl";
import { ACL_ACTIONS } from "@src/constants/acl";
import { BOOKING_STATUS } from "@src/constants";
import formatter from "@src/helpers/formatter";
import ColorHelper from "@src/helpers/colors";
import { Button, Col, Descriptions, Row, Tag } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const BookingInfo = ({onDelete, booking}) => {
    const {auth} = useSelector(({backend}) => backend);

    const isOrganizer = auth?.id && `${auth?.id}` === `${booking?.user?.id}`;

    const acl = Acl(auth);
    const allowedDeleteBooking = acl.isAllowed(ACL_ACTIONS.BOOKINGS.DELETE);

    let statusLabel = null;
    if (booking?.status?.includes(BOOKING_STATUS.CANCELLED)) {
        statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CANCELLED);
    } else if (booking?.status?.includes(BOOKING_STATUS.ENDED)) {
        statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ENDED);
    } else if (booking?.status?.includes(BOOKING_STATUS.NEW)) {
        statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.NEW);
    } else if (booking?.status?.includes(BOOKING_STATUS.ONGOING)) {
        if (booking?.status?.includes(BOOKING_STATUS.CHECKED_IN)) {
            statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CHECKED_IN);
        } else {
            statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ONGOING);
        }
    }

    const color = ColorHelper.getBookingColor(booking?.status);

    const cancelled = booking?.status?.includes(BOOKING_STATUS.CANCELLED);

    return (
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Descriptions column={1} size="small" bordered>
                    <Descriptions.Item label="Status">
                        <Tag color={color}>{statusLabel}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Title">{booking?.title}</Descriptions.Item>
                    <Descriptions.Item label="Remark">{booking?.remarks}</Descriptions.Item>
                    <Descriptions.Item label={formatter.toDisplayRoomType(booking?.room?.type)}>{booking?.room?.name}</Descriptions.Item>
                    <Descriptions.Item label="Start">{formatter.toDisplayDatetime(booking?.start)}</Descriptions.Item>
                    <Descriptions.Item label="End">{formatter.toDisplayDatetime(booking?.end)}</Descriptions.Item>

                    {cancelled && (
                        <>
                            <Descriptions.Item label="Cancelled by">{booking?.cancelled_user?.name}</Descriptions.Item>
                            <Descriptions.Item
                                label="Cancelled date">{formatter.toDisplayDatetime(booking?.cancelled_date)}</Descriptions.Item>
                        </>
                    )}

                    <Descriptions.Item label="Notify E-mails">
                        <ul style={{margin: 0, paddingLeft: 16}}>
                            {booking?.notify_emails?.map(e => <li key={e}>{e}</li>)}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Invited Users">
                        <ul style={{margin: 0, paddingLeft: 16}}>
                            {booking?.invited_users?.map(e => <li key={e}>{e?.email} - {e?.name}</li>)}
                        </ul>
                    </Descriptions.Item>


                    <Descriptions.Item label="Booked by">{booking?.user ? booking?.user?.name : booking?.organizer_name}</Descriptions.Item>
                    {(acl.isAdmin() || isOrganizer) &&
                        <Descriptions.Item label="Booking date">{formatter.toDisplayDatetime(booking?.date)}</Descriptions.Item>}
                </Descriptions>
            </Col>

            {allowedDeleteBooking && onDelete && booking?.id && (
                <Col span={24} style={{textAlign: "right"}}>
                    <Button danger icon={<DeleteOutlined/>} htmlType="button" type="default" onClick={onDelete}>
                        Delete
                    </Button>
                    &nbsp;
                </Col>
            )}
        </Row>
    );
};

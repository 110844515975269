import {ACL} from '../constants/acl'
import {USER_TYPES} from "../constants";

export const Acl = (auth) => {
    const aclTable = (auth?.type && ACL.hasOwnProperty(auth?.type)) ? ACL[auth.type] : [];
    return {
        isAdmin: () => {
            return [USER_TYPES.ADMIN, USER_TYPES.SUPER_ADMIN].includes(auth?.type)
        },
        isAllowed: (action) => {
            if (auth?.type === USER_TYPES.SUPER_ADMIN) {
                return true;
            }
            return aclTable.includes(action);
        }
    }
}

import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Space, Steps } from "antd";
import { connect } from "react-redux";
import { CommonDispatcher, CommonProps } from "../../store/helpers";
import "@src/assets/react-big-calender.css";
import { BOOKING_STATUS, ROOM_TYPES, SETTING_KEYS } from "@src/constants";
import { ACL_ACTIONS } from "@src/constants/acl";
import { Acl } from "@src/helpers/acl";
import { FormRoom } from "./form/room";
import { FormDateTime } from "./form/date-time";
import { FormUser } from "./form/user";
import { FormConfirm } from "./form/confirm";
import { BookingInfo } from "@src/screens/bookings/info";
import * as moment from "moment";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";

class BookingFormScreen extends React.Component {

    static propTypes = {
        booking: PropTypes.object,
        onSubmit: PropTypes.func,
        onDelete: PropTypes.func,
        onCancel: PropTypes.func,
        getRoomFacilities: PropTypes.func,
        users: PropTypes.array,
        rooms: PropTypes.array,
    };

    static defaultProps = {
        users: [],
        rooms: [],
        onSubmit: (v) => console.warn("action not register", v),
        onDelete: (v) => console.warn("action not register", v),
        onCancel: (v) => console.warn("action not register", v),
        getRoomFacilities: (v) => console.warn("action not register", v),
    };

    state = {
        current: 0,
        formValues: {}
    };

    constructor(props) {
        super(props);
        const {booking} = props;
        this.state.formValues = {
            type: booking?.room?.type ?? ROOM_TYPES.ROOM,
            title: booking?.title,
            room: booking?.room?.id,
            notify_emails: booking?.notify_emails,
            user: booking?.user?.id,
            remarks: booking?.remarks,
            invited_users: booking?.invited_users?.map(u => u.id),
            start: booking?.start ? moment(booking?.start) : null,
            end: booking?.end ? moment(booking?.end) : null
        }
    }

    doSubmit = (values) => {
        const submit = {
            ...values,
            start: values?.start ? moment(values?.start).toISOString() : null,
            end: values?.start ? moment(values?.end).toISOString() : null
        };
        delete submit.date;
        delete submit._start;
        delete submit._end;
        this.props.onSubmit(submit);
    };

    render() {
        const {current, formValues, ready} = this.state;
        const {booking, app_settings, auth} = this.props;

        const acl = Acl(auth);

        const isUpdate = !!booking?.id;
        const isEndedOrCancelledBooking = booking?.status?.includes(BOOKING_STATUS.ENDED) || booking?.status?.includes(BOOKING_STATUS.CANCELLED);

        const isOrganizer = auth?.id && `${auth?.id}` === `${booking?.user?.id}`;
        const isAllowedBookOnBehalf = app_settings?.[SETTING_KEYS.BOOKING.IS_ALLOWED_BOOK_ON_BEHALF] && acl.isAllowed(ACL_ACTIONS.BOOKINGS.BOOK_ON_BEHALF);

        const allowedCancelBooking = !isEndedOrCancelledBooking && acl.isAllowed(ACL_ACTIONS.BOOKINGS.CANCEL) ? (isOrganizer || acl.isAdmin()) : false;
        const allowedDeleteBooking = acl.isAllowed(ACL_ACTIONS.BOOKINGS.DELETE);
        const allowedUpdateBooking = isOrganizer || acl.isAdmin();

        const displayInfoOnly = isUpdate && (!booking?.status?.includes(BOOKING_STATUS.NEW) || !allowedUpdateBooking);

        const steps = [
            {
                title: "Room",
            },
            {
                title: "Date & Time",
            },
            {
                title: "User",
            },
            {
                title: "Confirmation",
            },
        ];

        const goBack = () => this.setState({current: current > 0 ? current - 1 : 0});
        const goNext = () => this.setState({current: current + 1});

        const formProviderProps = {
            onFormFinish: (name, info) => {
                this.setState({
                    formValues: {...formValues, ...info.values}
                }, () => {
                    goNext();
                })
            }
        };

        const control = isUpdate && <Space direction="horizontal" size={8}>
            {allowedDeleteBooking &&
                <Button danger icon={<DeleteOutlined/>} htmlType="button" type="default"
                        onClick={() => this.props.onDelete()}>
                    Delete
                </Button>}
            <Button danger icon={<StopOutlined/>} htmlType="button" type="default"
                    disabled={!allowedCancelBooking}
                    onClick={() => this.props.onCancel()}>
                Cancel
            </Button>
        </Space>

        return displayInfoOnly ? <>
            <BookingInfo booking={booking} auth={auth}/>
            {!!control && <div style={{marginTop: 24, textAlign: "right", width: "100%"}}>
                {control}
            </div>}
        </> : (
            <>
                <Steps progressDot current={current}>
                    {steps.map((item) => (
                        <Steps.Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                {ready && <Form.Provider {...formProviderProps}>
                    <div style={{marginTop: 24}}>
                        {<FormRoom
                            extra={control}
                            name="room"
                            show={current === 0}
                            initialValues={formValues}
                            control={{
                                allow_next: true,
                                allow_previous: false,
                            }}
                            onPrevious={goBack}
                        />}
                        {<FormDateTime
                            bookingId={booking?.id}
                            extra={control}
                            name="datetime"
                            show={current === 1}
                            initialValues={formValues}
                            room_id={formValues?.room}
                            control={{
                                allow_next: true,
                                allow_previous: true,
                            }}
                            onPrevious={goBack}
                        />}
                        {<FormUser
                            extra={control}
                            name="users"
                            show={current === 2}
                            initialValues={formValues}
                            control={{
                                allow_next: true,
                                allow_previous: true,
                            }}
                            onPrevious={goBack}
                            isAllowedBookOnBehalf={isAllowedBookOnBehalf}/>}

                        {<FormConfirm
                            extra={control}
                            is_update={!!booking?.id}
                            name="confirm"
                            show={current === 3}
                            values={formValues}
                            onPrevious={goBack}
                            onSubmit={() => this.doSubmit(formValues)}
                        />}
                    </div>
                </Form.Provider>}
            </>
        );
    }

    componentDidMount() {
        this.setState({ready: true});
    }
}

export default connect(CommonProps, CommonDispatcher)(BookingFormScreen);

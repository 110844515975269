import React from "react";
import { ui } from "../services";
import { Button, Input, Upload } from "antd";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";

export const FileSelect = (props) => {
    const {value, disabled, onChange, acceptTypes, maxSizeKb} = props;

    return <>
        <Input.Group>
            <Upload
                accept={acceptTypes?.join(",")}
                style={{backgroundColor: disabled ? "#e7e7e7" : undefined}}
                disabled={disabled}
                listType="text"
                multiple={false}
                showUploadList={false}
                customRequest={(info) => {
                    if (onChange) {
                        onChange(info.file);
                    }
                }}
                beforeUpload={(file) => {
                    const isTypeOk = !acceptTypes || acceptTypes.includes(file.type);
                    const isSizeOk = !maxSizeKb || maxSizeKb >= file.size / 1024;
                    if (!isTypeOk) {
                        ui.toast.warn(`only accept file types ${acceptTypes?.join(", ")}`);
                    }
                    if (!isSizeOk) {
                        ui.toast.warn(`exceeded maximum file size ${maxSizeKb / 1024}MB`);
                    }

                    return isTypeOk && isSizeOk;
                }}
            >
                <Button icon={<UploadOutlined/>} disabled={!!value}>
                    Click to Select
                </Button>
            </Upload>
            <Button style={{marginLeft: 4}} icon={<CloseCircleOutlined/>} disabled={!value} onClick={() => {
                if (onChange) {
                    onChange(undefined);
                }
            }}/>
        </Input.Group>
    </>;
};

import React from "react";
import {connect} from 'react-redux';
import {CommonDispatcher, CommonProps} from "../../store/helpers";
import {authService, userService} from '../../services';
import {Button, Modal, Col, Form, Input, Row} from "antd";
import PageContent from "../../components/page-content";


class Container extends React.PureComponent {

    state = {
        loading: false,
        modalVisible: false
    };

    formRef = React.createRef();
    passwordFormRef = React.createRef();

    componentDidMount() {
        userService.get(this.props.auth?.id).then(user => {
            this.setState({user, init_done: true});
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({init_done: true});
        });
    }

    doChangePassword = (form) => {
        this.props.confirm('Are you sure to update your password?', () => {
            this.setState({loading: true});
            const {current_password, new_password} = form;
            authService.updatePassword(current_password, new_password).then(() => {
                this.props.notification({message: 'Success', type: 'success'});
                this.setState({loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    doUpdate = (form) => {
        this.props.confirm('Are you sure to update your profile?', () => {
            this.setState({loading: true});
            userService.update(this.props.auth.id, form).then(() => {
                this.props.notification({message: 'Success', type: 'success'});
                this.setState({loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    render() {
        const {auth} = this.props;

        const {loading} = this.state;

        const initialValues = {
            name: auth?.name,
            email: auth?.email
        };

        const extra = <Button type="primary" onClick={() => this.setState({modalVisible: true})}>
            Change Password
        </Button>;
        return (
            <PageContent title="User Profile" extra={extra} loading={loading}>
                <Row>
                    <Col span={12}>
                        <Form layout="vertical" onFinish={this.doUpdate} autoComplete="off" ref={this.formRef}
                              initialValues={initialValues}>

                            <Form.Item label="Username">
                                <Input value={auth?.username} disabled/>
                            </Form.Item>
                            <Form.Item label="Pin">
                                <Input value={auth?.pin} disabled/>
                            </Form.Item>

                            <Form.Item label="Name" name={'name'} rules={[{
                                required: true,
                                message: 'Name is require',
                            }]}>
                                <Input placeholder="Name"/>
                            </Form.Item>
                            <Form.Item label="E-mail" name={'email'} rules={[{
                                required: true,
                                message: 'E-mail is required'
                            }, {
                                type: "email",
                                message: 'Invalid email format',
                            }]}>
                                <Input placeholder="E-mail" type="email"/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Modal title="Change Password" visible={this.state.modalVisible} onOk={() => {
                    if (this.passwordFormRef.current) {
                        this.passwordFormRef.current.submit();
                    }
                }} onCancel={() => this.setState({modalVisible: false})}>
                    <Form ref={this.passwordFormRef} layout="vertical" onFinish={values => {
                        this.doChangePassword(values);
                        this.setState({modalVisible: false});
                    }}>
                        <Form.Item name={'current_password'} rules={[
                            {required: true, message: 'Please insert your current password'},
                        ]}>
                            <Input.Password placeholder="Current Password"/>
                        </Form.Item>
                        <Form.Item name={'new_password'} rules={[
                            {required: true, message: 'Please insert your current password'},
                        ]}>
                            <Input.Password placeholder="New Password"/>
                        </Form.Item>
                        <Form.Item name={'retype_password'} rules={[
                            {required: true, message: 'Please retype your new password'},
                            {
                                validator: async (rule, value) => {
                                    if (value !== this.passwordFormRef.current.getFieldValue('new_password')) {
                                        throw new Error('must be same with new password!');
                                    }
                                },
                            },
                        ]}>
                            <Input.Password placeholder="Retype new Password"/>
                        </Form.Item>
                    </Form>
                </Modal>
            </PageContent>
        )
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

import http from './http';

class BookingService {

    list(offset, limit, query = {}) {
        return http.get(`/api/bookings`, {
            ...query,
            offset,
            limit
        });
    }

    get(id) {
        return http.get(`/api/bookings/${id}`);
    }

    update(id, params) {
        return http.put(`/api/bookings/${id}`, params);
    }

    create(params) {
        return http.post(`/api/bookings`, params);
    }

    cancel(id) {
        return http.delete(`/api/bookings/${id}/cancel`);
    }

    delete(id) {
        return http.delete(`/api/bookings/${id}/delete`);
    }
}

export default new BookingService();

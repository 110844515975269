export const formLayoutColInput = {
    labelCol: {
        xs: {span: 24},
        md: {span: 6},
        xxl: {span: 4}
    },
    wrapperCol: {
        xs: {span: 24},
        md: {span: 12},
        xxl: {span: 12}
    },
    labelAlign: 'left',
    colon: false
}

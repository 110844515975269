import React, {PureComponent} from 'react';
import {Drawer, Layout} from "antd";

import LOGO from '../../assets/images/logo-long-with-name.png';

import Header from './header';
import Footer from './footer';
import Menus from './menus';

import './style.css';
import Session from "./session";

export default function (AppContent, props) {

    class AppLayout extends PureComponent {

        lastResize = null;
        state = {
            width: 0,
            height: 0,
            mode: 'desktop',
            collapsed: false,
        };

        updateDimensions = () => {
            const mode = window.innerWidth > 1087 ? 'desktop' : 'mobile';
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,
                mode: mode,
            });
        };

        windowResizeListener = () => {
            const now = new Date();
            // prevent window resize too fast (250ms buffer time)
            if (!this.lastResize || (now.getTime() - this.lastResize) > 250) {
                this.updateDimensions();
                this.lastResize = now;
            }
        };

        componentDidMount = () => {
            this.updateDimensions();
            // window.addEventListener('resize', this.windowResizeListener);
        };

        componentWillUnmount = () => {
            // window.removeEventListener('resize', this.windowResizeListener);
        };

        onActionToggleSlider = () => {
            this.setState({
                collapsed: !this.state.collapsed,
            });
        };

        renderSider = () => {
            const {collapsed, width} = this.state;
            return width > 992 ? (
                <Layout.Sider
                    className="sider"
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    theme="light"
                >
                    <div className="sider-head">
                        {!collapsed && <img src={LOGO} className="full-menu-logo" alt='logo'/>}
                        {collapsed && <img src={LOGO} className="collapsed-menu-logo" alt='logo'/>}
                    </div>
                    <Menus/>
                </Layout.Sider>
            ) : (
                <Drawer
                    className="dashboard-drawer"
                    placement="left"
                    closable={false}
                    onClose={this.onActionToggleSlider}
                    visible={collapsed}
                >
                    <div className="logo text-center">
                        <img src={LOGO} className="full-menu-logo" alt='logo'/>
                    </div>
                    <Menus onMenuClick={this.onActionToggleSlider}/>
                </Drawer>
            )
        }

        render = () => {
            const {collapsed} = this.state;
            return <Layout>

                {<Session url={'/login'} firstTimeLoginUrl={'/first-time-login'}/>}
                {this.renderSider()}

                <Layout>
                    <Header collapsed={collapsed} onMenuIconClick={this.onActionToggleSlider}/>
                    <Layout.Content className="content">
                        <div className="inner-content">
                            <AppContent {...this.props} {...props}/>
                        </div>
                    </Layout.Content>
                    <Footer/>
                </Layout>
            </Layout>;
        }
    }

    return AppLayout;
}

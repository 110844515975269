import { Button, Col, Row, Space, Table } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { api, ui } from '../../services';
import PageContent from "@src/components/page-content";
import formatter from "@src/helpers/formatter";


export const DeviceLogs = () => {

    const {id} = useParams();

    const [device, setDevice] = useState();


    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlers = {
        refresh: () => {
            if (id) {
                setLoading(true);
                api.device.logs.list(id).then(rs => {
                    setRows(rs);
                    setLoading(false);
                }).catch(err => {
                    ui.notify.error(err);
                    setLoading(false);
                });
            }
        },
        download: (filename) => {
            ui.confirm(`Are you sure to download the log file?`, () => {
                setLoading(true);
                api.device.logs.download(id, filename).then(rs => {
                    ui.notify.success(`Downloaded`);
                    handlers.refresh();
                }).catch(err => {
                    ui.notify.error(err);
                    setLoading(false);
                })
            })
        },
        delete: (filename) => {
            ui.confirm(`Are you sure to delete the log file? This action cannot be undone.`, () => {
                setLoading(true);
                api.device.logs.delete(id, filename).then(rs => {
                    ui.notify.success(`Removed`);
                    handlers.refresh();
                }).catch(err => {
                    ui.notify.error(err);
                    setLoading(false);
                })
            })
        }
    }

    const columns = [
        {
            dataIndex: 'filename',
            title: 'Filename'
        },
        {
            dataIndex: 'size',
            title: 'Size',
            width: 80,
            render: (size) => {
                if (!size) {
                    return size;
                }
                const kb = 1024;
                const mb = 1024 * 1024;
                const gb = 1024 * 1024 * 1024;
                if (size > gb) {
                    return Number(size / gb).toFixed(2) + ' GB';
                } else if (size > mb) {
                    return Number(size / mb).toFixed(2) + ' MB';
                } else if (size > kb) {
                    return Number(size / kb).toFixed(2) + ' KB';
                }
                return `${size} bytes`
            }
        },
        {
            dataIndex: 'updatedAt',
            title: 'Updated Date',
            width: 160,
            render: formatter.toDisplayDatetime,
        },
        {
            dataIndex: 'filename',
            title: 'Actions',
            align: 'right',
            width: 120,
            render: (filename) => {
                return <Space size={2}>
                    <Button type="link" icon={<DownloadOutlined/>} title="Download"
                            onClick={() => handlers.download(filename)}/>
                    <Button danger type="link" icon={<DeleteOutlined/>} title="Delete"
                            onClick={() => handlers.delete(filename)}/>
                </Space>
            }
        },
    ];

    useEffect(() => {
        api.device.get(id).then(d => {
            setDevice(d);
        }).catch(err => {
            ui.notify.error(err)
        }).finally(() => {
            handlers.refresh();
        })
    }, [id]);

    return <PageContent hasBack={true} title="Device Logs" subTitle={device ? `${device?.hostname} (${device?.ip})` : ''}>
        <Row>
            <Col span={24}>
                <Table
                    bordered
                    rowKey={'filename'}
                    columns={columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={false}
                />
            </Col>
        </Row>
    </PageContent>
}

import React from "react";

import BG from '../../assets/images/errors/bg1.jpg';
import {Button, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import history from "../../routes/history";

export default class Error404 extends React.PureComponent {
    render() {
        return <div style={{backgroundImage: `url(${BG})`, height: '100vh'}}>
            <div style={{padding: 128}}>
                <Typography.Title level={1} style={{fontSize: "150px", marginBottom: 16}}>
                    404
                </Typography.Title>
                <p style={{fontSize: '1.5rem', fontWeight: 300}}>
                    OOPS! This page doesn't exist.
                </p>

                <Button title="Back" type="link" icon={<ArrowLeftOutlined/>} onClick={() => history.goBack()}>
                    Back
                </Button>
            </div>
        </div>
    }
}

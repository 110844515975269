import React from "react";
import * as moment from 'moment-timezone';
import CommonDispatcher from '../../store/helpers/commons-dispatcher';

import {reportService, fileService} from '../../services';

import {connect} from "react-redux";
import formatter from "../../helpers/formatter";
import {EVENT_TYPE, REPORT_TARGET_TYPES} from "../../constants";
import {
    Tag,
    Button,
    Card,
    Col,
    Form,
    Input,
    Row,
    Select,
    Space,
    Table,
    DatePicker
} from "antd";
import PageContent from "../../components/page-content";
import {
    EditOutlined,
    DeleteOutlined,
    FileOutlined,
    PlayCircleOutlined,
    FilterOutlined,
    ReloadOutlined,
    UpOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";

class Container extends React.PureComponent {

    now = moment();

    columns = [
        {
            dataIndex: ['name'],
            title: 'Name',
        },
        {
            dataIndex: ['type'],
            title: 'Type',
            render: formatter.toDisplayReportType
        },
        {
            dataIndex: 'target_timestamp_type',
            title: 'Time',
            render: (v, r) => {
                const {target_timestamp_type, target_timespan, target_timestamps_from, target_timestamps_to} = r;
                if (target_timestamp_type === REPORT_TARGET_TYPES.TIMESTAMPS) {
                    if (target_timestamps_from && target_timestamps_to) {
                        return <Tag>
                            {formatter.toDisplayDate(target_timestamps_from)}
                            &nbsp;<ArrowRightOutlined/>&nbsp;
                            {formatter.toDisplayDate(target_timestamps_to)}
                        </Tag>
                    }
                } else if (target_timestamp_type === REPORT_TARGET_TYPES.TIMESPAN) {
                    return <Tag>{formatter.toDisplayReportTimeSpan(target_timespan)}</Tag>;
                }
                return '-';
            }
        },
        {
            dataIndex: 'last_run_at',
            title: 'Last Run',
            sorter: true,
            render: formatter.toDisplayDatetime
        },
        {
            dataIndex: ['scheduler_is_enable'],
            title: 'Scheduled',
            align: 'center',
            width: 100,
            render: (v) => v ?
                <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>
        },
        {
            dataIndex: ['id'],
            title: 'Actions',
            align: 'right',
            width: 120,
            render: (id, r) => <Space size={4}>
                <Button disabled={!r.file} title="View" type="link" icon={<FileOutlined/>}
                        onClick={() => this.view(r)}/>
                <Button title="Run" type="link" icon={<PlayCircleOutlined/>} onClick={() => this.run(id)}/>

                <Link to={`/admin/reports/view/${id}`}>
                    <Button title="Edit" type="link" icon={<EditOutlined/>}/>
                </Link>
                <Button title="Delete" danger type="link" icon={<DeleteOutlined/>} onClick={() => this.delete(id)}/>
            </Space>
        },

    ];

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        showAdvanceFilter: true,

        sorter: null,
        rows: [],
        showing: {
            row: null,
            target: null
        }
    };

    filterRef = React.createRef();

    run = (id) => {
        this.props.confirm(`Are you sure to run the report now?`, () => {
            this.setState({loading: true});
            reportService.run(id).then(rs => {
                this.props.notification({message: 'Success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    }
    delete = (id) => {
        this.props.confirm(`Are you sure to delete the report? This action cannot be undone.`, () => {
            this.setState({loading: true});
            reportService.delete(id).then(rs => {
                this.props.notification({message: 'Success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    }
    view = (report) => {
        const {file, name} = report;
        this.props.confirm(`Are you sure to download the report now?`, () => {
            let filename;
            if (file.indexOf('.') > 0) {
                const ext = file.substring(file.lastIndexOf('.') + 1);
                filename = `${name}.${ext}`;
            } else {
                filename = name;
            }
            fileService.getReportFile(file, filename).then(() => {
                this.props.notification({message: 'Downloaded'});
            }).catch(err => {
                this.props.notification(err);
            })
        });
    }

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const query = {};

        if (sorter) {
            query.sort = `${sorter.order === 'descend' ? '-' : '+'}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        reportService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, pagination, sorter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    renderFilter = () => {
        const {showAdvanceFilter} = this.state;

        const initialValues = {
            filter_type: 'person_name',
            keyword: '',
            timestamps: [
                moment().startOf('month'),
                moment().endOf('month')
            ],
            types: []
        }

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Card>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={6}>
                                <Form.Item label="Filter by" name="filter_type">
                                    <Select>
                                        <Select.Option value="person_name">Name</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item label=" " name="keyword">
                                    <Input allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label=" ">
                                    <Button icon={showAdvanceFilter ? <UpOutlined/> : <FilterOutlined/>}
                                            onClick={() => this.setState({showAdvanceFilter: !showAdvanceFilter})}/>

                                    <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {showAdvanceFilter && <Row>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item label="Date Time" name="timestamps">
                                    <DatePicker.RangePicker
                                        style={{width: '100%'}}
                                        ranges={{
                                            Today: [
                                                moment().startOf('day'),
                                                moment().endOf('day')
                                            ],
                                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                                            'Last Week': [
                                                moment().subtract(1, 'week').startOf('week'),
                                                moment().subtract(1, 'week').endOf('week')
                                            ],
                                            'This Month': [
                                                moment().startOf('month'),
                                                moment().endOf('month')
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month'),
                                                moment().subtract(1, 'month').endOf('month')
                                            ],
                                        }}
                                        showTime
                                        format="YYYY/MM/DD HH:mm:ss"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Type" name="types">
                                    <Select allowClear mode="multiple">
                                        {Object.keys(EVENT_TYPE).map(k => {
                                            return <Select.Option
                                                value={EVENT_TYPE[k]}>{formatter.toDisplayEventType(EVENT_TYPE[k])}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
            </Card>
        </Form>
    }

    render() {
        const {pagination, rows, loading} = this.state;

        const extra = <Button type="primary">
            <Link to={'/admin/reports/view/create'}>
                Add
            </Link>
        </Button>

        return <PageContent title="Reports" extra={extra}>
            <Space direction="vertical" size={16} style={{width: '100%'}} className="overflow-x">
                <Table
                    bordered
                    rowKey={'id'}
                    columns={this.columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                />
            </Space>
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);

import React from "react";
import {connect} from "react-redux";

import {advertisementService, fileService, deviceService} from '../../services';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import PageContent from "../../components/page-content";
import history from "../../routes/history";
import AdsFormScreen from '../../screens/ads/form';

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        ads: null,
        devices: []
    };

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = async () => {
        const api = [
            deviceService.list()
        ]

        if (this.id) {
            api.push(advertisementService.get(this.id));
        }

        this.setState({loading: true});
        await Promise.all(api).then(async rs => {
            const ads = rs.length > 1 ? rs[1] : null;
            if (ads) {
                ads.images = await Promise.all(ads.images.map(async img => {
                    img.base64 = await fileService.getAdsImgAsBse64(img.id)
                    return img;
                }))
            }

            this.setState({
                devices: rs[0].items,
                ads,
                loading: false,
                init_done: true
            });
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false, init_done: true})
        });
    };

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? advertisementService.create(values) : advertisementService.update(this.id, values)).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    history.goBack();
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    render() {
        const {init_done, loading, ads, devices} = this.state;

        const title = this.isCreate ? 'Add Advertisement' : 'Edit Advertisement';

        return <PageContent title={title} hasBack={true} loading={loading}>
            {init_done && <AdsFormScreen ads={ads} devices={devices} onSubmit={this.submit}/>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh().finally();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

import React, {PureComponent} from 'react';
import {Layout, Row, Col, Typography} from 'antd';

import {APP} from '../../constants';

export default class LayoutFooter extends PureComponent {
    render() {
        const year = new Date().getFullYear();
        const compLink = APP.APP_POWERED_BY_WEBSITE ?
            <a style={{color: 'black'}} href={APP.APP_POWERED_BY_WEBSITE} rel="noopener noreferrer" target="_blank">
                {APP.APP_POWERED_BY}
            </a> : APP.APP_POWERED_BY

        const footerText = (word) => <Typography.Text style={{opacity: 0.5, fontSize: 12}}>{word}</Typography.Text>

        return (
            <Layout>
                <Layout.Footer>
                    <Row>
                        <Col span={2}>
                            &nbsp;
                        </Col>
                        <Col span={20} style={{textAlign: 'center'}}>
                            {footerText(<>{compLink}&copy;{year}</>)}
                        </Col>
                        <Col span={2} style={{textAlign: 'right'}}>
                            {footerText(APP.VERSION)}
                        </Col>
                    </Row>
                </Layout.Footer>
            </Layout>
        );
    }
}

import http from './http';

class IntegrationService {

    getGoogleOAuthUrl() {
        return http.get(`/api/integrations/google-oauth/url`);
    }

    authorizeGoogle(token) {
        return http.post(`/api/integrations/google-oauth/authorize`, {token});
    }
}

export default new IntegrationService();

import React from "react";

import { facilityService } from '../../services';
import { connect } from "react-redux";
import { Button, Col, Form, Input, Row, Select, Space, Table, Tag } from "antd";
import PageContent from "../../components/page-content";
import { DeleteOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import CommonDispatcher from '../../store/helpers/commons-dispatcher';
import { Link } from "react-router-dom";
import { CommonProps } from "@src/store/helpers";
import { USER_TYPES } from "@src/constants";

class Container extends React.PureComponent {

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        rows: []
    };

    filterRef = React.createRef();

    doDelete(id) {
        this.props.confirm(`All rooms added with this facility will auto remove as well. Are you sure to delete this facility? This action cannot be undone.`, () => {
            this.setState({loading: true});
            facilityService.delete(id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    }

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const filter = this.filterRef.current.getFieldsValue();

        const {filter_type, keyword} = filter;

        const query = {};

        if (keyword) {
            query[filter_type] = keyword;
        }

        if (sorter) {
            query.sort = `${sorter.order === 'descend' ? '-' : '+'}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        if (this.props.types) {
            query.types = this.props.types
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        facilityService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, pagination, sorter, filter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    renderFilter = () => {

        const initialValues = {
            filter_type: 'name',
            keyword: ''
        }

        const width = window.innerWidth

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row>
                {width >= 600 ?
                    <Col span={12}>
                        <Row gutter={[8, 8]}>
                            <Col span={6}>
                                <Form.Item label="Filter by" name="filter_type">
                                    <Select>
                                        <Select.Option value="name">Name</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label=" " name="keyword">
                                    <Input allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label=" ">
                                    <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Filter by" name="filter_type">
                                    <Select>
                                        <Select.Option value="name">Name</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="keyword">
                                    <Input allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
        </Form>
    }

    render() {
        const {auth} = this.props;
        const {pagination, loading, rows} = this.state;

        const extra = <>
            {auth?.type === USER_TYPES.SUPER_ADMIN && <Link to="/admin/facilities/create">
                <Button type="primary">
                    Add
                </Button>
            </Link>}
        </>

        const columns = [
            {
                dataIndex: ['name'],
                sorter: true,
                title: 'Name'
            },
            {
                dataIndex: ['is_active'],
                sorter: true,
                title: 'Status',
                align: 'center',
                width: 120,
                render: is_active => <Tag color={is_active ? 'success' : 'error'}>{is_active ? 'Active' : 'Disabled'}</Tag>
            },
            {
                dataIndex: 'icon',
                title: 'Icon',
                align: 'center',
                width: 100,
                render: (icon) => icon ? <img src={`${icon}`} alt="avatar" style={{width: 64, padding: 8}}/> : ''
            },
            ...(auth?.type === USER_TYPES.SUPER_ADMIN ? [
                {
                    dataIndex: ['id'],
                    title: 'Actions',
                    align: 'right',
                    width: 100,
                    render: (id) => {
                        return <Space size={2}>
                            <Link to={`/admin/facilities/${id}`}>
                                <Button type="link" icon={<EditOutlined/>}/>
                            </Link>

                            <Button danger type="link" icon={<DeleteOutlined/>} onClick={() => this.doDelete(id)}/>
                        </Space>
                    }
                }
            ] : [])
        ];


        return <PageContent title="Facilities" loading={loading} extra={extra}>
            <Space direction="vertical" size={16} style={{width: '100%'}}>
                {this.renderFilter()}
                <Table
                    bordered
                    rowKey={'id'}
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                    columns={columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                />
            </Space>
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

import React from "react";
import LOGO from "../../assets/images/logo-long.png";
import {Col, Result, Row, Space} from "antd";
import PropTypes from "prop-types";


export default class Error500 extends React.PureComponent {

    static propTypes = {
        status: PropTypes.string,
        title: PropTypes.string,
        error: PropTypes.object,
    };

    static defaultProps = {
        status: 'warning'
    };

    render() {
        const {error, title, status} = this.props;

        return <Row justify="center" align="middle" style={{height: '100vh'}}>
            <Col span={12} style={{height: '70vh'}}>
                <Row justify="center" gutter={[16, 16]}>
                    <Col span={24}>
                        <Space size={2} style={{width: '100%', textAlign: 'center'}} direction="vertical">
                            <img alt="Logo" style={{width: '80%', padding: 16}} src={LOGO}/>
                        </Space>
                    </Col>

                    <Col md={24}>
                        <Result
                            status={status}
                            title={title ? title : error?.response?.status}
                            subTitle={error?.message}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    }
}

import {BOOKING_STATUS} from "../constants";

class Colors {

    getBookingColor(bookingStatus) {
        const status = typeof bookingStatus === "string" ? [bookingStatus] : bookingStatus;
        let color = '#ed1475';
        if (status?.includes(BOOKING_STATUS.CANCELLED)) {
            color = '#727272';
        } else if (status?.includes(BOOKING_STATUS.ENDED)) {
            color = '#1784cd';
        } else if (status?.includes(BOOKING_STATUS.NEW)) {
            color = '#87c883';
        } else if (status?.includes(BOOKING_STATUS.ONGOING)) {
            color = '#ed1475';
        }
        return color;
    }
}

export default new Colors();

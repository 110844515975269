import React from 'react';
import {Button, Input} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CommonProps, CommonDispatcher} from '../../store/helpers';
import {GoogleOutlined} from '@ant-design/icons';

import GoogleLogin from "react-google-login";
import GoogleLogoutButton from "./google-logout-button";

class GoogleLoginButton extends React.Component {

    static propTypes = {
        clientId: PropTypes.string,
        autoLogin: PropTypes.bool,
        style: PropTypes.object,
        label: PropTypes.string,
        icon: PropTypes.node,
        onAuthenticated: PropTypes.func
    };

    static defaultProps = {
        icon: <GoogleOutlined/>,
        autoLogin: false,
        label: 'Sign in with Google',
        loading: false,
        onAuthenticated: () => console.warn('not handled authenticated callback')
    };

    state = {
        payload: null
    };

    button(label, loading = false, onClick) {
        const {icon, style} = this.props;
        return <Button icon={icon} onClick={onClick} type="primary" style={style} loading={loading}>
            {label}
        </Button>
    }

    render() {
        const {label, style, icon, autoLogin, onAuthenticated, clientId} = this.props;
        const {payload} = this.state;

        return <Input.Group compact>
            <GoogleLogin
                isSignedIn={true}
                clientId={clientId}
                buttonText={label}
                render={renderProps => (
                    <Button icon={icon} htmlType="button" type="primary" style={style}
                            onClick={() => {
                                if (payload) {
                                    onAuthenticated(payload);
                                } else {
                                    renderProps.onClick();
                                }
                            }} disabled={renderProps.disabled}>
                        {payload ? `Continue with ${payload.profileObj?.name}` : label}
                    </Button>
                )}
                onSuccess={(payload) => {
                    // console.log(payload);
                    this.setState({payload})
                    if (autoLogin) {
                        onAuthenticated(payload);
                    }
                }}
                onFailure={(response, details) => {
                    let message;
                    switch (response.error) {
                        case 'access_denied':
                            message = 'User permission not allowed';
                            break;
                        case 'popup_closed_by_user':
                            message = 'Popup closed before sign in';
                            break;
                        case 'idpiframe_initialization_failed':
                            message = response.details;
                            break;
                        default:
                            message = `${response.details} (${response.error})`;
                    }
                    // console.log(response);

                    this.props.notification({message, type: 'error'});
                    this.setState({loading: false});
                }}
                onRequest={(req) => {
                    this.setState({loading: true});
                }}
                cookiePolicy={'single_host_origin'}
            />
            {payload && <GoogleLogoutButton clientId={clientId} afterLogout={() => {
                this.setState({payload: null})
                this.props.sessionExpired();
            }}/>}
        </Input.Group>
    }
}

export default connect(CommonProps, CommonDispatcher)(GoogleLoginButton);

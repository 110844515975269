export const SPLASH_SCREEN = 'SPLASH_SCREEN';
export const GLOBAL_LOADING = 'GLOBAL_LOADING';
export const NOTIFICATION = 'NOTIFICATION';
export const MESSAGE_DIALOG = 'MESSAGE_DIALOG';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';

export const CHECK_SESSION = 'CHECK_SESSION';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const GET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';

export function SplashScreen(show) {
    return {
        type: SPLASH_SCREEN,
        payload: {show},
    };
}

export function Loading(show) {
    return {
        type: GLOBAL_LOADING,
        payload: {show},
    };
}

export function Notification({title, message, type = 'info', show = true}) {
    return {
        type: NOTIFICATION,
        payload: {
            title, message, type, show,
        },
    };
}

export function ConfirmDialog({message, onYes, onNo = null, title = 'Confirm', show = true}) {
    return {
        type: CONFIRM_DIALOG,
        payload: {
            title, message, onYes, onNo, show,
        },
    };
}

export function MessageDialog({title = 'Message', message, type = 'info', show = true, onOkay = null}) {
    return {
        type: MESSAGE_DIALOG,
        payload: {
            title, message, type, show, onOkay,
        },
    };
}

export function CheckUserSession() {
    return {type: CHECK_SESSION, payload: {}};
}

export function SetUserSession(payload) {
    return {type: SET_USER_SESSION, payload};
}

export function SessionExpired({redirect}) {
    return {type: SESSION_EXPIRED, payload: {redirect}};
}

export function SetGeneralSettings(payload) {
    return {type: SET_GENERAL_SETTINGS, payload};
}

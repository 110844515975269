import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { ConfidentialClientApplication, PublicClientApplication } from "@azure/msal-node";

export const SsoMsalAuthProvider = (clientId, tenantId = null) => {
    const auth = { clientId };
    if (tenantId) {
        auth.authority = `https://login.microsoftonline.com/${tenantId}`;
    }
    return new MsalAuthProvider(
        {
            auth,
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            },
        },
        {
            scopes: ["https://graph.microsoft.com/user.read"],
        },
        {
            loginType: LoginType.Redirect,
        }
    );
};

export const RoomMsalAuthProvider = (clientId, tenantId = null, authority = `https://login.microsoftonline.com`) => {
    const auth = {
        clientId,
        tenantId,
        authority: authority ? authority : `https://login.microsoftonline.com`,
    };
    if (tenantId) {
        auth.authority = `${auth.authority}/${tenantId}`;
    }
    return new PublicClientApplication({ auth });
};

import React from "react";
import PropTypes from "prop-types";
import {Space, Descriptions} from "antd";
import {Tag} from "antd";
import formatter from "../../helpers/formatter";

export default class DeviceStatusScreen extends React.PureComponent {

    static propTypes = {
        device: PropTypes.object,
    };

    render() {
        const {device} = this.props;
        /*- connectivity (api)
        - battery level (api)
        - android id (send during device registration via api)
        - able to bind / update room (api) - one room can bind to multiple devices
        - device signal (api)*/
        const descriptionProps = {
            size: 'small',
            bordered: true,
            column: 1
        }

        return <Space direction="vertical" size={16} style={{width: '100%'}}>
            <Descriptions title="Information" {...descriptionProps}>
                <Descriptions.Item label="Name">
                    {device?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                    {formatter.toDisplayDeviceType(device?.type)}
                </Descriptions.Item>
                <Descriptions.Item label="Hostname">
                    {device?.hostname}
                </Descriptions.Item>
                <Descriptions.Item label="Serial No.">
                    {device?.serial}
                </Descriptions.Item>
                <Descriptions.Item label="IP Address">
                    {device?.ip}
                </Descriptions.Item>
                <Descriptions.Item label="MAC Address">
                    {device?.mac}
                </Descriptions.Item>
                <Descriptions.Item label="Firmware">
                    {device?.firmware}
                </Descriptions.Item>

                <Descriptions.Item label="Registered Date">
                    {formatter.toDisplayDatetime(device?.created_at)}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Status" {...descriptionProps}>
                <Descriptions.Item label="Status">
                    <Tag color={device?.is_up ? 'success' : 'error'}>
                        {device?.is_up ? 'Up' : 'Down'}
                    </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Wifi Signal">
                    {device?.wifi_signals !== null ? `${device?.wifi_signals}dbm` : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Battery">
                    {device?.battery !== null ? `${device?.battery} %` : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Memory">
                    {device?.memory_free && device?.memory_total && <>
                        {device?.memory_free}/{device?.memory_total}
                    </>}
                </Descriptions.Item>

                <Descriptions.Item label="Storage">
                    {device?.storage_free && device?.storage_total && <>
                        {device?.storage_free}/{device?.storage_total}
                    </>}
                </Descriptions.Item>

                <Descriptions.Item label={formatter.toDisplayRoomType(device?.room?.type)}>
                    {device?.room ? device?.room?.name : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Last Update">
                    {formatter.toDisplayDatetime(device?.last_heartbeat)}
                </Descriptions.Item>
            </Descriptions>
        </Space>
    }
}

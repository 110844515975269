import React from "react";
import {connect} from "react-redux";

import {facilityService} from '../../services';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import PageContent from "../../components/page-content";
import history from "../../routes/history";
import FacilityFormScreen from '../../screens/facility/form';

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        facility: null,
        rooms: []
    };

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = () => {
        if (this.id) {
            this.setState({loading: true});
            facilityService.get(this.id).then(rs => {
                this.setState({
                    facility: rs,
                    loading: false,
                    init_done: true
                });
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false, init_done: true})
            });
        } else {
            this.setState({init_done: true})
        }
    };

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? facilityService.create(values) : facilityService.update(this.id, values)).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    history.goBack();
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    render() {
        const {init_done, loading, facility} = this.state;

        const title = this.isCreate ? 'Add Facility' : 'Edit Facility';

        return <PageContent title={title} hasBack={true} loading={loading}>
            {init_done && <FacilityFormScreen facility={facility} onSubmit={this.submit}/>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

import React from "react";
import PropTypes from "prop-types";
import {
    Switch,
    Button,
    Col,
    Form,
    Input,
    DatePicker,
    Row,
    Select,
    Radio
} from "antd";

import formatter from "../../helpers/formatter";
import {connect} from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import moment from 'moment';
import {REPORT_TARGET_TYPES, REPORT_TIMESPAN_TYPES, REPORT_TYPES, SCHEDULER_TYPES} from "../../constants";

class Screen extends React.PureComponent {

    static propTypes = {
        report: PropTypes.object,
        onSubmit: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: (v) => console.warn('action not register', v),
    };

    state = {
        current_target_type: REPORT_TARGET_TYPES.TIMESPAN,
        current_scheduler_is_enable: false,
        current_scheduler_type: null
    }

    formRef = React.createRef();

    constructor(props) {
        super(props);

        const {report} = this.props;
        if (report) {
            this.state = {
                current_target_type: report.target_timestamp_type,
                current_scheduler_is_enable: report.scheduler_is_enable,
                current_scheduler_type: report.scheduler_type
            }
        }
    }

    handleSubmit = (form) => {

        if (form.target_timestamps && form.target_timestamps.length >= 2) {
            form.target_timestamps_from = moment(form.target_timestamps[0]).toISOString();
            form.target_timestamps_to = moment(form.target_timestamps[1]).toISOString();
        }

        if (form.scheduler_start) {
            form.scheduler_start = moment(form.scheduler_start).toISOString();
        }

        delete form.target_timestamps;

        this.props.onSubmit(form);
    }

    renderSchedulerForm = () => {
        const {current_scheduler_type} = this.state;

        const dates = [];
        for (let d = 1; d <= 31; d++) {
            dates.push(d);
        }

        const weeks = [];
        for (let d = 0; d < 7; d++) {
            weeks.push({value: d, label: moment().day(d).format('dddd')});
        }

        return <>
            <Form.Item label=" " name="scheduler_type" rules={[{
                required: true,
                message: 'Please select the scheduler type',
            }]} required={false}>
                <Radio.Group>
                    {Object.values(SCHEDULER_TYPES).map(v => <Radio key={v} value={v}>
                        {v.charAt(0).toUpperCase() + v.slice(1)}
                    </Radio>)}
                </Radio.Group>
            </Form.Item>

            <Form.Item label="Start" name="scheduler_start" rules={[{
                required: true,
                message: 'Please select the scheduler start time',
            }]}>
                <DatePicker style={{width: '100%'}} showTime={[
                    SCHEDULER_TYPES.ONCE,
                    SCHEDULER_TYPES.HOURLY,
                ].includes(current_scheduler_type)}/>
            </Form.Item>

            {current_scheduler_type === SCHEDULER_TYPES.MONTHLY &&
            <Form.Item label="Dates" name="scheduler_dates" help="scheduler to run only on selected date(s)">
                <Select mode="multiple" style={{width: '100%'}} allowClear>
                    {dates.map(d => <Select.Option key={d} value={d}>{d}</Select.Option>)}
                </Select>
            </Form.Item>}

            {current_scheduler_type === SCHEDULER_TYPES.WEEKLY &&
            <Form.Item label="Days" name="scheduler_weeks" help="scheduler to run only on day(s)">
                <Select mode="multiple" style={{width: '100%'}} allowClear>
                    {weeks.map(d => <Select.Option key={d.value} value={d.value}>{d.label}</Select.Option>)}
                </Select>
            </Form.Item>}
        </>
    }

    render() {
        const {report} = this.props;
        const {current_target_type, current_scheduler_is_enable} = this.state;

        const initialValues = {
            name: report?.name,
            type: report?.type,
            target_timestamp_type: report?.target_timestamp_type,

            target_timestamps: (report?.target_timestamps_from && report?.target_timestamps_to) ? [
                moment(report.target_timestamps_from),
                moment(report.target_timestamps_to)
            ] : null,
            target_timespan: report?.target_timespan,
            emails: report?.emails ? report?.emails : [],

            scheduler_is_enable: report?.hasOwnProperty('scheduler_is_enable') ? report.scheduler_is_enable : false,
            scheduler_type: report?.scheduler_type,
            scheduler_start: report?.scheduler_start ? moment(report?.scheduler_start) : null,
            scheduler_dates: report?._scheduler_dates ? report?._scheduler_dates : [],
            scheduler_weeks: report?._scheduler_weeks ? report?._scheduler_weeks : [],
        }

        const onChange = (values) => {
            if (values.hasOwnProperty('target_timestamp_type')) {
                this.setState({current_target_type: values.target_timestamp_type})
            }

            if (values.hasOwnProperty('scheduler_is_enable')) {
                this.setState({current_scheduler_is_enable: values['scheduler_is_enable']})
            }

            if (values.hasOwnProperty('scheduler_type')) {
                this.setState({current_scheduler_type: values['scheduler_type']})
            }
        };

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            colon: false
        };


        return <Form {...formItemLayout} initialValues={initialValues} onValuesChange={onChange}
                     onFinish={this.handleSubmit} ref={this.formRef}>

            <Row>
                <Col span={24}>
                    <Form.Item label="Name" name="name" rules={[{
                        required: true,
                        message: 'Name is require',
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Type" name="type" rules={[{
                        required: true,
                        message: 'Please select the report type',
                    }]}>
                        <Select style={{width: '100%'}}>
                            {Object.values(REPORT_TYPES).map(v => <Select.Option key={v} value={v}>
                                {formatter.toDisplayReportType(v)}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Datetime" name="target_timestamp_type" rules={[{
                        required: true,
                        message: 'Please select the target timestamp type',
                    }]}>
                        <Radio.Group>
                            {Object.values(REPORT_TARGET_TYPES).map(v => <Radio key={v} value={v}>
                                {formatter.toDisplayReportTargetTimestampType(v)}
                            </Radio>)}
                        </Radio.Group>
                    </Form.Item>

                    {current_target_type === REPORT_TARGET_TYPES.TIMESTAMPS &&
                    <Form.Item label=" " colon={false} required={false} name="target_timestamps" rules={[{
                        required: true,
                        message: 'Please input the time',
                    }]}>
                        <DatePicker.RangePicker
                            style={{width: '100%'}}
                            ranges={{
                                Today: [
                                    moment().startOf('day'),
                                    moment().endOf('day')
                                ],
                                Yesterday: [
                                    moment().subtract(1, 'day').startOf('day'),
                                    moment().subtract(1, 'day').endOf('day')
                                ],
                                'Last Week': [
                                    moment().subtract(1, 'week').startOf('week'),
                                    moment().subtract(1, 'week').endOf('week')
                                ],
                                'Last Month': [
                                    moment().subtract(1, 'month').startOf('month'),
                                    moment().subtract(1, 'month').endOf('month')
                                ],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}/>
                    </Form.Item>}

                    {current_target_type === REPORT_TARGET_TYPES.TIMESPAN &&
                    <Form.Item label=" " colon={false} required={false} name="target_timespan" rules={[{
                        required: true,
                        message: 'Please select the time span',
                    }]}>
                        <Select style={{width: '100%'}}>
                            {Object.values(REPORT_TIMESPAN_TYPES).map(v => <Select.Option key={v} value={v}>
                                {formatter.toDisplayReportTimeSpan(v)}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>}

                </Col>

                <Col span={24}>
                    <Form.Item label="Scheduler" name="scheduler_is_enable" valuePropName="checked">
                        <Switch/>
                    </Form.Item>

                    {current_scheduler_is_enable && this.renderSchedulerForm()}
                </Col>
            </Row>

            <Row>
                <Col span={3}>&nbsp;</Col>
                <Col>
                    <Button htmlType="submit" type="primary">
                        {report ? 'Update' : 'Submit'}
                    </Button>
                </Col>
            </Row>
        </Form>
    }
}

export default connect(null, CommonDispatcher)(Screen);

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Space } from "antd";
import * as moment from "moment";

import formatter from "@src/helpers/formatter";
import { roomService } from "@src/services";

const RoomTimeSlots = ({onChange, timeSlots, date, value, disableBefore, disableAfter}) => {

    const disableBeforeDate = disableBefore ? moment(disableBefore) : null;
    const disableAfterDate = disableAfter ? moment(disableAfter) : null;

    // sample
    /*let cols = [];
    for (let i = 1; i <= 24; i++) {
        const d = moment(date).hour(i).startOf('hour');
        const selected = moment(d).isSame(moment(value));

        let disabled = false;
        if (!disabled && disableBeforeDate) {
            disabled = d.isSameOrBefore(disableBeforeDate);
        }

        cols.push(<Col key={i} span={6} style={{textAlign: "center"}}>
            <Button disabled={disabled}
                    type={selected ? "primary" : "default"}
                    title={moment(d).toISOString()}
                    onClick={() => {
                        if (onChange) {
                            onChange(d.toDate());
                        }
                    }}>
                <span style={{width: 100, height: 32}}>{moment(d).format("hh:mm a")}</span>
            </Button>
        </Col>);
    }*/
    const useSlots = [...timeSlots];
    const totalSlots = useSlots?.length;
    const leftOver = totalSlots > 4 ? (4 - totalSlots % 4) : 0;
    if (leftOver > 0) {
        for (let i = 0; i < leftOver; i++) {
            useSlots.push({is_placeholder: true})
        }
    }

    const slots = useSlots?.map((s, i) => {
        let content;
        if (!s.is_placeholder) {
            const d = moment(s.timestamp);
            const selected = d.isSame(moment(value));

            let disabled = !s.is_available;
            if (!disabled && disableBeforeDate) {
                disabled = d.isSameOrBefore(disableBeforeDate);
            }
            if (!disabled && disableAfterDate) {
                disabled = d.isSameOrAfter(disableAfterDate);
            }

            content = <Button disabled={disabled}
                              style={{width: 130}}
                              type={selected ? "primary" : "default"}
                              title={moment(d).format('YYYY-MM-DD hh:mm:ss')}
                              onClick={() => {
                                  if (onChange) {
                                      onChange(d.toDate());
                                  }
                              }}>
                {moment(d).add(1, 'second').format("hh:mm a")}
            </Button>;
        } else {
            content = <span>&nbsp;</span>
        }

        return <Col key={i} span={6} style={{textAlign: "center"}}>
            {content}
        </Col>
    });

    return <Row justify="space-between" align="middle" gutter={[0, 12]}>
        {slots}
    </Row>
}
const StartEndTimeSelect = ({disabled, selected, value, onClick}) => {

    const [displayValue, setDisplayValue] = useState(value);

    useEffect(() => {
        setDisplayValue(value ? formatter.toDisplayDatetime(moment(value).add(1, 'second')) : '');
    }, [value]);

    return <Input
        disabled={disabled}
        value={displayValue}
        readOnly={true}
        className={selected ? "selected-border" : ""}
        style={{textAlign: "center", cursor: "pointer"}}
        onClick={onClick}
    />
}

export const FormDateTime = ({
                                 bookingId,
                                 extra,
                                 show,
                                 name,
                                 room_id,
                                 control = {allow_next: false, allow_previous: false},
                                 initialValues,
                                 onPrevious
                             }) => {


    const ref = useRef(false);

    const [form] = Form.useForm();

    const [showTimeSlot, setShowTimeSlot] = useState({type: ''});
    const [formValues, setFormValues] = useState({
        ...initialValues,
        date: initialValues?.date ? moment(initialValues?.date) : moment(),
        _start: initialValues?.start ? moment(initialValues?.start) : null,
        _end: initialValues?.end ? moment(initialValues?.end) : null,
    });
    const [timeSlots, setTimeSlots] = useState({loading: false, error: "", start: [], end: []});

    const formProps = {
        form,
        name,
        layout: "vertical",
        initialValues: formValues,
        onValuesChange: (value, values) => {
            if (value.hasOwnProperty('date')) {
                form.setFieldsValue({...values, start: null, end: null, _start: null, _end: null});
            }
            if (value.hasOwnProperty('start')) {
                form.setFieldsValue({...values, _start: value.start});
            }
            if (value.hasOwnProperty('end')) {
                form.setFieldsValue({...values, _end: value.end});
            }
            setFormValues(values);
        },
        style: {display: show ? "block" : "none"},
    }

    const handlers = {
        loadSlots: () => {
            const date = formValues?.date;
            if (room_id && date) {
                setTimeSlots({...timeSlots, error: "", loading: true});
                roomService.getTimeslots(room_id, moment(date).startOf('day'), bookingId).then(rs => {
                    try {
                        const data = {start: [], end: []};
                        rs.slots.forEach(({start, end, is_available}) => {
                            data.start.push({
                                timestamp: moment(start),
                                is_available
                            })
                            data.end.push({
                                timestamp: moment(end),
                                is_available
                            })
                        });
                        setTimeSlots({...data, error: "", loading: false});
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    setTimeSlots({...timeSlots, error: err.message(), loading: false});
                });
            }
        }
    }

    useEffect(() => {
        if (ref.current) {
            handlers.loadSlots();
        }
    }, [room_id]);

    useEffect(() => {
        if (ref.current) {
            form.setFieldsValue({start: undefined, end: undefined});
            setFormValues({...formValues, _start: undefined, _end: undefined, start: undefined, end: undefined});
            handlers.loadSlots();
        }
    }, [formValues?.date]);

    useEffect(() => {
        handlers.loadSlots();
        ref.current = true;
    }, []);

    return <Form {...formProps}>
        <Form.Item label="Date" name="date" rules={[{
            required: true,
            message: "Please select the booking date",
        }]}>
            <DatePicker style={{width: "100%"}} disabledDate={(current) => {
                return current.isBefore(moment().subtract(1, 'day').endOf('day'));
            }}/>
        </Form.Item>

        <Row gutter={[16, 0]}>
            <Col span={12}>
                <Form.Item label="Start Time" name="_start" rules={[{
                    required: true,
                    message: "Please select the booking start time",
                }]}>
                    <StartEndTimeSelect
                        disabled={timeSlots?.loading}
                        selected={showTimeSlot?.type === "start"}
                        onClick={() => setShowTimeSlot({type: 'start'})}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="End Time" name="_end" rules={[{
                    required: true,
                    message: "Please select the booking end time",
                }]}>
                    <StartEndTimeSelect
                        disabled={timeSlots?.loading}
                        selected={showTimeSlot?.type === "end"}
                        onClick={() => setShowTimeSlot({type: 'end'})}
                    />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="start" hidden={showTimeSlot?.type !== "start"}>
                    <RoomTimeSlots
                        timeSlots={timeSlots?.start} date={formValues?.date}
                        disableAfter={formValues?.end}/>
                </Form.Item>
                <Form.Item name="end" hidden={showTimeSlot?.type !== "end"}>
                    <RoomTimeSlots
                        timeSlots={timeSlots?.end} date={formValues?.date}
                        disableBefore={formValues?.start}/>
                </Form.Item>
            </Col>
        </Row>


        <Form.Item style={{marginTop: 30, textAlign: "right"}}>
            <Space direction="horizontal" size={8}>
                {!!extra && <>{extra}&nbsp;</>}
                <Button htmlType="button" disabled={!control?.allow_previous} onClick={onPrevious}>
                    Previous
                </Button>
                <Button htmlType="submit" disabled={!control?.allow_next} type="primary">
                    Next
                </Button>
            </Space>
        </Form.Item>
    </Form>
};

import React from "react";
import {Card, Button, Form, Input, Switch, Space, InputNumber, Typography, Select} from "antd";

import {MAILER_TYPES, SETTING_KEYS} from '../../constants';
import PropTypes from "prop-types";
import {formLayoutColInput} from "./config";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";
import PermissionDeniedScreen from "../status/permission-denied.screen";
import {CommonProps} from "../../store/helpers";
import {connect} from "react-redux";

export const USING_KEYS = [
    SETTING_KEYS.NOTIFICATION.BOOKING.CANCELLED.ENABLE,
    SETTING_KEYS.NOTIFICATION.BOOKING.UPCOMING.ENABLE,
    SETTING_KEYS.NOTIFICATION.BOOKING.UPCOMING.BEFORE_MINUTES,

    SETTING_KEYS.NOTIFICATION.DEVICE.RECEIVER_EMAIL,
    SETTING_KEYS.NOTIFICATION.DEVICE.LOW_BATTERY.ENABLE,
    SETTING_KEYS.NOTIFICATION.DEVICE.LOW_BATTERY.THRESHOLD_PERCENT,
    SETTING_KEYS.NOTIFICATION.DEVICE.DOWN.ENABLE,
    SETTING_KEYS.NOTIFICATION.DEVICE.DOWN.THRESHOLD_MINUTES,
    SETTING_KEYS.NOTIFICATION.DEVICE.UP.ENABLE,

    SETTING_KEYS.NOTIFICATION.ROOM_FACILITY.RECEIVER_EMAIL,
    SETTING_KEYS.NOTIFICATION.BOOKING.ADMIN.EMAIL,
    SETTING_KEYS.NOTIFICATION.BOOKING.ADMIN.ENABLE
]

class NotificationForm extends React.PureComponent {

    static propTypes = {
        settings: PropTypes.object,
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        onSubmit: (v) => console.warn('action not register', v)
    };

    formRef = React.createRef();

    state = {
        selected_type: MAILER_TYPES.GENERIC,
        showTest: false
    }

    onFinish = (form) => {
        this.props.onSubmit(form);
    }

    render() {
        const {settings, auth} = this.props;

        const allowEditDevice = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_NOTIFICATION);

        if (!allowEditDevice) {
            return <PermissionDeniedScreen/>
        }

        const formItemLayout = {
            ...formLayoutColInput
        };


        const initialValues = {};

        USING_KEYS.forEach(k => {
            initialValues[k] = settings?.[k]
        });

        return <>
            <Form {...formItemLayout} ref={this.formRef} initialValues={initialValues} onFinish={this.onFinish}>
                <Space style={{width: '100%'}} direction="vertical" size={16}>
                    <Card title="Bookings" size="small">

                        <Typography.Title level={5} underline>Admin</Typography.Title>
                        <Form.Item label="Receiver email" name={SETTING_KEYS.NOTIFICATION.BOOKING.ADMIN.EMAIL}>
                            <Input type="email" allowClear/>
                        </Form.Item>
                        <Form.Item style={{minHeight: 72}} label={<div>
                            Send for
                            <br/>new/update/cancel booking
                            <br/>failed room calender sync
                        </div>} name={SETTING_KEYS.NOTIFICATION.BOOKING.ADMIN.ENABLE} valuePropName="checked">
                            <Switch/>
                        </Form.Item>

                        <Typography.Title level={5} underline>Other Users</Typography.Title>
                        <Form.Item
                            style={{minHeight: 72}}
                            label={<div>
                                Send reminder <br/><i>(to user who books only)</i>
                            </div>}
                            tooltip=""
                            name={SETTING_KEYS.NOTIFICATION.BOOKING.UPCOMING.ENABLE} valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item label="Send reminder before"
                                   name={SETTING_KEYS.NOTIFICATION.BOOKING.UPCOMING.BEFORE_MINUTES}>
                            <Select options={[5, 10, 15, 20, 25, 30, 45, 60].map(t => {
                                return {value: t, label: `${t} minutes`}
                            })}/>
                        </Form.Item>
                    </Card>

                    <Card title="Room" size="small">
                        <Form.Item
                            label="Receiver (Report Facility)"
                            name={SETTING_KEYS.NOTIFICATION.ROOM_FACILITY.RECEIVER_EMAIL}>
                            <Input allowClear type="email" autoComplete="no"/>
                        </Form.Item>
                    </Card>

                    <Card title="Device" size="small">
                        <Form.Item label="Receiver Email" name={SETTING_KEYS.NOTIFICATION.DEVICE.RECEIVER_EMAIL}>
                            <Input allowClear type="email" autoComplete="no"/>
                        </Form.Item>
                        <Form.Item label="Send device offline" name={SETTING_KEYS.NOTIFICATION.DEVICE.DOWN.ENABLE}
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item label="Send device online" name={SETTING_KEYS.NOTIFICATION.DEVICE.UP.ENABLE}
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>

                        <Form.Item label="Offline threshold (minutes)"
                                   name={SETTING_KEYS.NOTIFICATION.DEVICE.DOWN.THRESHOLD_MINUTES}>
                            <InputNumber min={1} max={3660}/>
                        </Form.Item>
                    </Card>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Space>
                    </Form.Item>
                </Space>
            </Form>
        </>
    }
}

export default connect(CommonProps, null)(NotificationForm);

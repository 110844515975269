import React from "react";
import {connect} from "react-redux";

import {reportService} from '../../services';
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import PageContent from "../../components/page-content";
import ReportForm from '../../screens/report/form';
import history from "../../routes/history";

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        person: null,
        init_done: false,
        select_access_control_levels: [],
        summary: null
    };

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = () => {
        if (this.id) {
            this.setState({loading: true});
            reportService.get(this.id).then(rs => {
                this.setState({report: rs, loading: false, init_done: true})
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false, init_done: true})
            });
        } else {
            this.setState({init_done: true})
        }
    };

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? reportService.create(values) : reportService.update(this.id, values)).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    history.goBack();
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    render() {
        const {report, init_done, loading} = this.state;

        const title = this.isCreate ? 'Add Report' : 'Edit Report';

        return <PageContent title={title} hasBack={true}>
            {init_done && <ReportForm report={report} onSubmit={this.submit} loading={loading}/>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);

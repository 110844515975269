import React from "react";

import {settingService} from '../../services';
import {connect} from "react-redux";
import {Descriptions, Modal, Tabs} from "antd";
import PageContent from "../../components/page-content";
import {CommonProps, CommonDispatcher} from '../../store/helpers';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import NotificationForm, {USING_KEYS as NOTIFICATION_KEYS} from '../../screens/settings/notification-form';
import DeviceForm, {USING_KEYS as DEVICE_KEYS} from "../../screens/settings/device-form";
import BookingForm, {USING_KEYS as BOOKING_KEYS} from "../../screens/settings/booking-form";
import SystemForm, {USING_KEYS as SYSTEM_KEYS} from "../../screens/settings/system-form";
import history from "../../routes/history";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";

class Container extends React.PureComponent {

    static propTypes = {
        section: PropTypes.string,
    };

    static defaultProps = {
        loading: false,
        select_access_control_levels: [],
        onSubmit: (v) => console.warn('action not register', v),
        onUpdateProfilePicture: (v) => console.warn('action not register', v)
    };

    state = {
        init_done: false,
        settings: null,
        ldap_result: null
    };

    refresh = () => {
        const {section} = this.props;

        let keys;
        switch (section) {
            case 'system':
                keys = SYSTEM_KEYS;
                break;
            case 'device':
                keys = DEVICE_KEYS;
                break;
            case 'notification':
                keys = NOTIFICATION_KEYS;
                break;
            case 'booking':
                keys = BOOKING_KEYS;
                break;
            default:
                keys = null;
        }

        if (keys) {
            this.setState({loading: true});
            settingService.get(keys).then(settings => {
                this.setState({settings, loading: false, init_done: true})
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        }
    };

    submit = (form, ask = true) => {
        const submit = () => {
            this.setState({loading: true});
            settingService.update(form).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        }

        if (ask) {
            this.props.confirm(`Are you sure to update the changes?`, () => {
                submit();
            })
        } else {
            submit();
        }
    }

    onUploadFile = (key, file) => {
        this.props.confirm(`Are you sure to upload the file?`, () => {
            this.setState({loading: true});
            settingService.upload(key, file, {name: file.name}).then(rs => {
                this.props.notification({message: 'Uploaded', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        })
    }

    onDelete(key) {
        this.props.confirm(`Are you sure to delete the settings?`, () => {
            this.setState({loading: true});
            settingService.delete(key).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    }

    onTestEmail = (settings) => {
        this.props.confirm(`Do you want to test send an email?`, () => {
            this.setState({loading: true});
            settingService.testSendEmail(settings).then(rs => {
                const {response} = rs.response ? rs.response : {};
                let message = 'Success';
                if (typeof response === 'string') {
                    message = response;
                } else if (typeof response === 'object' && response.message) {
                    message = response.message;
                }
                this.props.notification({message, type: 'success'});
                this.setState({loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    };

    onTestLdap = (settings) => {
        this.props.confirm(`Do you want to test LDAP connectivity?`, () => {
            this.setState({loading: true});
            settingService.testBindLdap(settings).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.setState({ldap_result: rs, loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    };

    render() {
        const {section, auth} = this.props;
        const {init_done, loading, settings, ldap_result} = this.state;

        const printObject = (obj) => {
            return Object.keys(obj).map(k => {
                const v = ldap_result[k];
                if (Array.isArray(v)) {
                    return <Descriptions.Item key={k} label={k}>
                        {v.map((str, i) => <li key={i}>{str}</li>)}
                    </Descriptions.Item>
                } else if (typeof v === "object") {
                    return printObject(v);
                } else {
                    return <Descriptions.Item key={k} label={k}>{v}</Descriptions.Item>
                }
            })
        };

        const allowEditDevice = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_DEVICE);
        const allowEditNotification = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_NOTIFICATION);

        return <PageContent title="Settings" loading={loading}>
            {init_done && <Tabs activeKey={section} style={{padding: 0}} onChange={(v) => {
                history.replace(`/admin/system/settings/${v}`);
            }}>
                <Tabs.TabPane tab={<Link replace to={`/admin/system/settings/system`}>System</Link>} key="system">
                    <SystemForm
                        settings={settings}
                        onSubmit={this.submit}
                        onTestLdap={this.onTestLdap}
                        onTestEmail={this.onTestEmail}
                    />

                    <Modal title="Test LDAP Response" visible={!!ldap_result}
                           destroyOnClose={true}
                           okButtonProps={{hidden: true}} cancelText="Close"
                           onCancel={() => this.setState({ldap_result: null})}>
                        <Descriptions bordered colon={false} column={1} size="small">
                            {ldap_result && printObject(ldap_result)}
                        </Descriptions>
                    </Modal>
                </Tabs.TabPane>

                {allowEditDevice &&
                <Tabs.TabPane tab={<Link replace to={`/admin/system/settings/device`}>Device</Link>} key="device">
                    <DeviceForm settings={settings} onSubmit={this.submit}/>
                </Tabs.TabPane>}

                {allowEditNotification &&
                <Tabs.TabPane tab={<Link replace to={`/admin/system/settings/notification`}>Notification</Link>}
                              key="notification">
                    <NotificationForm settings={settings} onSubmit={this.submit}/>
                </Tabs.TabPane>}

                <Tabs.TabPane tab={<Link replace to={`/admin/system/settings/booking`}>Booking</Link>} key="booking">
                    <BookingForm
                        settings={settings}
                        onSubmit={this.submit}
                        onUploadFile={this.onUploadFile}/>
                </Tabs.TabPane>
            </Tabs>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);

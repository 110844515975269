import http from './http';
import storage from '../storage';

class FileService {

    getDataFile(file, download = null) {
        return http.getDownload('/file/data', {f: file}, {download});
    }

    getReportFile(file, download = null) {
        return http.getDownload('/file/data', {f: `reports/${file}`}, {download});
    }

    getAdsImg(id, download = null) {
        return http.getDownload(`/file/ads-img/${id}`, null, {download});
    }

    getAdsImgAsBse64(id) {
        return this.getFileToBase64(`/file/ads-img/${id}`, null, false);
    }

    getDataFileToBase64(file) {
        return this.getFileToBase64('/file/data', {f: file});
    }

    getFileToBase64(path, params = null, useCache = true) {
        return new Promise(((resolve, reject) => {
            const paramKey = params ? Object.keys(params).map(k => `${k}=${params[k]}`).join('&') : '';
            const key = `${path}${paramKey}`;
            const cache = storage.getSessionStorage(key);
            if (cache && useCache) {
                resolve(cache);
            } else {
                http.getDownloadBlob(path, params).then(blob => {
                    try {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function () {
                            resolve(reader.result);
                            if (useCache) {
                                storage.setSessionStorage(key, reader.result);
                            }
                        }
                    } catch (e) {
                        reject(e);
                    }
                }).catch(reject);
            }
        }))
    }
}

export default new FileService();

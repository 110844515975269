import React, {Fragment} from 'react';
import {Menu} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {getMenus, OVERALL_MENUS} from '../../constants/menu';
import {Link} from "react-router-dom";

class Menus extends React.PureComponent {

    static propTypes = {
        onMenuClick: PropTypes.func,
    };
    static defaultProps = {
        onMenuClick: null,
    };

    state = {
        selectedKeys: [],
        openKeys: [],
    };

    constructor(props) {
        super(props);
        const menuKeys = OVERALL_MENUS.filter(this.findMatchedMenuPatter).map(m => {
            if (m.children) {
                return {
                    selected: m.children.filter(this.findMatchedMenuPatter).map(sm => `${m.id}-${sm.id}`),
                    open: [m.id],
                };
            }
            return {
                selected: [m.id],
            };
        });

        this.state = {
            selectedKeys: menuKeys.map(k => k.selected).flat().filter(k => !!k),
            openKeys: menuKeys.map(k => k.open).flat().filter(k => !!k),
        }
    }

    findMatchedMenuPatter = (menu) => {
        const currentPath = window.location.pathname;
        // console.log(menu, currentPath);
        try {
            if (menu.path && currentPath === menu.path) {
                return true;
            }

            if (menu.pathPatterns) {
                let matched = false;
                for (let pattern of menu.pathPatterns) {
                    matched = (new RegExp(pattern)).exec(currentPath);
                    if (matched) {
                        return true;
                    }
                }
            }

            if (menu.children) {
                for (const submenu of menu.children) {
                    if (this.findMatchedMenuPatter(submenu)) {
                        return true;
                    }
                }
            }

            return false;
        } catch (e) {
            console.warn('menu pattern', e);
        }
        return false;
    };

    render() {
        const {selectedKeys, openKeys} = this.state;
        const {auth, onMenuClick} = this.props;

        const linkProps = {
            onClick: onMenuClick,
        };

        const sMenu = getMenus(auth)

        return (
            <Fragment>
                <Menu
                    style={{borderRight: 'none'}}
                    theme="light"
                    mode="inline"
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    onOpenChange={(openKeys) => this.setState({openKeys})}
                >
                    {sMenu.map(menu => {
                        const key = `${menu.id}`;

                        if (menu.children && menu.children.length > 0) {
                            return (
                                <Menu.SubMenu key={key} icon={menu.icon} title={menu.title}>
                                    {menu.children.map((submenu) => (
                                        <Menu.Item key={`${key}-${submenu.id}`} icon={submenu.icon}>
                                            <Link {...linkProps} to={submenu.path}>
                                                {submenu.title}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            );
                        }

                        return (
                            <Menu.Item key={key} icon={menu.icon}>
                                <Link {...linkProps} to={menu.path}>
                                    {menu.title}
                                </Link>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Fragment>
        );
    }
}

const mapStateToProps = (state = {}, ownProps) => {
    const {backend} = state;
    const t = {
        auth: backend.auth,
    };
    return {...t, ...ownProps};
};

export default connect(mapStateToProps, null)(Menus);

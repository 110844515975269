import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { userService } from "@src/services";

export const FormUser = ({
                             extra,
                             show,
                             name,
                             isAllowedBookOnBehalf,
                             initialValues,
                             control = {allow_next: false, allow_previous: false, is_submit: false},
                             onPrevious,
                             onSubmit
                         }) => {

    const [users, setUsers] = useState({loading: false, data: [], error: ""});
    const [ready, setReady] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);

    const handlers = {
        loadUsers: () => {
            setUsers({...users, loading: true, error: ""});
            userService.list(0, null).then(rs => {
                setUsers({data: rs.items, loading: false, error: ""});
            }).catch(err => {
                setUsers({...users, loading: false, error: err.message});
            });
        }
    }

    useEffect(() => {
        if (!ready) {
            setReady(true);
            handlers.loadUsers();
        }
    });

    const formProps = {
        name,
        layout: "vertical",
        initialValues,
        onValuesChange: (value, form) => setFormValues(form),
        style: {display: show ? "block" : "none"}
    }


    return <Form {...formProps}>
        {isAllowedBookOnBehalf && <Form.Item label="Booked by" name="user">
            <Select allowClear={true} showSearch optionFilterProp="label" options={users?.data?.map(u => ({label: u.name, value: u.id}))}/>
        </Form.Item>}

        <Form.Item label="Remarks" name="remarks" rules={[{
            max: 300,
            message: `Maximum 300 characters only`
        }]}>
            <Input.TextArea rows={5}/>
        </Form.Item>

        <Form.List name="notify_emails">
            {(fields, {add, remove}) => (
                <>
                    Notify Emails
                    <Row gutter={[8, 8]} style={{marginBottom: 24}}>
                        {fields.map(field => (
                            <Col span={24}>
                                <Row align="middle" gutter={[8, 8]} key={field.key}>
                                    <Col span={22}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name]}
                                            style={{marginBottom: 0}}
                                            rules={[
                                                {required: true, message: 'Please insert a email'},
                                                {type: "email", message: 'Please insert a valid email format'},
                                            ]}
                                        >
                                            <Input type="email" autoComplete="chrome-off"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                            Add Email
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>

        <Form.Item label="Invited Users" name="invited_users" help={<i>* user must have valid e-mail address</i>}>
            <Select mode="multiple" allowClear={true} showSearch optionFilterProp="label"
                    options={users?.data.map(u => ({
                        value: u.id,
                        label: `${u.name} (${u.email})`,
                        disabled: !u.email
                    }))}/>
        </Form.Item>

        <Form.Item style={{marginTop: 30, textAlign: "right"}}>
            <Space direction="horizontal" size={8}>
                {!!extra && <>{extra}&nbsp;</>}
                <Button htmlType="button" disabled={!control?.allow_previous} onClick={onPrevious}>
                    Previous
                </Button>
                <Button htmlType="submit" disabled={!control?.allow_next} type="primary">
                    Next
                </Button>
            </Space>
        </Form.Item>
    </Form>
};

import http from './http';

class AdvertisementService {

    list(offset, limit, query = {}) {
        return http.get(`/api/screensavers`, {
            ...query,
            offset,
            limit
        });
    }

    get(id) {
        return http.get(`/api/screensavers/${id}`);
    }

    update(id, params) {
        return http.put(`/api/screensavers/${id}`, params);
    }

    create(params) {
        return http.post(`/api/screensavers`, params);
    }

    delete(id) {
        return http.delete(`/api/screensavers/${id}`);
    }

    updateImage(id, params) {
        return http.put(`/api/screensavers/image/${id}`, params);
    }

    deleteImage(id) {
        return http.delete(`/api/screensavers/image/${id}`);
    }
}

export default new AdvertisementService();

import React, {useEffect, useState} from "react";
import {Form, Row, Col, Descriptions, Space, Button,} from "antd";
import formatter from "@src/helpers/formatter";
import {roomService, userService} from "@src/services";

export const FormConfirm = ({
                                extra,
                                is_update,
                                show,
                                values,
                                onPrevious,
                                onSubmit
                            }) => {

    const [ready, setReady] = useState(false);

    const [rooms, setRooms] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (ready) {
            Promise.all([
                userService.list(0, null),
                roomService.list(0, null, {types: [values?.type]})
            ]).then(([rs1, rs2]) => {
                setRooms(rs2.items);
                setUsers(rs1.items);
            });
        }
    }, [values]);

    useEffect(() => {
        if (!ready) {
            setReady(true);
        }
    });

    return <div style={{display: show ? "block" : "none"}}>
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Descriptions column={1} size="small" bordered>
                    <Descriptions.Item label="Title">
                        {values?.title}
                    </Descriptions.Item>
                    <Descriptions.Item label={formatter.toDisplayRoomType(values?.type)}>
                        {rooms?.find(r => r.id === values?.room)?.name ?? values?.room}
                    </Descriptions.Item>
                    <Descriptions.Item label="Start">
                        {formatter.toDisplayDatetime(values?.start)}
                    </Descriptions.Item>
                    <Descriptions.Item label="End">
                        {formatter.toDisplayDatetime(values?.end)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Booked by">
                        {users?.find(u => u.id === values?.user)?.name ?? values?.user}
                    </Descriptions.Item>
                    <Descriptions.Item label="Remarks">
                        {values?.remarks}
                    </Descriptions.Item>
                    <Descriptions.Item label="Notify Emails">
                        <Space direction="vertical">
                            {values?.notify_emails?.map((item, i) => <span key={i}>{item}</span>)}
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Invited Users">
                        <Space direction="vertical">
                            {values?.invited_users?.map((uid, i) => <span key={i}>
                                {users?.find(u => u.id === uid)?.name}
                            </span>)}
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Booking Date">
                        {formatter.toDisplayDate(values?.date)}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>

        <div style={{marginTop: 30, textAlign: "right"}}>
            <Space direction="horizontal" size={8}>
                {!!extra && <>{extra}&nbsp;</>}
                <Button htmlType="button" onClick={onPrevious}>
                    Previous
                </Button>
                <Button type="primary" htmlType="submit" onClick={onSubmit}>
                    {is_update ? "Update" : "Submit"}
                </Button>
            </Space>
        </div>
    </div>
}

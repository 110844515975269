import React from "react";
import {connect} from "react-redux";

import {roomService, reportService} from '../../services';

import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import PageContent from "../../components/page-content";

import BookingByRoomChart from '../../screens/graphs/booking-by-room';
import {DatePicker, Col, Form, Row, Button} from "antd";
import {ReloadOutlined} from '@ant-design/icons';
import * as moment from 'moment';
import {RoomsSelectors} from "../../components/selectors";

class Container extends React.PureComponent {

    id = null;
    isCreate = false;

    state = {
        loading: false,
        init_done: false,

        data: null,

        graph_duration_data: null,
        graph_duration_loading: false
    };

    defaultFilterValues = {
        timestamps: [
            moment().subtract(7, 'days').endOf('day'),
            moment().endOf('day')
        ]
    }

    refresh = (filter = null) => {
        const {timestamps, rooms} = filter ? filter : this.defaultFilterValues;
        let useTimestamps = null;
        if (timestamps) {
            useTimestamps = [
                moment(timestamps[0]).startOf('day').toISOString(),
                moment(timestamps[1]).endOf('day').toISOString()
            ];
        }

        this.setState({loading: true});
        reportService.graphGetBookingByRooms(useTimestamps, rooms).then(rs => {
            this.setState({data: rs, loading: false});
        }).catch(error => {
            this.props.notificationError(error);
            this.setState({loading: false});
        });
    };


    render() {
        const {init_done, loading, data, rooms} = this.state;

        return <PageContent title="Report Graph" loading={loading}>
            <Row>
                <Col span={24}>
                    <Form layout="vertical" defaultValue={this.defaultFilterValues} onFinish={this.refresh}
                          onValuesChange={(values, form) => {
                              this.refresh({
                                  ...this.defaultFilterValues,
                                  ...form
                              });
                          }}
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={6}>
                                <Form.Item label="Date" name="timestamps">
                                    <DatePicker.RangePicker
                                        style={{width: '100%'}}
                                        ranges={{
                                            Today: [moment().startOf('day'), moment().endOf('day')],
                                            'This Week': [
                                                moment().startOf('week'),
                                                moment().endOf('week')
                                            ],
                                            'Last Week': [
                                                moment().subtract(1, 'week').startOf('week'),
                                                moment().subtract(1, 'week').endOf('week')
                                            ],
                                            'This Month': [
                                                moment().startOf('month'),
                                                moment().endOf('month')
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month'),
                                                moment().subtract(1, 'month').endOf('month')
                                            ],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Rooms/Desks" name="rooms">
                                    <RoomsSelectors rooms={rooms} mode="multiple"/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label=" ">
                                    <Button htmlType="submit" icon={<ReloadOutlined/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                {init_done && <>
                    <Col span={24}>
                        <BookingByRoomChart data={data}/>
                    </Col>
                </>}
            </Row>
        </PageContent>
    }

    componentDidMount() {
        this.setState({loading: true});
        roomService.list().then(rs => {
            this.setState({rooms: rs.items, init_done: true, loading: false});
        }).catch(err => {
            this.setState({init_done: true, loading: false});
        }).finally(() => {
            this.refresh();
        });
    }
}

export default connect(null, CommonDispatcher)(Container);
